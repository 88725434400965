import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import TemplateColumnListItem from './templateColumnListItem';


const useStyles = makeStyles((theme) => ({
    stepTwo: {
        width: '200px',
        height: '200px'
    }
}));

const TemplateOrder = (props) => {
    const classes = useStyles();
    const [arrayLength, setLength] = React.useState()
    let [templateColumns, setTemplateColumns] = React.useState(props.templateModel.data)

    useEffect(() => {
        setLength(props.templateModel.data.length)

    }, [props.templateModel.data, props.weekDayOrder])

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        let items = Array.from(props.templateModel.data)
        let [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        items = items.map((item, index) => {
            // console.log(item.header, index)
            return { ...item, index: index }
        })

        props.dispatch({
            type: 'set_template_model',
            payload: {
                data: items
            }
        });

    }

    useEffect(() => {
        setTemplateColumns(props.templateModel.data)
    }, [props.templateModel.data])

    if (!props.templateModel.data.length) {
        return (<><LinearProgress /></>)
    }
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='templates'>
                {(provided) => (
                    <Grid item xs={12} md={6}  {...provided.droppableProps} ref={provided.innerRef} >
                        <List dense={false}>
                            {templateColumns.map((item, index) => (
                                <Draggable key={String(index)} draggableId={String(index)} index={index} >
                                    {(provided) => (
                                        <TemplateColumnListItem showDragHandle={true} provided={provided} item={item} />
                                    )}
                                </Draggable>
                            ))}
                        </List>
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(TemplateOrder);