import React, { useState, useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import SettingsIcon from '@material-ui/icons/Settings';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core//Grid';
import List from '@material-ui/core//List';
import Card from '@material-ui/core//Card';
import CardHeader from '@material-ui/core//CardHeader';
import ListItem from '@material-ui/core//ListItem';
import ListItemText from '@material-ui/core//ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core//Divider';
import { v4 as uuid } from 'uuid';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    limit: {
        height:400
    },
    content: {
        height: 500
    },
    button: {
        margin: theme.spacing(1),
    },
    root: {
        textAlign: 'left'
    },
    list: {
        overflow: 'auto',
        height: 333,
        paddingTop: 0
    },
    highlight: {
        backgroundColor: '#efefef'
    },
    typography: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

const Limit = (props => {
    const classes = useStyles();
    let [open, setOpen] = React.useState(false);
    const openModal = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = () => {
        axiosInstance.post('/settings', {
              companyId: props.login.user.company.id,
              key: 'limitByTech',
              value: right
            }, requestHeaderConfig(props.login.authToken)).then(response => {
                let updatedSettingsObj = response.data;
                props.dispatch({
                    type: 'set_settings',
                    payload: updatedSettingsObj
                });
                props.dispatch({
                    type: 'set_alert',
                    payload: {
                      active: true,
                      message: 'Office settings saved'
                    }
                });
                handleClose();
            }).catch((e) => {
                console.warn(e, 'Could not save settings for : Notification Emails');
            });
    };

    useEffect(() => {
        setLeft(props.left);
        setRight(props.right)
    }, [props])

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState(props.left);
    const [right, setRight] = useState(props.right);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
        newChecked.push(value);
        } else {
        newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleCheckedRight = () => {

        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title, items) => (
        <>
        <Typography variant="overline" display="block">
            Offices
        </Typography>
        <Card className={classes.limit}>
            
        <CardHeader
            sx={{ px: 2, py: 1 }}
            avatar={<></>}
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List
            sx={{
            width: 200,
            height: 230,
            bgcolor: 'background.paper',
            overflow: 'auto',
            }}
            dense
            component="div"
            role="list"
            className={classes.list}
        >
            {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;
            return (
                <ListItem
                key={uuid()} 
                role="listitem"
                button
                onClick={handleToggle(value)}
                className={checked.indexOf(value) !== -1 ? classes.highlight : ''}
                >
                <ListItemText id={labelId} primary={value.name} />
                </ListItem>
            );
            })}
            <ListItem />
        </List>
        </Card>
        </>
    );

  return (
    <>
    

            <Grid item xs>
                <Paper className={classes.paper}>
                    <List subheader={<ListSubheader>Limit Technicians Shown By Office</ListSubheader>} className={classes.root}>
                        <Button
                    onClick={openModal}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<SettingsIcon />}
                    style={{marginBottom: 20}}>Choose Offices</Button>
                    </List>
                </Paper>
            </Grid>



        <Dialog fullWidth={false} maxWidth={'md'} open={open} onClose={handleClose} scroll="paper" aria-labelledby="new-export-template">
                <DialogContent className={classes.content}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item>{customList('Not Limited By Office', left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList('Limited By Office', right)}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button color="primary" variant="contained" onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
    </>
  );
});

const mapStateToProps = (state) => {
    return {
      ...state
    };
  };
  
  export default connect(mapStateToProps)(Limit);
