import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axiosInstance from '../../utilities/axios';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    //   maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.primary,
    }
  }));

  /**
 * @summary React state will not update by passing the same object it already has. 
 * Create a new object with the same values as the original 
 * @param {object} model 
 */
const createNewModel = (model) => {
    let newModel = {}
    for (const [key, value] of Object.entries(model)) {
        newModel[key] = value;
    } 

    return newModel;
}

const ExternalSystem = (props) => {
    let [model, setModel] = useState(false) // set default state after login
    const classes = useStyles();

    const handleChangeJobSystem = (event) => {
        axiosInstance.post('/settings', {
            companyId: props.login.user.company.id,
            key: 'jobExternalSystem',
            value: event.target.value
          }, requestHeaderConfig(props.login.authToken)).then((response) => {
              let updatedSettingsObj = response.data;
              props.dispatch({
                  type: 'set_settings',
                  payload: updatedSettingsObj
              })
          }).catch((e) => {
              console.warn(e, 'Could not save settings for : jobExternalSystem');
          });
    };
    const handleChangeUserSystem = (event) => {
        axiosInstance.post('/settings', {
              companyId: props.login.user.company.id,
              key: 'userExternalSystem',
              value: event.target.value
            }, requestHeaderConfig(props.login.authToken)).then((response) => {
                let updatedSettingsObj = response.data;
                props.dispatch({
                    type: 'set_settings',
                    payload: updatedSettingsObj
                })
            }).catch((e) => {
                console.warn(e, 'Could not save settings for : userExternalSystem');
            });
    };
    useEffect(async () => {
        await axiosInstance.get('/systems', {
            params: {
                companyId: props.login.user.company.id
              },
              ...requestHeaderConfig(props.login.authToken)
            }).then((response) => {
                setModel(response.data.externalSystems);
            }).catch((e) => {
                console.warn(e, 'Could not fetch list of external systems');
            });
    }, [])
     
    if (!model) {
        return (
            <></>
        )
    }
    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <List subheader={<ListSubheader>User External System</ListSubheader>} className={classes.root}>
                        <FormControl fullWidth>
                            <InputLabel>Default</InputLabel>
                            <Select
                                label="Default"
                                onChange={handleChangeUserSystem}
                                defaultValue={props.appSettings.userExternalSystem}
                            >
                                {model.map((system) => (<MenuItem key={system.id} value={system.id}>{system.label}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </List>
                </Paper>
            </Grid>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <List subheader={<ListSubheader>Job External System</ListSubheader>} className={classes.root}>
                        
                    <FormControl fullWidth>
                            <InputLabel>Default</InputLabel>
                            <Select
                                label="Default"
                                onChange={(e) => {handleChangeJobSystem(e, props.row)}}
                                defaultValue={props.appSettings.jobExternalSystem}
                            >
                                {model.map((system) => (<MenuItem key={system.id} value={system.id}>{system.label}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </List>
                </Paper>
            </Grid>
        </Grid>
        </>

    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
  };
export default connect(mapStateToProps)(ExternalSystem);