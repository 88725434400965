import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import { sendEmailWithAttachedCSV } from '../../actions/viewTimecardActions'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles((theme) => ({

    comments: {
        width: '100%'
    },
    button: {
        margin: 10
    }
}));


const EmailCSV = (props) => {
    const classes = useStyles();
    const { headers, exportData } = props
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("")
    const [showWarningForNonValidEmail, setWarningForNonValidEmail] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setWarningForNonValidEmail(false)
        setOpen(false)
    }

    const handleSend = () => {
        if (!validateEmail(props.selectedRecipient)) {
            setWarningForNonValidEmail(true)
            return false;
        }

        let sentEmail = sendEmailWithAttachedCSV(props, props.selectedRecipient, message, headers, exportData)
        if (!sentEmail) { return false; }
        setOpen(false)
        setMessage('')
        props.dispatch({type: 'set_alert',payload: {active: true,isInfo: false,message: 'Timecard successfully sent'}});

    }

    function validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const onChange = (field) => {
        props.dispatch({
            type: 'set_recipient_email',
            payload: field.target.value
        });
    }

    return (
        <div>
            <Button variant='contained' color='secondary' onClick={handleClickOpen}>
                Email Timecard
            </Button>

            <Dialog
                open={open}
                aria-labelledby="send-email-title"
                aria-describedby="send-email-description"
            >

                <DialogTitle style={{ color: '#004680' }} id="alert-dialog-title">
                    Email Timecard Export File
                </DialogTitle>

                <DialogContent>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 1 },
                        }}
                    >
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
                            <FormHelperText>Email</FormHelperText>
                            <Select
                                autoFocus={false}
                                value={props.selectedRecipient}
                                label="Email"
                                onChange={onChange}
                            >
                                {props.listOfAccountUsers.map((user) => {
                                    return (<MenuItem key={user.id} value={user.email}>{user.name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            onChange={(e) => setMessage(e.target.value)}
                            label="Add a Message"
                            className={classes.comments}
                            value={message}
                            multiline rows={4}
                            variant="outlined" />

                    </Box>
                </DialogContent>


                {showWarningForNonValidEmail && <DialogContent>
                    <MuiAlert severity="warning">Please Enter a Valid Email</MuiAlert>
                </DialogContent>}

                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant='contained' color ='secondary' style={{marginRight: 16}} onClick={handleSend}>Send</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(EmailCSV);
