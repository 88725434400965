import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import axiosInstance from '../../../utilities/axios';
import Box from '@material-ui/core/Box';
import { requestHeaderConfig } from '../../../utilities';
const useStyles = makeStyles((theme) => ({
    submit: {
        margin: 10
    }
}));

function Submit(props) {
    const classes = useStyles();
    const {setSelectionModel} = props
    let [bulkActions, setBulkActions] = useState([]);

    const hasFeature = (name, appSettings) => {
        let features = appSettings.features;
        let hasFeature = false;
        if (features) {
            let feature = features.find((f) => { return f === name });
            if (!feature) {
                hasFeature = false;
            } else {
                hasFeature = true;
            }
        }
        return hasFeature;
    }

    useEffect(() => {
        setBulkActions(props.bulkActions);
    }, [props.bulkActions])

    const handleRequestChanges = () => {
        if (bulkActions.length > 0) {
            let allowedTimecardsUserHasPermissionsFor = [];
            let approvedTimecards = [];
            let displayPermissionError = false;

            // if the feature is enabled, and you do not have the permission, and you are not an "Account Admin", do not allow Approved Timecards to be changed
            if (hasFeature('admin_approval_required_to_request_changes', props.appSettings) && !props.login.canRequestChangesForApprovedTimecards && !props.login.isAdmin) {
                // if the current user is NOT an admin, or user does not have the permission to request changes for approved timecards, then do not allow the user to request changes for the timecards that are in the status of 'Approved'
                approvedTimecards = bulkActions.filter((timecard) => timecard.status === 'Approved');
                if (approvedTimecards.length > 0) {
                    // since there is 1 or more approved timecards we must filter them out of the bulkActions array, then display them in a notifcation
                    displayPermissionError = true;
                    allowedTimecardsUserHasPermissionsFor = bulkActions.filter((timecard) => timecard.status !== 'Approved');
                    bulkActions = allowedTimecardsUserHasPermissionsFor;
                    // next we need to notify the user that (X) number of timecards that were in Approved status that could not be requested changes for.
                    displayPermissionError = true;
                }
            }

            let payload = {
                companyId: props.login.user.company.id,
                userId: props.login.user.id,
                firstName: props.login.user.firstName,
                lastName: props.login.user.lastName,
                name: props.login.user.name,
                officeName: props.login.user.location.name,
                userTimezone: props.login.user.timezone,
                emails: bulkActions.map((timecard) => timecard.techEmail),
                reportDateRanges: props.reportDateRanges,
                timecards: bulkActions.map((timecard) => { 
                    return {
                        id: timecard.id, 
                        email: timecard.techEmail
                    } 
                })
            };
            if (hasFeature('admin_approval_required_to_request_changes', props.appSettings) && bulkActions.length === 0 && displayPermissionError && !props.login.canRequestChangesForApprovedTimecards) { 
                props.dispatch({
                    type: 'set_alert',
                    payload: {
                        active: true,
                        isInfo: true,
                        message: `You do not have permission to request changes for (${approvedTimecards.length}) "Approved" timecards`
                    }
                });
            } else {
                axiosInstance.post('/request', payload, requestHeaderConfig(props.login.authToken)).then(response => {
                    props.dispatch({
                        type: 'set_timecard_report',
                        payload: response && response.data && response.data.content ? response.data.content : []
                    });
                    props.dispatch({
                        type: 'set_alert',
                        payload: {
                            active: true,
                            message: 'Timecard(s) Changes Requested'
                        }
                    });
                    if (displayPermissionError) {
                        props.dispatch({
                            type: 'set_alert',
                            payload: {
                                active: true,
                                isInfo: true,
                                message: `You do not have permission to request changes for (${approvedTimecards.length}) "Approved" timecards`
                            }
                        });
                    }
                    props.dispatch({
                        type: 'set_timecard_report_date_range',
                        payload: props.reportDateRanges
                    });
                    props.dispatch({
                        type: 'set_bulkActions',
                        payload: []
                    });
                    setSelectionModel([])

                }).catch((e) => {
                    console.warn(e, 'Could not request changes');
                });
            }
        }
    }

    const handleApprove = () => {
        if (bulkActions.length > 0) {
            let payload = {
                companyId: props.login.user.company.id,
                userId: props.login.user.id,
                firstName: props.login.user.firstName,
                lastName: props.login.user.lastName,
                name: props.login.user.name,
                officeName: props.login.user.location.name,
                userTimezone: props.login.user.timezone,
                id: bulkActions.map((timecard) => timecard.id),
                reportDateRanges: props.reportDateRanges,
                bulkActions: bulkActions.map((card) => {
                    return {
                        id: card.id,
                        email: card.email
                    }
                })
            };

            axiosInstance.post('/approve', payload, requestHeaderConfig(props.login.authToken)).then(response => {
                props.dispatch({
                    type: 'set_timecard_report',
                    payload: response && response.data && response.data.content ? response.data.content : []
                });
                props.dispatch({
                    type: 'set_alert',
                    payload: {
                        active: true,
                        message: 'Timecard(s) Approved'
                    }
                });
                props.dispatch({
                    type: 'set_timecard_report_date_range',
                    payload: props.reportDateRanges
                });
                props.dispatch({
                    type: 'set_bulkActions',
                    payload: []
                });
                setSelectionModel([])
            }).catch((e) => {
                console.warn(e, 'Could not approve timecard');
            });
        }
    };

    const isTechTimecardScreen = () => {       
        return window.location.pathname === '/myTimecards';
    }

    const canApproveOrRequestChanges = () => {
        const { canSubmit, user } = props.login
        const completeUserData = props.listOfAccountUsers.find((fullUserData)=> { return user.id === fullUserData.id})
        const activities = completeUserData?.activities;
        let isAdmin = false;
        if (activities) {
            isAdmin = activities.includes('admin.account') || activities.includes('admin.user');
        }
        return canSubmit || isAdmin
    }

    return (
        <>
            <Box>
                {!isTechTimecardScreen() && canApproveOrRequestChanges() && (<Button className={classes.submit} color="primary" onClick={handleApprove}>Approve</Button>)}
                {!isTechTimecardScreen() && canApproveOrRequestChanges() && (<Button className={classes.submit} color="secondary" onClick={handleRequestChanges}>Request Changes</Button>)}
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(Submit);