import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';

const Token = (props) => {
    const [token, setToken] = useState('');

    const handleChange = (e, value) => {
        setToken(value)
    }

    useEffect(() => {
        props.dispatch({
            type: 'set_template_model',
            payload: {
                token: token,
                isStacConnector: true,
                data: []
            }
        });
    }, [token])

  return (
        <TextField
            id="stac-basic-token"
            label="Basic Token"
            helperText="Found in the code section of your recipe."
            onChange={(e) => {
                return handleChange(e, e.target.value, 'token')
            }}
            value={token}
        />
  );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(Token);