import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import DeleteButton from './deleteJobItemButton';
import Fade from '@material-ui/core/Fade';
import axiosInstance from '../../utilities/axios';
import { requestHeaderConfig } from '../../utilities';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    dateContainer: {
      marginTop: 0,
    },
    hide: {
        display: 'none'
    },
    error: {
        border: '1px solid #ff0000'
    }
  }));

  const AddJobItem = (props) => {
    const classes = useStyles();
    const didInitialize = useRef(false);
    // when no records are found, rather than display an edit row, prompt them to add a new one
    let [disable, setDisableServiceLine] = useState((props.libItems.find(l => l.id === props.model.libItemId) && props.libItems.find(l => l.id === props.model.libItemId).serviceLine !== null));
    let [selectedDate, setSelectedDate] = useState(new Date());
    let [show, setShow] = useState(true);
    let [displayNone, setDisplayNone] = useState(false);
    let [payload, setPayload] = useState(false);
    let [showErrors, setShowErrors] = useState(false);
    let [serviceLine, setServiceLine] = useState(0);

    const validatePayload = (payload) => {
        // if any are undefined, remove the action, so the backend does not attempt to save to servicetrade
        if (!payload.serviceLine) delete payload.action;
        if (!payload.libItem) delete payload.action;
        if (!payload.quantity) delete payload.action;
        if (payload.usedOn < 0) delete payload.action; // if before Jan 1, 1970
        return payload
    };

    useEffect(() => {
        if (!show) {
            setPayload({...payload, ...{
                index: payload.model.index,
                action: 'DELETE'
            }})
            setTimeout(() => {
                setDisplayNone(true);
            }, 500);
        }
    }, [show]);

    useEffect(async () => {
        if (!payload && props.model.index !== undefined) {
            await axiosInstance.get('/jobs',{
                params: {
                    companyId: props.login.user.company.id,
                    userId: props.login.user.id,
                    index: props.model.index
                },
                ...requestHeaderConfig(props.login.authToken)
                }).then((response) => {
                let model = response.data;
                setPayload({
                    index: model.index,
                    jobId: model.jobId,
                    libItem: props.libItems.find(l => l.id === model.libItemId),
                    quantity: model.quantity || 1,
                    usedOn: moment(model.usedOn ? new Date(model.usedOn * 1000) : new Date()).unix(),
                    serviceLine: props.serviceLines.find(s => s.id === model.serviceLine),
                    source: {
                        type: 'tech',
                        userId: model.source.userId
                    },
                    model: model
                });
            }).catch((e) => {
                console.warn(e, 'Could not fetch job');
            });
        }
    }, [])

    useEffect(() => {
        if (disable) {
            setPayload({...payload, ...{serviceLine: payload.libItem.serviceLine}});
            setServiceLine(payload.libItem.serviceLine)
        }
    }, [disable]);

    useEffect(() => {
        setPayload({...payload, ...{serviceLine: serviceLine, usedOn: moment(selectedDate).unix(), action: 'POST'}});
    }, [serviceLine, selectedDate])

    useEffect(async () => {
        if (didInitialize.current) {
            if (payload.libItem && payload.libItem.serviceLine !== null) {
                // A lib item with a default serviceLine should disable the service line dropdown
                setDisableServiceLine(true);
                // when selecting a new lib item, the service line should be set to it's default
                setServiceLine(payload.libItem.serviceLine);
            } else {
                // A lib item without a default serviceLine should allow the user to select a serviceLine
                setDisableServiceLine(false);
            }
            if (props.model.index) {
                await axiosInstance.put('/jobs/draft', {
                    companyId: props.login.user.company.id,
                    userId: props.login.user.id,
                    job: validatePayload(payload),
                    index: props.model.index
                  }, requestHeaderConfig(props.login.authToken)).catch((e) => {
                      console.warn(e, 'Could not save draft');
                  });
            }
        } else {
            didInitialize.current = true;
        }
    }, [payload]);

    if (!payload) {
        return (<></>);
    }

    return (
            <Fade in={show} className={displayNone ? classes.hide : ''}>
                <Grid container justify="space-around">
                    <FormControl variant="outlined">
                        <Autocomplete
                                noOptionsText="Select job item from dropdown"
                                className={!payload.libItem && showErrors ? classes.error : ''}
                                onChange={(e, newValue) => {
                                    setShowErrors(true)
                                    setPayload({...payload, ...{libItem: newValue, action: 'POST'}});
                                }}
                                options={props.libItems}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    } else {
                                        return option.code + ' ' + option.name;
                                    }
                                }}
                                getOptionSelected={(option, value) => {
                                    return option.id === value.id
                                }}
                                style={{ width: 300, textAlign: 'left' }}
                                renderInput={(params) => <TextField style={{textAlign: 'left' }} {...params} variant="outlined" />}
                            />
                    </FormControl>
                    <FormControl variant="outlined">
                        <Autocomplete
                            noOptionsText="Select service line from dropdown"
                            className={!payload.serviceLine && showErrors ? classes.error : ''}
                            value={serviceLine}
                            disabled={disable}
                            onChange={(e, newValue) => {
                                setShowErrors(true);
                                setServiceLine(newValue);
                            }}
                            getOptionSelected={(option, value) => {
                                if (typeof value === 'string') {
                                    return option.id === value;
                                } else if (option.id) {
                                    return option.id === value.id
                                } else {
                                    return false
                                }
                            }}
                            options={props.serviceLines}
                            getOptionLabel={(option) => {
                                if (option && option.abbr) {
                                    return option.abbr + ' ' + option.name;
                                } else {
                                    return ''
                                }
                            }}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <TextField className={!payload.quantity && showErrors ? classes.error : ''} type="number" style={{ width: 75 }} value={payload.quantity} onChange={(e) => {
                            setShowErrors(true)
                            setPayload({...payload, ...{quantity: +e.target.value, action: 'POST'}});
                        }} variant="outlined" />
                    </FormControl>
                    <FormControl variant="outlined">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                autoOk={true}
                                className={classes.dateContainer}
                                variant="inline"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                margin="normal"
                                style={{ width: 180 }}
                                value={selectedDate}
                                onChange={(e, selectedDate) => {
                                    setShowErrors(true)
                                    setSelectedDate(selectedDate);
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <DeleteButton model={payload} setShow={setShow} />
                </Grid>
            </Fade>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(React.memo(AddJobItem));