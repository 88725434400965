import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingScreen from '../loading';

const LogoutPage = (props) => {
    
    useEffect(() => {
        props.dispatch({
            type: 'exit'
        });
    }, [])
    return (
      <LoadingScreen message={"Logging Out"}/>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(LogoutPage);