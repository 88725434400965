import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { requestHeaderConfig } from '../../utilities';
import axiosInstance from '../../utilities/axios';

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }));

  /**
   * @name Redirect
   * @param {} props
   * @description This component will redirect an admin to the specified timecard based solely on the uuid passed in the url query string 
   */
  const EditRedirect = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    let navi = useHistory();
    let requestParams = {
        params: {
            companyId: props.login.user.company.id,
            uuid: new URLSearchParams(window.location.search).get('key')
        },
        ...requestHeaderConfig(props.login.authToken)
    }

    axiosInstance.get('/fetchTimecard', requestParams).then((response) => {
        var timecard = response.data;
        props.dispatch({
            type: 'set_timecard_rows',
            payload: timecard.additionalRows || []
        });
        props.dispatch({
            type: 'set_edit_timecard',
            payload: timecard
        });
        navi.push('/editTimecard');
        return timecard
    }).catch((e) => {
        console.warn(e, 'Could not retrieve timecard');
        navi.push('/');
        setOpen(false);
    })

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(EditRedirect);