import _ from 'lodash';

const combineByItemCode = (entries) => {
    let combinedRecords = [];
    let sObj = {}
    let summary = [];

    const summarize = (record) => {
      let key = record.itemCode ? record.itemCode : record.activity;
      //if the item code has already been defined in the summary (not the initial pass-thru)
      if (sObj[key]) {
        record.week.forEach((w) => {
          sObj[key][w.day] = sObj[key][w.day] + w.value
        });
      } else {
        sObj[key] = {
          Mon: 0,
          Tue: 0,
          Wed: 0,
          Thu: 0,
          Fri: 0,
          Sat: 0,
          Sun: 0
        };
        record.week.forEach((w) => {
          sObj[key][w.day] = sObj[key][w.day] + w.value
        });
      }
      sObj[key]['Total'] = sObj[key]['Mon'] + sObj[key]['Tue'] + sObj[key]['Wed'] + sObj[key]['Thu'] + sObj[key]['Fri'] + sObj[key]['Sat'] + sObj[key]['Sun'];
      sObj[key]['Label'] = key;

      return record;
    }

    // group records by job number, loop thru each
    let jGroups = _.groupBy(entries, r => r.jobNumber);
    for (let [jobNumber, records] of Object.entries(jGroups)) {
      // if current job group only has one work entry, just push to master array
      if (records.length > 1) {
        // group all work entries of this job by item code or activity
        let jobWorkEntries = _.groupBy(records, (j) => { return j.itemCode ? j.itemCode : j.activity });
        // loop through each group of item codes
        for (let [itemCode, groupedWorkEntries] of Object.entries(jobWorkEntries)) {
          // check length, if only one, no need to group, just push to master array
          if (groupedWorkEntries.length > 1) {
            // establish your baseline object
            let baselineRecord = groupedWorkEntries[0];
            let unCombinedEntries = []; // include the first item
            // for each work entry of this item code for this job, sum the week total, quantity, week days
            for (let x = 0; x < groupedWorkEntries.length; x++) {
              let workEntry = groupedWorkEntries[x];
              if (x === 0) {
                // add the first item, and instanitate an entirely new object divorced from the original to avoid circular error
                unCombinedEntries.push({ ...{}, ...workEntry });
              } else {
                // bonus: store the original, un-combined records on the combined row for later use.
                unCombinedEntries.push(workEntry);
                // sum the week total, quantity, all week days.
                baselineRecord.weekTotal = baselineRecord.weekTotal + workEntry.weekTotal;
                if (baselineRecord.quanity && workEntry.quanity) {
                  baselineRecord.quantity = baselineRecord.quantity + workEntry.quantity;
                }
                baselineRecord.week.map((baselineWeekItem) => {
                  let workEntryWeekItem = workEntry.week.find(workEntryItem => workEntryItem.day === baselineWeekItem.day);
                  baselineWeekItem.value = baselineWeekItem.value + workEntryWeekItem.value;
                  return baselineWeekItem;
                });
              }
            }
            // bonus: set information about this combined object for later use
            baselineRecord._metadata = unCombinedEntries;
            // this represents a sum of all work entries using this item code for this job.
            combinedRecords.push(summarize(baselineRecord));
          } else {
            // this item code is the only one of its' kind on this job. Nothing to combine. Just push it to the master array.
            combinedRecords.push(summarize(groupedWorkEntries[0]));
          }
        }
      } else {
        // this job number only has a single work entry. There are no other entries to combine by item code. Just push to the master array.
        combinedRecords.push(summarize(records[0]));
      }
    }
    for (let itemCode in sObj) {
      summary.push(sObj[itemCode])
    }

    // a complete array of objects of all work entries combined by item code of each job number
    return {
      combined: combinedRecords,
      summary: summary
    }
  }

  export default combineByItemCode;
