import { connect } from 'react-redux';
import React, { useEffect } from 'react'
import TemplateStep from './templateStepper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const TemplateEditModal = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        props.dispatch({
            type: 'set_open_edit_template_model',
            payload: false
        })
    };

    useEffect(() => {
        if (props.templateEditMode !== open) {
            setOpen(props.templateEditMode);
        }
        if (props.templateEditMode === true) {
            props.dispatch({
                type: 'set_template_model',
                payload: {
                    // set only the key that has been chnaged
                    name: props.templateModel.name
                }
            });
        }
    }, [props.templateEditMode])
    return (
        <div>
            <Dialog fullWidth={true} maxWidth={'md'} open={open} scroll="paper" onClose={handleClose} >
                <DialogContent>
                    <TemplateStep setTemplateList={props.setTemplateList} setOpen={setOpen}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(TemplateEditModal);
