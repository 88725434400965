import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import {useLocation} from "react-router-dom"
import { requestHeaderConfig } from '../utilities';
import axiosInstance from '../utilities/axios';
import LoadingScreen from './loading';

const ProtectedRoute = ({children, login, props}) => {
    let location = useLocation();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [shouldLogOut, setShouldLogOut] = useState(false);

    const handleLogout = async () => {
        const domain = process.env.REACT_APP_AUTH0_DOMAIN;
        const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
        const returnTo = process.env.REACT_APP_AUTH0_RETURN_TO;

        window.location.href = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`
        props.dispatch({
            type: 'exit'
        })
    }

    useEffect(() => {
        axiosInstance.post('/security', { page: location.pathname, accountId: login.user.account.id }, requestHeaderConfig(login.authToken, {
            userId: login.user.id,
            accountId: login.user.account.id,
            page: location.pathname.substring(1)
        })).then((res) => {
            setIsAuthorized(res);
        }).catch(() => {
            setShouldLogOut(true);
        })
    }, [])

    if (shouldLogOut) {
        handleLogout();
    }

    if (!isAuthorized) {
        return (<LoadingScreen />)
    } else {    
        return children
    } 
};

const mapStateToProps = (state) => {
    return {
      ...state
    };
};

export default connect(mapStateToProps)(ProtectedRoute);
