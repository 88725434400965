import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {templateListReducer} from './reducers/templateReducer'
import { analyticsReducer } from './reducers/analyticsReducer';

const loginReducer = (state = {}, action) => {
    switch (action.type) {
        case 'initialize':
            state = action.payload
            return state;
        case 'exit':
            return {
                authenticated: false
            };
        default:
            return state;
    }
};
const technicianReducer = (state = 0, action) => {
    switch (action.type) {
        case 'set_technician':
            state = action.payload
            return state;
        default:
            return state;
    }
};
const usersReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_users':
            return [...action.payload]
        default:
            return state;
    }
};

const timeCardTypeReducer = (state = 0, action) => {
    switch (action.type) {
        case 'set_timeCardType':
            state = action.payload
            return state;
        default:
            return state;
    }
};
const importReducer = (state = 0, action) => {
    let newState = [];
    switch (action.type) {
        case 'set_imported_users':
            for (let x = 0; x < action.payload.length; x++) {
                newState.push(action.payload[x]);
            }
            return newState;
        default:
            return state;
    }
};
const officeListReducer = (state = [], action) => {
    let newState = [];
    switch (action.type) {
        case 'set_office_list':
            for (let x = 0; x < action.payload.length; x++) {
                newState.push(action.payload[x]);
            }
            state = newState;
            return state;
        default:
            return state;
    }
};
const selectedOfficeReducer = (state = "", action) => {
    let newState = "";
    switch (action.type) {
        case 'set_selected_office':
            newState = action.payload
            return newState;
        default:
            return state;
    }
};
const selectedRecipientReducer = (state = "", action) => {
    let newState = "";
    switch (action.type) {
        case 'set_recipient_email':
            newState = action.payload
            return newState;
        default:
            return state;
    }
};
const selectedEmailReducer = (state = "", action) => {
    let newState = "";
    switch (action.type) {
        case 'set_selected_email':
            newState = action.payload
            return newState;
        default:
            return state;
    }
};

const settingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'set_settings':
            let newState = {};
            for (const [key, value] of Object.entries(action.payload)) {
                newState[key] = value;
            }
            return newState;
        default:
            return state;
    }
};
const viewTimeCardReducer = (state = {}, action) => {
    switch (action.type) {
        case 'set_view_timecard':
            let newState = {};
            for (const [key, value] of Object.entries(action.payload)) {
                newState[key] = value;
            }
            return newState;
        default:
            return state;
    }
};
const backdropReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_backdrop':
            return action.payload;
        default:
            return state;
    }
};
const pageReducer = (state = '', action) => {
    switch (action.type) {
        case 'set_application_page':
            return action.payload;
        default:
            return state;
    }
};
const menuReducer = (state = true, action) => {
    switch (action.type) {
        case 'set_menu_open':
            return action.payload;
        default:
            return state;
    }
};

const editTimeCardReducer = (state = {}, action) => {
    switch (action.type) {
        case 'set_edit_timecard':
            let newState = {};
            for (const [key, value] of Object.entries(action.payload)) {
                newState[key] = value;
            }
            return newState;
        default:
            return state;
    }
};

const dateRangesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'set_date':
            let newState = {};
            newState.startDay = action.payload.startDay;
            newState.endDay = action.payload.endDay;
            newState.label = action.payload.label;
            newState.startMoment = action.payload.startMoment;
            newState.endMoment = action.payload.endMoment;
            newState.weekDates = action.payload.weekDates;
            return newState;
        default:
            return state;
    }
};

const currentDateRangesReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_current_date':
            let newState = {};
            newState.startDay = action.payload.startDay;
            newState.endDay = action.payload.endDay;
            newState.label = action.payload.label;
            newState.startMoment = action.payload.startMoment;
            newState.endMoment = action.payload.endMoment;
            newState.weekDates = action.payload.weekDates;
            return newState;
        default:
            return state;
    }
};

const reportDateRangesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'set_timecard_report_date_range':
            let newState = {};
            newState.startDay = action.payload.startDay;
            newState.endDay = action.payload.endDay;
            newState.label = action.payload.label;
            newState.startMoment = action.payload.startMoment;
            newState.endMoment = action.payload.endMoment;
            newState.weekDates = action.payload.weekDates;
            return newState;
        default:
            return state;
    }
};

const showUnsubmittedLaborTechsReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_show_unsubmitted_labor':
            return action.payload;
        default:
            return state;
    }
};
const showUnsubmittedClockTechsReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_show_unsubmitted_clock':
            return action.payload;
        default:
            return state;
    }
};
const showUnsubmittedExpensesTechsReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_show_unsubmitted_expenses':
            return action.payload;
        default:
            return state;
    }
};

const tableRowInitialState = [{
    itemCode: "No records found",
    jobNumber: "",
    jobUri: "",
    week: [{ 
        day: "Sun",
        value: 0
    }, {
        day: "Mon",
        value: 0
    }, {
        day: "Tue",
        value: 0
    }, {
        day: "Wed",
        value: 0
    }, {
        day: "Thu",
        value: 0
    }, {
        day: "Fri",
        value: 0
    }, {
        day: "Sat",
        value: 0
    }],
    weekTotal: 0
}]
const tableRowReducer = (state = tableRowInitialState, action) => {
    switch (action.type) {
        case 'set_rows':
            let newState = [];
            for (let x = 0; x < action.payload.model.length; x++) {
                newState.push(action.payload.model[x]);
            }
            if (action.payload.model.length === 0) {
                if (action.techID) {
                    tableRowInitialState[0].technician_id = action.techID
                }
                newState.push(tableRowInitialState[0]);
            }
            return newState;
        default:
            return state;
    }
};
const unCombinedReducer = (state = [], action) => {
    switch (action.type) {
        case 'set_uncombined':
            let newState = [];
            for (let x = 0; x < action.payload.length; x++) {
                newState.push(action.payload[x]);
            }
            return newState;
        default:
            return state;
    }
};


const summaryReducer = (state = [], action) => {
    switch (action.type) {
        case 'set_summary':
            let newState = [];
            for (let x = 0; x < action.payload.length; x++) {
                newState.push(action.payload[x]);
            }
            return newState;
        default:
            return state;
    }
};

const additionalRowsReducer = (state = [], action) => {
    let newState = [];
    switch (action.type) {
        case 'set_timecard_rows':
            action.payload.forEach((row) => {
                newState.push(row);
            });
            return newState;
        case 'update_timecard_rows':
            action.payload.forEach((row) => {
                newState.push(row);
            });
            return newState;
        default:
            return state;
    }
}

const prevDateReducer = (state = {
    shouldUse: false,
    startEndDates: {}
}, action) => {
    switch (action.type) {
        case 'set_previous_used_date':
            let newState = {};
            newState.shouldUse = action.payload.shouldUse;
            newState.startEndDates = action.payload.startEndDates;
            return newState;
        default:
            return state;
    }
};

const workReducer = (state = 0, action) => {
    switch (action.type) {
        case 'set_work_total':
            return action.payload;
        default:
            return state;
    }
};
const grandTotalReducer = (state = 0, action) => {
    switch (action.type) {
        case 'set_grand_total':
            return action.payload;
        default:
            return state;
    }
};

const timeCardReportReducer = (state = [], action) => {
    let newState = [];
    switch (action.type) {
        case 'set_timecard_report':
            action.payload.forEach((row) => {
                newState.push(row);
            });
            return newState;
        default:
            return state;
    }
}
const bulkTimeCardReportActionsReducer = (state = [], action) => {
    let newState = [];
    switch (action.type) {
        case 'set_bulkActions':
            action.payload.forEach((row) => {
                newState.push(row);
            });
            return newState;
        default:
            return state;
    }
}
const placeHolderReducer = (state = [], action) => {
    let newState = [];
    switch (action.type) {
        case 'set_placeHolder':
            action.payload.forEach((row) => {
                newState.push(row);
            });
            return newState;
        default:
            return state;
    }
}
const alertReducer = (state = {
    active: false,
    message: '' 
}, action) => {
    let newState = {};
    switch (action.type) {
        case 'set_alert':
            newState['active'] = action.payload.active;
            newState['message'] = action.payload.message;
            newState['isError'] = action.payload.isError;
            newState['isInfo'] = action.payload.isInfo;
            return newState;
        default:
            return state;
    }
}
const editModalReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_edit_mode':
            return action.payload;
        default:
            return state;
    }
}

const addModalReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_add_mode':
            return action.payload;
        default:
            return state;
    }
}
const libItemsReducer = (state = [], action) => {
    switch (action.type) {
        case 'set_libItems':
            return action.payload;
        default:
            return state;
    }
}
const serviceLinesReducer = (state = [], action) => {
    switch (action.type) {
        case 'set_serviceLines':
            return action.payload;
        default:
            return state;
    }
}
const jobModalReducer = (state = false, action) => {
    switch (action.type) {
        case 'save_job_modal':
            return action.payload;
        default:
            return state;
    }
}

const templateTypeReducer = (state = 'Labor', action) => {
    switch (action.type) {
        case 'set_template_type':
            return action.payload;
        default:
            return state;
    }
}
const templateColumnsReducer = (state = [], action) => {
    switch (action.type) {
        case 'set_template_columns':
            return action.payload;
        default:
            return state;
    }
}
const templateModelReducer = (state = {
    company_id: false,
    data: [],
    groupByItemCode: false,
    name: false,
    type: false,
    stac: false,
    token: false,
    isStacConnector: false
}, action) => {
    let newState = {};
    
    switch (action.type) {
        case 'set_template_model':
            newState = {};
            // apply the key / values to the brand new object from the original default
            for (const [key, value] of Object.entries(state)) {
                newState[key] = value;
            }
            // apply the key(s) passed to the new object. Should only be one key at a time
            for (const [key, value] of Object.entries(action.payload)) {
                newState[key] = value;
            }
            return newState;
        default:
            return state;
    }
}

const templateEditModeReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_open_edit_template_model':
            return !state;
        default:
            return state;
    }
}

const stepListenerReducer = (state = 0, action) => {
    let counter = 0;
    switch (action.type) {
        case 'trigger_template_name_save':
            counter = counter + state;
            return counter + 1;
        default:
            return state;
    }
}
const templateImageHoverReducer = (state = true, action) => {
    switch (action.type) {
        case 'set_template_type_hover_image':
            return action.payload
        default:
            return state;
    }
}
const incrementTemplateSubStepReducer = (state = 0, action) => {
    switch (action.type) {
        case 'set_template_step_reducer':
            return action.payload
        default:
            return state;
    }
}
const analyticsDateRangeReducer = (state = 7, action) => {
    switch (action.type) {
        case 'set_analytics_date_range':
            return action.payload
        default:
            return state;
    }
}
const usageGraphIdReducer = (state = 0, action) => {
    switch (action.type) {
        case 'set_usage_graph_id':
            return action.payload
        default:
            return state;
    }
}
const usageGraphModeReducer = (state = false, action) => {
    switch (action.type) {
        case 'set_usage_graph_mode':
            return action.payload
        default:
            return state;
    }
}
const lockTimecardReducer = (state = {
    status: false,
    submittedOn: 0
}, action) => {
    switch (action.type) {
        case 'set_locked_timecard':
            return action.payload
        default:
            return state;
    }
}

const clockExportsReducer = (state = [], action) => {
    switch (action.type) {
        case 'set_clockExports':
            return action.payload
        default:
            return state;
    }
}

const exportModalReducer = (state = false, action) => {
    switch (action.type) {
        case 'open_export_modal':
            return true
        case 'close_export_modal':
            return false
        default:
            return state;
    }
}

const auditFilterReducer = (state = {
    start: false,
    end: false,
    name: false,
    technician: false,
    type: false
}, action) => {
    let newState = {};
    
    switch (action.type) {
        case 'set_audit_filter':
            newState = {};
            // apply the key / values to the brand new object from the original default
            for (const [key, value] of Object.entries(state)) {
                newState[key] = value;
            }
            // apply the key(s) passed to the new object. Should only be one key at a time
            for (const [key, value] of Object.entries(action.payload)) {
                newState[key] = value;
            }
            return newState;
        default:
            return state;
    }
}

const historyModalReducer = (state = {
    open: false,
    id: false,
    name: false,
    history: false
}, action) => {
    switch (action.type) {
        case 'set_history_modal':
            return action.payload;
        default:
            return state;
    }
}

const showOnBreakInSummaryReducer = (state = false, action) => {
    switch (action.type) {
        case 'show_on_break_in_summary':
            return action.payload
        default:
            return state;
    }
}

const timecardReleaseStatusReducer = (state = false, action) => {
    switch (action.type) {
        case 'timecard_is_released':
            return true
        case 'timecard_not_released':
            return false
        default:
            return state;
    }
}

const rootReducer = combineReducers({
    login: loginReducer,
    timeCardRows: additionalRowsReducer,
    dateRanges: dateRangesReducer,
    currentDateRange: currentDateRangesReducer,
    reportDateRanges: reportDateRangesReducer,
    tableRows: tableRowReducer,
    appSettings: settingsReducer,
    prevDate: prevDateReducer,
    view: viewTimeCardReducer,
    edit: editTimeCardReducer,
    technician: technicianReducer,
    timeCardType: timeCardTypeReducer,
    listOfAccountUsers: usersReducer,
    // do not persist / whitelist
    imported: importReducer,  
    officeList: officeListReducer,
    selectedOffice: selectedOfficeReducer,
    selectedEmail: selectedEmailReducer,
    isProcessing: backdropReducer,
    applicationPage: pageReducer,
    menuOpen: menuReducer,
    grandTotal: grandTotalReducer,
    workTotal: workReducer,
    report: timeCardReportReducer,
    bulkActions: bulkTimeCardReportActionsReducer,
    alert: alertReducer,
    editMode: editModalReducer,
    modal: editModalReducer,
    addModal: addModalReducer,
    libItems: libItemsReducer,
    serviceLines: serviceLinesReducer,
    jobModal: jobModalReducer,
    templateList: templateListReducer,
    summary: summaryReducer,
    templateType: templateTypeReducer,
    templateColumns: templateColumnsReducer,
    templateModel: templateModelReducer,
    templateEditMode: templateEditModeReducer,
    stepListener: stepListenerReducer,
    templateImageHover: templateImageHoverReducer,
    incrementTemplateSubStep: incrementTemplateSubStepReducer,
    analyticsDateRange: analyticsDateRangeReducer,
    usageGraphId: usageGraphIdReducer,
    usageGraphMode: usageGraphModeReducer,
    analytics: analyticsReducer,
    isLocked: lockTimecardReducer,
    auditFilter: auditFilterReducer,
    showUnsubmittedLabor: showUnsubmittedLaborTechsReducer,
    showUnsubmittedClock: showUnsubmittedClockTechsReducer,
    showUnsubmittedExpenses: showUnsubmittedExpensesTechsReducer,
    showExportModal: exportModalReducer,
    constTimecards: placeHolderReducer,
    clockExports: clockExportsReducer,
    unCombinedEntries: unCombinedReducer,
    openHistory: historyModalReducer,
    showOnBreakInSummary: showOnBreakInSummaryReducer,
    selectedRecipient: selectedRecipientReducer,
    timecardReleaseStatus: timecardReleaseStatusReducer
})

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['login', 'timeCardRows', 'dateRanges', 'tableRows', 'appSettings', 'prevDate', 'view', 'edit','technician', 'timeCardType', 'menuOpen', 'listOfAccountUsers' ]
};
const middleware = [thunk]
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(...middleware));
const persistor = persistStore(store);

export {store, persistor}