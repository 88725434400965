import {
    TEMPLATE_REQUEST,
    TEMPLATE_REQUEST_SUCCESS,
    TEMPLATE_REQUEST_FAIL,
    TEMPLATE_DELETE_REQUEST,
    TEMPLATE_DELETE_SUCCESS,
    TEMPLATE_DELETE_FAIL,
    LABOR_HEADER_UPDATE,
    WEEKDAY_ORDER_SETUP,
    UPDATE_UNIVERSAL_HEADER
} from '../constants/templateConstants'


export const templateListReducer = (state = { templates: [], laborItemSelected: [], laborItemHeader: [], clockEventSelected: [], clockHeader: [], weekDay: [], header: [] }, action) => {

    switch (action.type) {
        case TEMPLATE_REQUEST:
            return { ...state, loading: true, templates: [] }

        case TEMPLATE_REQUEST_SUCCESS:
            return { ...state, laborItem: [3], loading: false, templates: action.payload, laborItemSelected: action.payload[1].data }

        case TEMPLATE_REQUEST_FAIL:
            return { loading: false, error: action.payload }

        case TEMPLATE_DELETE_REQUEST:
            return { ...state, loading: true }

        case TEMPLATE_DELETE_SUCCESS:
            return { ...state, templates: action.payload, loading: false }

        case TEMPLATE_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case LABOR_HEADER_UPDATE:
            return { ...state, laborItemHeader: action.payload }
        case WEEKDAY_ORDER_SETUP:
            return { ...state, weekDay: action.payload }
        case UPDATE_UNIVERSAL_HEADER:
            return {...state, header:action.payload}


        default:
            return state;

    }
}
