import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Alert from '@material-ui/lab/Alert';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect, useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import TemplateType from './templateTypeSelection';
import TemplateColumnContainer from './templateColumnContainer';
import TemplateOrder from './templateOrder';
import TemplateColumnName from './templateColumnName';
import { addTemplate } from '../../actions/templateActions';
import { getTemplate, updateTemplateData, updateDefaultTemplate } from '../../actions/templateActions'
import {templateTypes} from '../../Types/TemplateTypes'
import UnGroupedIcon from '@material-ui/icons/List';
import GroupedIcon from '@material-ui/icons/ListAlt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import TemplateGroupByImage from './templateGroupByImage';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepOne: {
        '& input': {
            height: 100,
            fontSize: 50
        }
    },
    stepContainer: {
        margin: '0 auto',
        width: '85%'
    },
    formButtons: {
        marginLeft: '50px'
    },
    alert: {
        marginTop: 15,
        marginBottom: 15
    },
    stepThree: {
        width: '200px',
        height: '200px'
      },
    icon: {
        height: '50px',
        width: '50px'
    }
}));

function getSteps(editMode) {
    if (editMode) {
        return ['Rename your template','Select the columns', 'Rename the columns', 'Order the columns'];
    }
    return ['Choose a name', 'Pick a type', 'Choose a format','Select the columns', 'Rename the columns', 'Order the columns'];
}

const TemplateStep = (props) => {
    const classes = useStyles();
    let [type, setType] = React.useState(1);
    const [activeStep, setActiveStep] = React.useState(0);
    const [showAlert, setShowAlert] = React.useState(false);
    const [templateName, setTemplateName] = React.useState(props.templateEditMode ? props.templateModel.name : '');
    const [temporaryName, setTempName] = React.useState('')
    const steps = getSteps(props.templateEditMode);
    let setOpen = props.setOpen;

    const hasFeature = (name) => {
        let features = props.appSettings.features;
        let hasFeature = false;
        if (features) {
            let feature = features.find((f) => { return f === name });
            if (!feature) {
                hasFeature = false;
            } else {
                hasFeature = true;
            }
        }
        return hasFeature;
    }

    const templateList = useSelector((state) => {
        return state.templateList
    })

    const { templates } = templateList
    const dispatch = useDispatch()

    const handleNameChange = (e) => {
        setTemplateName(e.target.value)
        setShowAlert(false)
    }

    const handleTypeChange = (e, type) => {

        // if type is {1}, ungrouped, set false, otherwise true
        props.dispatch({
            type: 'set_template_model',
            payload: {
                // set only the key that has been chnaged
                groupByItemCode: type
            }
        });

        props.dispatch({
            type:'set_template_type_hover_image',
            payload: type 
        })

        setType(type);
    };

    const getStepContent = (stepIndex, editMode) => {
        if (editMode) {
            switch (stepIndex) {
                case 0:
                    return (
                        <>
                            <div className={classes.stepContainer}>
                                <TextField defaultValue={templateName} className={classes.stepOne} label="Template Name" onChange={handleNameChange} ></TextField>
                            </div>
                        </>);
                case 1:
                    return (
                        <>
                            <div className={classes.stepContainer}>
                                <TemplateColumnContainer />
                            </div>
                        </>);
                case 2:
                    return (
                        <>
                            <div className={classes.stepContainer}>
                                <TemplateColumnName />
                            </div>
                        </>);
                case 3:
                    return (
                        <>
                            <div className={classes.stepContainer}>
                                <TemplateOrder />
                            </div>
                        </>);
                default:
                    return 'Unknown';
            }
        }
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <div className={classes.stepContainer}>
                            <TextField className={classes.stepOne} label="Template Name" onChange={handleNameChange} ></TextField>
                        </div>
                    </>);
            case 1:
                return (
                    <>
                        <div className={classes.stepContainer}>
                            <Typography variant="overline" display="block" gutterBottom>Select a type</Typography>
                            <TemplateType />
                        </div>
                    </>);
            case 2: 
                    return (<>
                        <Typography variant="overline" display="block" gutterBottom>Would you like to group by item code?</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <ToggleButtonGroup
                                    value={type}
                                    exclusive
                                    onChange={handleTypeChange}
                                    aria-label="Grouped or ungrouped"
                                >
                                    <ToggleButton className={classes.stepThree} value={templateTypes.UNGROUPED} aria-label="Ungrouped" >
                                        <Tooltip title="Ungrouped" aria-label="Ungrouped">
                                            <UnGroupedIcon className={classes.icon}/>
                                        </Tooltip>
                                        Ungrouped
                                    </ToggleButton>
                                    <ToggleButton className={classes.stepThree} value={templateTypes.GROUPED} aria-label="Grouped" > 
                                        <Tooltip title="Grouped" aria-label="Grouped">
                                            <GroupedIcon className={classes.icon}/>
                                        </Tooltip>
                                        Grouped
                                    </ToggleButton>

                                    <ToggleButton className={classes.stepThree} value={templateTypes.QBD} aria-label="Quickbooks_desktop" > 
                                        <Tooltip title="Grouped" aria-label="Quickbooks_desktop">
                                            <GroupedIcon className={classes.icon}/>
                                        </Tooltip>
                                            Quickbooks Desktop Grouping
                                    </ToggleButton>

                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs>
                                <TemplateGroupByImage />
                            </Grid>
                        </Grid>
                    </>)
            case 3:
                return (
                    <>
                        <div className={classes.stepContainer}>
                            <TemplateColumnContainer />
                        </div>
                    </>);
            case 4:
                return (
                    <>
                        <div className={classes.stepContainer}>
                            <TemplateColumnName />
                        </div>
                    </>);
            case 5:
                return (
                    <>
                        <div className={classes.stepContainer}>
                            <TemplateOrder />
                        </div>
                    </>);
            default:
                return 'Unknown';
        }
    }

    useEffect(() => {
        setTempName(templateName)
    }, [templateName])

    const handleNext = async () => {
        let data;
        if (activeStep === 0) {
            // if clicking next while on the first step, set the template name onto the model
            let currentTemplateName = templates.map(item => item.name.toLowerCase())

            // if not on edit mode you will not be able to continue if the template name appears to be already taken in a diffetent template
            if (!props.templateEditMode) {
                if (currentTemplateName.includes(templateName.toLowerCase())) {
                    setShowAlert(true)
                    setActiveStep(-1);
                } else {
                    props.dispatch({
                        type: 'set_template_model',
                        payload: {
                            name: templateName
                        }
                    })
                }
                // When on edit mode you can procceed with the original name but not be able to continue with a name matching templates 
            } else {
                if (props.templateEditMode) {
                    let templatesAvailable = templates.map(item => item.name.toLowerCase()).filter(item => item !== temporaryName.toLowerCase())

                    if (templatesAvailable.includes(templateName.toLowerCase())) {
                        setShowAlert(true)
                        setActiveStep(-1);
                    }
                } else {
                    props.dispatch({
                        type: 'set_template_model',
                        payload: {
                            name: templateName
                        }
                    })
                    setShowAlert(false)
                }
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        
        if (activeStep === steps.length - 1) {
            if (props.templateEditMode) {
                // update the template array with the newest index.
                const updatedTemp = props.templateModel.data.map((item, index) => {
                    return {
                        ...item,
                        index: index
                    }
                })

                let updatePayload = {
                    ...props.templateModel,
                    data: updatedTemp.slice(0, props.templateModel.data.length),
                    name: templateName
                };
                data = await updateTemplateData(updatePayload, props);
                props.dispatch({
                    type: 'set_open_edit_template_model',
                    payload: false
                });
            } else {
                data = await addTemplate(props.templateModel, props).catch((e) => {
                    console.log(e, 'An Error has occured')
                });
                setOpen(false);
            }

            if (data) {
                props.setTemplateList(data);
            }

            if (props.templateEditMode) {
                if (props.appSettings.laborDefault.name === temporaryName) {
                    updateDefaultTemplate(props, 'laborDefault')
                } else if (props.appSettings.clockDefault.name === temporaryName) {
                    updateDefaultTemplate(props, 'clockDefault')
                }
            }
            dispatch(getTemplate(props));
        }
    };

    const handleBack = () => {
        if (!props.templateEditMode && (activeStep - 1) === 3) {
            props.dispatch({
                type: 'set_template_step_reducer',
                payload: 0
            })
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleReset = () => {
        if (!props.templateEditMode) {
            props.dispatch({
                type: 'set_template_step_reducer',
                payload: 0
            })
        }
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>Template Saved</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div>
                        <Typography className={classes.instructions}>{getStepContent(activeStep, props.templateEditMode)}</Typography>
                        {showAlert && activeStep === 0 && <Alert className={classes.alert} severity="warning">This name is already saved, please select a unique template name </Alert>}
                        <div className={classes.formButtons}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                Back
                            </Button>
                            <Button variant="contained" disabled={templateName === '' && !props.templateEditMode} color="primary" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(TemplateStep);