import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import GroupedImgLink from '../../assets/imgs/Grouped_Export.png';
import UngroupedImgLink from '../../assets/imgs/Ungrouped_Export.png';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    img: {
      maxWidth: '300px'
    }
  }));

const TemplateGroupByImage = (props) => {
    const classes = useStyles();
    let [showUnGroupedImg, setImage] = useState(true);

    useEffect(() => {
        setImage(props.templateImageHover);
    }, [props.templateImageHover])

    return (
        <>
            <div className={classes.root}>
                {!showUnGroupedImg && (
                <>
                    <Grid container direction="column" justify="center" alignItems="flex-start">
                        <Typography variant={'caption'}>Ungrouped</Typography>
                        <img className={classes.img} src={UngroupedImgLink}/>
                        <Typography variant={'caption'}>A job item per row separated by item code and date</Typography>
                    </Grid>
                </>)}
                {showUnGroupedImg && (
                <>
                    <Grid container direction="column" justify="center" alignItems="flex-start">
                        <Typography variant={'caption'}>Grouped</Typography>
                        <img className={classes.img} src={GroupedImgLink}/>
                        <Typography variant={'caption'}>A tech per row with a sum of job items by item code in the weekly period</Typography>
                    </Grid>
                </>)}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(TemplateGroupByImage);
