import { REQUEST_DATA, 
    REQUEST_DATA_FAIL,  
    REQUEST_COMPANY_SUBMISSIONS} from "../constants/analytics";

export const analyticsReducer = (state = { clickActions: [], CompanySubmissions: []  }, action) => {

    switch (action.type) {
        
        case REQUEST_DATA:
            return { ...state, clickActions: action.payload}

        case REQUEST_DATA_FAIL:
            return {...state, error: action.payload}
        
        case REQUEST_COMPANY_SUBMISSIONS: 
            return {...state, CompanySubmissions: action.payload }
            
        default:
            return state;

    }
}
