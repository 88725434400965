import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { round, convertDecimalToHoursToHourly } from '../../../utilities';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1),
    },
    additionalRows: {
        maxWidth: 30
    },
    comments: {
        width: '100%'
    },
    accordion: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    submit: {
        margin: 10
    },
    summary: {
        borderBottom: 'none',
    }
}));

const AdditionalRow = (props) => {
    const classes = useStyles();
    const { typeName } = props
    let [row, setRow] = useState(props.row);
    let renderDecimalFormatClock = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;

    useEffect(() => {
        setRow(props.row);
    }, [props.row]);

    return (
        <>
            <TableRow>
                <TableCell className={props.readOnly ? classes.summary : ''} colSpan={props.readOnly && 0}>
                    <Typography className={classes.root}>
                        {row.label}
                    </Typography>
                </TableCell>
                {Array.from({ length: 3 }).map(() => <TableCell className={props.readOnly ? classes.summary : ''}></TableCell>)}

                {props.appSettings.clockEventTypes.onbreak && props.appSettings.clockEventTypes.onbreak === true && props.isViewTimeCard === true && (<TableCell style={{borderBottom: 'none'}}></TableCell>)}
                {props.appSettings.weekDayOrder && props.appSettings.weekDayOrder.length > 0 && props.appSettings.weekDayOrder.map((day) => (
                    <TableCell align={props.readOnly ? 'center' : ''} className={props.readOnly ? classes.summary : ''} key={uuid()}>
                        {
                            renderDecimalFormatClock && typeName === 'Clock Events'
                                ? convertDecimalToHoursToHourly(round(row[day.day]))
                                : round(row[day.day])
                        }
                    </TableCell>
                ))}

                <TableCell align={'center'} className={props.readOnly ? classes.summary : ''}>
                    {renderDecimalFormatClock && typeName === 'Clock Events' ? convertDecimalToHoursToHourly(round(row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat']))
                        : round(row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat'])}
                </TableCell>
            </TableRow>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(AdditionalRow);
