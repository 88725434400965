import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const LoadingScreen = ({ message = "Loading" }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress />
      <Typography variant="h6" component="p" marginTop={2}>
        {message}
      </Typography>
    </Box>
  );
}

export default LoadingScreen;