import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        border: '1px solid #c3c3c3',
        borderRadius: 10,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '94.5%',
        marginLeft: 3
    },
    typography: {
        paddingTop: 10
    }
  }));

  const AddJobItemButton = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.typography} variant="h5" gutterBottom onClick={props.onClick}>
                <IconButton aria-label="add" className={classes.margin}>
                    <AddIcon fontSize="small" />
                </IconButton>
                Add Job Item
            </Typography>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(AddJobItemButton);