import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import { requestHeaderConfig } from '../../utilities';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TemplateColumnSelection = (props) => {
    let [columns, setColumns] = useState([]);
    const [selections, setSelections] = useState([]);
    const [disableItemCodeOnly, setDisableItemCode] = useState(false)

    const handleChange = (e, selectionArray) => {
        selectionArray = selectionArray.map((item, idx) => {
            return {
                header: item.code,
                index: idx,
                value: '',
                alias: item.label,
                id: item.id
            };
        });
        props.dispatch({
            type: 'set_template_model',
            payload: {
                // set only the key that has been chnaged
                data: selectionArray
            }
        });
        setSelections(extractSelections(columns, selectionArray));
    }

    // this will limit columns to only its applicate template type and group/ungroup setting. It will also limit by feature flag
    const limitByTemplateType = (columns) => {
        const template = props.templateModel;
        const type = template.type.toLowerCase();

        columns = columns.filter((column, index) => {
            if (column.feature && column.feature.includes('qbd') && props.templateImageHover != 'QBD') {
                return false;
            }
            if (type === 'expenses') {
                // expenses has no filter, allow all columns
                return true
            }

            if (column.feature && column.feature.length) {
                for (let x = 0; x < column.feature.length; x++) {
                    let feature = column.feature[x];
                    // if the "feature" required to use this column is not enabled for this account, or if this account has no features
                    if (props.appSettings && props.appSettings.features && !props.appSettings.features.includes(feature) && column.feature[x] && feature.toLowerCase() !== 'qbd') {
                        return false;
                    } else if(props.appSettings && !props.appSettings.features)  {
                        return false;
                    }
                }
            }
            // some selected columns are actually additional rows and do not have a type. add a check for that
            if ( column.types ) {
                return column.types.includes(type)
            } else {
                return true;
            }
            
        })
        return columns
    }

    useEffect(() => {
        const additionalRows = props.appSettings.additionalRows.map((item) => {
            return {
                id: item.id,
                label: item.label,
                code: item.label
            }
        });
        
        axiosInstance.post('/template/columns', {
            itemCodes: props.templateModel.groupByItemCode,
            templateType: !props.templateEditMode ? props.templateType : props.templateModel.type
        }, requestHeaderConfig(props.login.authToken)).then((response) => {
            let availableColumns = response.data;   
            let columns = limitByTemplateType([...availableColumns, ...additionalRows]);

            if (props.templateEditMode) {
                let preSelectedColumns = props.templateModel.data;
                let defaultColumns = extractSelections(columns, preSelectedColumns); // 
                setSelections(defaultColumns);
                setColumns(columns);
            } else {
                if (props.templateModel.groupByItemCode === true) {
                    let itemCodeSelection = availableColumns.map((item) => {
                        if (!item.itemCodeAvailable && item.itemCodeAvailable !== undefined) {
                            if (props.templateModel.type === "Labor") {
                                return { ...item, label: item.label + " (not available with grouping)" }
                            } 
                        }
                        return { ...item, label: item.label }
                    })
                    setColumns(limitByTemplateType([...itemCodeSelection, ...additionalRows]));
                    setDisableItemCode(true)
                } else {
                    setColumns(limitByTemplateType([...availableColumns, ...additionalRows]));
                    setDisableItemCode(false)
                }

            }

        }).catch((e) => {
            console.warn(e, 'Could not retrieve columns');
        });
    }, []);

    const extractSelections = (columns, preSelectedColumns) => {
        return columns.filter((column) => {
            for (let x = 0; x < preSelectedColumns.length; x++) {
                if (preSelectedColumns[x].header === column.code) {
                    return true;
                }
            }
            return false;
        });
    }

    if (!columns.length) {
        return (<><LinearProgress /></>)
    }
    return (
        <Autocomplete
            multiple
            id="template-selections"
            value={selections}
            options={columns}
            disableCloseOnSelect
            onChange={handleChange}
            getOptionDisabled={(option) => option.hasOwnProperty("itemCodeAvailable") && !option.itemCodeAvailable && disableItemCodeOnly === true}
            getOptionLabel={(option) => option.label}
            renderOption={(option, { selected }) => (
                <>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.label}
                </>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Columns" placeholder="Select 1 or more columns" />
            )}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(TemplateColumnSelection);