import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { requestHeaderConfig } from '../../utilities';
import axiosInstance from '../../utilities/axios';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  }
}));

const AdditionalRows = (props) => {
  const classes = useStyles();
  let [label, setAdditionalRowLabel] = useState('');
  let [model, setModel] = useState([]);

  const handleChange = (field) => {
    setAdditionalRowLabel(field.target.value)
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAdd();
    }
  }

  const handleAdd = () => {
    var rows = [{
      id: uuid(),
      label: label,
      Sun: 0,
      Mon: 0,
      Tue: 0,
      Wed: 0,
      Thu: 0,
      Fri: 0,
      Sat: 0,
    }, ...props.appSettings.additionalRows];
    axiosInstance.post('/settings', {
      companyId: props.login.user.company.id,
      key: 'additionalRows',
      value: rows
    }, requestHeaderConfig(props.login.authToken)).then(response => {
      let updatedSettingsObj = response.data;
      props.dispatch({
        type: 'set_settings',
        payload: updatedSettingsObj
      });
      setAdditionalRowLabel('');
    }).catch((e) => {
      console.warn(e, 'Could not save settings for : Notification Emails');
    });
  };

  const handleDelete = (selectedRow) => {
    axiosInstance.post('/settings', {
      companyId: props.login.user.company.id,
      key: 'additionalRows',
      value: model.filter((additionalRow) => additionalRow.id !== selectedRow.id)
    }, requestHeaderConfig(props.login.authToken)).then(response => {
      let updatedSettingsObj = response.data;
      props.dispatch({
        type: 'set_settings',
        payload: updatedSettingsObj
      })
    }).catch((e) => {
      console.warn(e, 'Could not save settings for : Notification Emails');
    });
  };

  useEffect(() => {
    setModel(props.appSettings.additionalRows);
  }, [props.appSettings]);

  return (
    <TableContainer>
      <Table size="small" className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {model.map((row) => (
            <TableRow key={uuid()}>
              <TableCell component="th" scope="row">
                <IconButton aria-label="delete" className={classes.margin} onClick={e => handleDelete(row)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
                {row.label}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter align="left">
          <TextField style={{marginLeft: 7}} id="standard-basic" label="Row Name" value={label} onKeyPress={handleKeyPress} onChange={handleChange} />
          <Button style={{ marginLeft: 22, marginTop:22, maxHeight:30}} variant="contained" color="primary" align="right" onClick={handleAdd}>Add</Button>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state
  };
};
export default connect(mapStateToProps)(AdditionalRows);