import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { connect } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const History = (props) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    props.dispatch({
      type: 'set_history_modal',
      payload: {
          open: false,
          id: false,
          name: false,
          history: false
      }
  });
  };

  useEffect(() => {
    if (props && props.openHistory && props.openHistory.open === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.openHistory])

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
      <Box sx={{ ...style, width: 400 }}>
        <h2 id="parent-modal-title">{props.openHistory && props.openHistory.name && (<>{props.openHistory.name}</>)}</h2>
            <List dense={true} sx={{
              width: '100%',
              maxWidth: 400,
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 400,
              '& ul': { padding: 0 },
            }}>
                {open && props.openHistory && props.openHistory.history && props.openHistory.history.length > 0 && props.openHistory.history.map((log) => { return (<ListItem>
                  <ListItemIcon>
                    <AccessTimeIcon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${log.author ? log.status + ' by ' + log.author : log.status}`}
                    secondary={log.timestamp}
                  />
                </ListItem>)})}
            </List>
      </Box>
    </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      ...state,
  };
};
export default connect(mapStateToProps)(History);