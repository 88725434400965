import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import TimeCardEntry from '../timecard/timeCardEntry';
import SelectTechnician from './selectTechnician';
import Modal from './editModal';
import { requestHeaderConfig } from '../../utilities';
import LoadingScreen from '../loading';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    types: {
        fontSize: 10,
        backgroundColor: theme.palette.background.paper,
    },
    jobItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1)
        },
        fontSize: 10,
        float: "right"
    },
    rangeSelector: {
        maxWidth: 250,
        display: "flex",
        justifyContent: "space-evenly"
    },
    dateRange: {
        marginTop: 13,
        minWidth: 115
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    hidden: {
        display: 'none'
    }
  }));

const getStartEndDates = (calendarStartDay, prevWeek, nextWeek, startMoment, endMoment) => {
    let days = [],
        noDaysInWeek = 7,
        label = "",
        diff;

    // collect the last 7 days
    for (let x = 0; x < 7; x++) {
        days.push(moment().subtract(x, 'days'));
    }

    // on initial set
    if (!prevWeek && !nextWeek) {
        days.forEach((day) => {
            if (day.toString().split(' ')[0] === calendarStartDay) {
                startMoment = day.startOf('day');
                // calculate the difference been our week start and today to get the week end day.
                diff = moment().diff(startMoment, 'days')
                let numDaysUntilEndWeek;
                // console.log('Week start day was '+ diff +' days ago. You need to add ' + ((noDaysInWeek - diff) - 1) + ' days onto today to get the end day');
                numDaysUntilEndWeek = ((noDaysInWeek - diff) - 1);
                endMoment = moment().startOf('day').add(numDaysUntilEndWeek, 'days');
            }
        }); 
    } else if (prevWeek) {
        // if switching date range, we already know our start and end days
        startMoment.subtract(7, 'days');
        endMoment.subtract(7, 'days');
    } else if (nextWeek) {
        startMoment.add(7, 'days');
        endMoment.add(7, 'days');
    }
    label = startMoment.format('MMM DD') + ' - ' + endMoment.format('MMM DD');

    var getDaysBetweenDates = function(startDate, endDate) {
        var now = startDate.clone(), dates = [];
  
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('MM/DD/YYYY'));
            now.add(1, 'days');
        }
        return dates;
    };

    return {
        startDay: startMoment.unix(),
        endDay: endMoment.unix(),
        label: label,
        startMoment: startMoment, // use to increment/decrement date
        endMoment: endMoment,
        weekDates: getDaysBetweenDates(startMoment, endMoment)
    };
};

const calculate = (rows) => {
    let total = rows ? rows.map((row) => {
        return row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat']
    }) : rows;

    if (Array.isArray(total) && total.length === 0) {
        return 0;
    } else {
        return total.reduce((total, value) => total + value)
    }
};

const setInitialType = (props) => {
    if (props && props.appSettings) {
        if (props.appSettings.jobItems && props.appSettings.jobItems.enabled) return 0;
        if (props.appSettings.clockEvents && props.appSettings.clockEvents.enabled) return 1;
        if (props.appSettings.expenses && props.appSettings.expenses.enabled) return 2;
    }
    return 0;
}

const TimeCardContainer = (props) => {
    const classes = useStyles();
    let startDay = props.login.settings.startDay;
    let [dates, setDates] = useState(props.currentDateRange || getStartEndDates(startDay, false, false));
    let [data, setData] = useState();
    let [showBackdrop, setShowBackdrop] = useState(false);
    let [timeCardType, setTimeCardType] = useState(setInitialType(props));
    let [reload, setReload] = useState(0);
    let settingsParams = {
        params: {
            companyId: props.login.user.company.id,
            field: 'startDay'
        },
        ...requestHeaderConfig(props.login.authToken)
    }

    let userObj = {...{}, ...props.login.user};
    delete userObj.activities;
    // if an admin ever changes the start day or the number of time card types, you won't find the proper records, refactor this
    let timeCardParams = {
        params: {
            companyId: props.login.user.company.id,
            combine: false,
            userId: props.technician.id,
            startEpochTime: dates.startDay,
            endEpochTime: dates.endDay,
            startDay: startDay,
            range: dates.label,
            type: timeCardType,
            userObj: {
                timezone: userObj.timezone,
                loginId: userObj.id
            },
            techObj: {
                timezone: props.technician.timezone
            },
            startDateFormat: dates.startMoment.format('MM/DD/YYYY'),
            endDateFormat: dates.endMoment.format('MM/DD/YYYY'),
        },
        ...requestHeaderConfig(props.login.authToken)
    }
    
    useEffect(() => {
        if (reload !== 0) {
            reloadTimeCardData(props.dateRanges, timeCardParams);
        }
    }, [reload])

    const handleTypeChange = (e, newValue) => {
        props.dispatch({
            type: 'set_timeCardType',
            payload: newValue
        });
        setTimeCardType(newValue);
    };

    const handleNext = () => {
        let startEndDates = getStartEndDates(startDay, false, true, props.dateRanges.startMoment, props.dateRanges.endMoment);
        props.dispatch({
            type: 'set_date',
            payload: startEndDates
        });
        props.dispatch({
            type: 'set_current_date',
            payload: startEndDates
        });
        reloadTimeCardData(startEndDates, timeCardParams);
    };
    const handlePrev = () => {
        let startEndDates = getStartEndDates(startDay, true, false, props.dateRanges.startMoment, props.dateRanges.endMoment);
        props.dispatch({
            type: 'set_date',
            payload: startEndDates
        });
        props.dispatch({
            type: 'set_current_date',
            payload: startEndDates
        });
        reloadTimeCardData(startEndDates, timeCardParams);
    };

    const handleCloseBackdrop = () => {
        props.dispatch({
            type: 'set_backdrop',
            payload: false
        });
    };

    useEffect(() => {
        props.dispatch({
            type: 'set_timeCardType',
            payload: timeCardType
        });
        props.dispatch({
            type: 'set_backdrop',
            payload: true
        });
        loadData(props.currentDateRange);
    }, [timeCardType, props.technician]);
    useEffect(() => {
        setShowBackdrop(props.isProcessing)
    }, [props.isProcessing]);

    const fetchTimeCardData = (params) => {
        if (props.appSettings && props.appSettings.endPoints.length === 0) {
            console.warn('None of the three timecard types are enabled.')
            // Handle this more gracefully
            return false;
        }
        var url = props.appSettings.endPoints[timeCardType];

        axiosInstance.get('/status', requestHeaderConfig(props.login.authToken, {
            range: timeCardParams.params.range, type: timeCardParams.params.type, technicianId: timeCardParams.params.userId
        })).then(() => {
            props.dispatch({
                type: 'timecard_is_released',
            });
        }).catch(() => {
            props.dispatch({
                type: 'timecard_not_released',
            });
        });


        return axiosInstance.get(url.replace("/api", ""), params).then((response) => {
            var entries = response.data;
            var returnObj = {
                model: entries && entries.timeCardItems && entries.timeCardItems.length > 0 ? entries.timeCardItems : [],
                week: entries && entries.timeCardItems && entries.timeCardItems.length > 0 ? entries.timeCardItems[0].week : false,
                summary: entries.summary,
                isLocked: entries.isLocked,
                submittedOn : entries.submittedOn,
                unCombinedEntries: entries && entries.unCombinedEntries && entries.unCombinedEntries.length > 0 ? entries.unCombinedEntries : []
            };
            if (entries.clockExports && entries.clockExports.length) {
                returnObj.clockExports = entries.clockExports;

                props.dispatch({
                    type: 'set_clockExports',
                    payload: returnObj.clockExports
                });
            }

            props.dispatch({
                type: 'set_locked_timecard',
                payload: {
                    status: entries.isLocked,
                    submittedOn: entries.submittedOn
                }
            });

            if (entries.additionalRows) {
                returnObj.additionalRows = entries.additionalRows;
            }
            return returnObj;
        })
    }

    const loadData = async (selectedDateRange) => {
        if (props.technician) {
            await axiosInstance.get('/settings', settingsParams)
                .then(async (response) => {
                    let updatedSettingsObj = response.data;
                    updatedSettingsObj.startEndDates = props.currentDateRange || getStartEndDates(updatedSettingsObj.startDay, false, false);
                    props.dispatch({
                        type: 'set_date',
                        payload: props.currentDateRange || getStartEndDates(updatedSettingsObj.startDay, false, false)
                    });
                    props.dispatch({
                        type: 'set_settings',
                        payload: updatedSettingsObj
                    });
                    return updatedSettingsObj;
                }).then(async (settings) => {
                    await loadServiceLinesAndLibItems(settings);
                    return settings;
                }).then(async (settings) => {
                    return settings;
                }).then(async (settings) => {
                    await loadTimeCardData(settings, selectedDateRange);
                    props.dispatch({
                        type: 'set_backdrop',
                        payload: false
                    });
                });
        }
    };
    const loadServiceLinesAndLibItems = async (obj) => {
        if (timeCardType !== 1) {
            await axiosInstance.get('/libItems', {
                params: {},
                ...requestHeaderConfig(props.login.authToken)
                })
                .then(function (response) {
                    props.dispatch({
                        type: 'set_libItems',
                        payload: response.data 
                    });
                })
                .catch(function (error) {
                    console.log(error, ': An error has occured while retrieving the settings');
                });
            await axiosInstance.get('/serviceLines', {
                params: {
                    companyId: props.login.user.company.id
                },
                ...requestHeaderConfig(props.login.authToken)
                })
                .then(function (response) {
                    props.dispatch({
                        type: 'set_serviceLines',
                        payload: response.data
                    });
                })
                .catch(function (error) {
                    console.log(error, ': An error has occured while retrieving the settings');
                });
        }
        return obj;
    };
    const loadTimeCardData = async (settings, selectedDateRange) => {
        props.appSettings.endPoints = settings.endPoints;
        if (selectedDateRange) {
            timeCardParams.params.startEpochTime = selectedDateRange.startDay;
            timeCardParams.params.endEpochTime = selectedDateRange.endDay;
            timeCardParams.params.range = selectedDateRange.label;
            timeCardParams.params.startDateFormat = selectedDateRange.startMoment.format('MM/DD/YYYY');
            timeCardParams.params.endDateFormat = selectedDateRange.endMoment.format('MM/DD/YYYY');
        }
        await fetchTimeCardData(timeCardParams, settings.endPoints).then((timeCardItems) => {
            let rows = timeCardItems.model;
            let summary = timeCardItems.summary;
            let total = 0;
            if (timeCardItems.additionalRows) {
                props.dispatch({
                    type: 'set_timecard_rows',
                    payload: timeCardItems.additionalRows
                });
                if (timeCardItems.additionalRows.length > 0) {
                    props.dispatch({
                        type: 'set_grand_total',
                        payload: calculate(timeCardItems.additionalRows)
                    });
                }
            } else {
                console.warn(timeCardItems, 'NO ADD ROWS rows found for selecting a new type')
            }
            
            // combines total from all records entered by technician in servicetrade
            for (let x = 0; x < rows.length; x++) {
                total = total + rows[x].weekTotal;
            }
            props.dispatch({
                type: 'set_work_total',
                payload: total
            });
            props.dispatch({
                type: 'set_rows',
                techID: props.technician && props.technician.id ? props.technician.id : props.login && props.login.user && props.login.user.id ? props.login.user.id : "Tech ID Not Found",
                payload: timeCardItems
            });
            props.dispatch({
                type: 'set_summary',
                payload: summary
            });
            props.dispatch({
                type: 'set_uncombined',
                payload: timeCardItems.unCombinedEntries
            });
            setData({...settings, ...timeCardItems});
            return timeCardItems;
        });
    }
    const reloadTimeCardData = async (startEndDates, timeCardParams) => {
        props.dispatch({
            type: 'set_date',
            payload: startEndDates
        });
        props.dispatch({
            type: 'set_backdrop',
            payload: true
        });
        timeCardParams.params.startEpochTime = startEndDates.startDay;
        timeCardParams.params.endEpochTime = startEndDates.endDay;
        timeCardParams.params.range = startEndDates.label;
        timeCardParams.params.type = timeCardType;
        timeCardParams.params.startDateFormat = startEndDates.startMoment.format('MM/DD/YYYY');
        timeCardParams.params.endDateFormat = startEndDates.endMoment.format('MM/DD/YYYY');
        await fetchTimeCardData(timeCardParams).then((timeCardItems) => {
            let rows = timeCardItems.model;
            let summary = timeCardItems.summary;
            let total = 0;
            // combines total from all records entered by technician in servicetrade
            for (let x = 0; x < rows.length; x++) {
                total = total + rows[x].weekTotal;
            }
            props.dispatch({
                type: 'set_work_total',
                payload: total
            });
            // add totals for additional rows
            if (timeCardItems.additionalRows) {
                props.dispatch({
                    type: 'set_timecard_rows',
                    payload: timeCardItems.additionalRows
                });
                if (timeCardItems.additionalRows.length > 0) {
                    props.dispatch({
                        type: 'set_grand_total',
                        payload: calculate(timeCardItems.additionalRows)
                    });
                }
            }
            props.dispatch({
                type: 'set_rows',
                techID: props.technician && props.technician.id ? props.technician.id : props.login && props.login.user && props.login.user.id ? props.login.user.id : "Tech ID Not Found",
                payload: timeCardItems
            });
            props.dispatch({
                type: 'set_summary',
                payload: summary
            });
            props.dispatch({
                type: 'set_uncombined',
                payload: timeCardItems.unCombinedEntries
            });
            props.dispatch({
                type: 'set_backdrop',
                payload: false
            });
            setData({...data, ...timeCardItems});
            return timeCardItems;
        })
    }

    const tabClassName = (active) => {
        return !active ? classes.hidden : classes.active;
    }

    if (!data) {
        return (<>
            <LoadingScreen />
        </>);
    }
    
    return (
        <>
            <Backdrop className={classes.backdrop} open={showBackdrop} onClick={handleCloseBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container className={classes.container}>
                {(props.login.canSubmit || props.login.isAdmin) && (<SelectTechnician/>)}
                <Grid container direction="row" justify="space-between" spacing={6} alignItems="center">
                    <Grid item xs={5} className={classes.rangeSelector}>
                        <IconButton aria-label="prev" className={classes.left} onClick={handlePrev}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <Box className={classes.dateRange}>
                            <strong>{props.dateLabel}</strong>
                        </Box>
                        <IconButton aria-label="next" className={classes.right} onClick={handleNext}>
                            <ChevronRightIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={7}>
                        <Tabs
                            value={timeCardType}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTypeChange}
                            aria-label="timeCardTypeSelectors"
                        >   
                                <Tab className={tabClassName(props.appSettings.jobItems.enabled)} label="Labor Items" />
                                <Tab className={tabClassName(props.appSettings.clockEvents.enabled)} label="Clock Events" />
                                <Tab className={tabClassName(props.appSettings.expenses.enabled)} label="Expenses" />
                        </Tabs>
                    </Grid>   
                </Grid>
                <TimeCardEntry />
            </Container>
            {props.modal.open && (<Modal count={reload} setReload={setReload}/>)}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
        startEpoch: state.dateRanges.startDay,
        endEpoch: state.dateRanges.endDay,
        dateLabel: state.dateRanges.label,
        tableRows: state.tableRows,
        appSettings: state.appSettings
    };
};
export default connect(mapStateToProps)(TimeCardContainer);