import React from 'react';
import axiosInstance from '../../utilities/axios';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function SimpleModal() {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(true);
    const [ready, setReady] = React.useState(false);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    
    const handleClick = () => {
        if (!ready) {
            openInNewTab(process.env.REACT_APP_SERVICETRADE_APP)
            setReady(true)
        } else {
            window.location.reload();
        }
        
    };

    const handleClose = () => {
        setOpen(false);
        // on close, destroy the old token that has disappeared
        let query = window.location.search.split('=');
        if (query.length < 2) {
            window.location.href = "/"
        } else {
            axiosInstance.get('/cancel?data=' + query[1]);//
        }
    };

    const handleOpen = () => {
        setOpen(true)
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Welcome to Timecard</h2>
            <p id="simple-modal-description">
                <Alert severity="info">{ready ? `After signing the Eula, click the button below to continue` : `Please don't forget to sign the Eula agreement before proceeding`}</Alert>
            </p>
            <Button
                onClick={handleClick}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                type="button">{ready ? "Try Again" : "Eula Agreement"}
            </Button>
        </div>
    );

    return (
        <div>

            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                type="button"
                onClick={handleOpen}>
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}
