import React from 'react';
import DragIcon from '@material-ui/icons/DragHandle';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';

import { connect } from 'react-redux';

const TemplateColumnListItem = (props) => {
    let [alias, setAlias] = React.useState(props.item.alias);
    let [edit, setEditMode] = React.useState(false);

    const handleEditMode = () => {
        setEditMode(!edit);
    };

    const handleReadMode = () => {
        let updated = props.templateModel.data.map((column) => {
            if (column.header === props.item.header) {
                column.alias = alias;
            }
            return column;
        });
        props.dispatch({
            type: 'set_template_model',
            payload: {
                data: updated
            }
        });
        setEditMode(!edit);
    }

    const handleAliasChange = (e, item) => {
        setAlias(e.target.value);
    };

  return (
        <ListItem {...props.provided.draggableProps} ref={props.provided.innerRef} {...props.provided.dragHandleProps}>
            <ListItemIcon>
                {props.showDragHandle && (<DragIcon />)}
            </ListItemIcon>
            {edit && (<TextField
                id="outlined-required"
                label={props.item.header}
                margin="dense"
                fullWidth
                defaultValue={props.item.alias}
                variant="outlined"
                onChange={(e, a) => { return handleAliasChange(e)}}
            />)}
            {!edit && (<ListItemText
                    primary={props.item.alias}
                    secondary={props.item.header}
                  />)}
            {edit && (<ListItemSecondaryAction onClick={handleReadMode}>
                <IconButton edge="end" aria-label="Save">
                    <SaveIcon />
                </IconButton>
            </ListItemSecondaryAction>)}
            {!edit && !props.showDragHandle && (<ListItemSecondaryAction onClick={handleEditMode}>
                <IconButton edge="end" aria-label="Edit">
                    <EditIcon />
                </IconButton>
            </ListItemSecondaryAction>)}
        </ListItem>
  );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(TemplateColumnListItem);