import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import axiosInstance from '../../utilities/axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from "react-router-dom";
import GrandTotals from './grandTotals';
import AdditionalRowContainer from './additionalRowContainer';
import { round } from '../../utilities';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
    },
    additionalRows: {
        maxWidth: 30
    },
    comments: {
        width: '100%'
    },
    accordion: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    submit: {
        margin: 10
    },
    dateHeader: {
        textAlign: 'center'
    }
  }));

const calculate = (rows) => {
    let total = rows ? rows.map((row) => {
        return row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat']
    }) : rows;

    if (Array.isArray(total) && total.length === 0) {
        return 0;
    } else {
        return total.reduce((total, value) => total + value)
    }
};

const EditTimeCard = (props) => {
    const classes = useStyles();
    // let model = props.edit.model;
    let week = props.edit.model[0].week;
    // let grandTotals = props.settings.grandTotals;
    let [additionalRows, setAdditionalRows] = useState(props.edit.additionalRows || [])
    let [userComments, setUserComments] = useState(props.edit.comments);
    let [model, setModel] = useState(props.edit.model);
    let [mustBeCertified, setCertified] = useState(true);
    let uniqueJobItems = [];
    let navi = useHistory();
    const GetTimecardDataEndPoints = ['jobItems', 'clockEvents', 'expenses']

    
    props.dispatch({
        type: 'set_application_page',
        payload: 'Submit Changes to Timecard'
    });
    
    if (props.edit && props.edit.additionalRows && additionalRows.length > 0) {
        props.dispatch({
            type: 'set_grand_total',
            payload: calculate(additionalRows)
        });
    }

    let rows = model;
    useEffect(async () => {
        if (props.technician && props.technician.id && props.login && props.login.settings && props.login.settings.startDay && props && props.edit.startEpoch) {
            let timeCardParams = {
                params: {
                    companyId: props.login.user.company.id,
                    combine: false,
                    userId: props.technician.id,
                    startEpochTime: props.edit.startEpoch,
                    endEpochTime: props.edit.endEpoch,
                    startDay: props.login.settings.startDay,
                    range: props.edit.range,
                    type: props.edit.timeCardType,
                    userObj: {
                        timezone: props.edit.userObj.timezone
                    },
                    techObj: {
                        timezone: props.technician.timezone
                    }
                },
                ...requestHeaderConfig(props.login.authToken)
            }
            const fetchTimeCardData = (params, url) => {
                // the url is stored in mongo. Example: "/api/jobItems". axiosInstance already has "/api" prefixed. Remove it here
                return axiosInstance.get(url.replace("/api", ""), params).then((response) => {
                    var entries = response.data;
                    var returnObj = {
                        model: entries && entries.timeCardItems && entries.timeCardItems.length > 0 ? entries.timeCardItems : [],
                        week: entries && entries.timeCardItems && entries.timeCardItems.length > 0 ? entries.timeCardItems[0].week : false,
                        summary: entries.summary,
                        isLocked: entries.isLocked,
                        submittedOn : entries.submittedOn
                    };

                    if (entries.clockExports) {
                        returnObj.clockExports = entries.clockExports;
        
                        props.dispatch({
                            type: 'set_clockExports',
                            payload: returnObj.clockExports
                        });
                    }
        
                    if (entries.additionalRows) {
                        returnObj.additionalRows = entries.additionalRows;
                    }
                    return returnObj;
                })

            }
            await fetchTimeCardData(timeCardParams, `/api/${GetTimecardDataEndPoints[props.edit.type]}`).then((items) => {
                setModel(items.model);
            });
        }
    }, [])
    let total = 0;
    // combines total from all records entered by technician in servicetrade
    for (let x = 0; x < rows.length; x++) {
        total = total + rows[x].weekTotal;
    }
    props.dispatch({
        type: 'set_work_total',
        payload: total
    });

    const getExternalId = (technician) => {
        let extId = '';
        if (technician.externalIds) {
            extId = technician.externalIds;
            let values = Object.values(extId);
            if (values.length && values.filter(id => id !== '').length) {
                extId = values.filter(id => id !== '')[0];
            } 
        }
        return extId;
    }
    
    const getWageType = (login, techId) => {
        return login && login.userList && login.userList.length && login.userList.filter((user) => user.id === techId)[0] ? login.userList.filter((user) => user.id === techId)[0].wageType || '' : ''
    }

    const extractExternalIdValueById = (externalIds, id) => {
        let value = '';
        if (externalIds && externalIds.length && typeof externalIds !== 'string') {
            externalIds.forEach((idObj) => {
                if (idObj.id === id) {
                    value = idObj.value;
                }
            });
        }
        return value;
    }
    const mapExternalIdToModel = (model) => {
        model.map((item) => {
            if (item.serviceLine) {
                const serviceLine = props.serviceLines.find((service) => { return service.id === item.serviceLine});
                item.serviceLineName = serviceLine.name;
                item.serviceLineAbbr = serviceLine.abbr;
                item.serviceLineTrade = serviceLine.trade;
            }
            item.externalId = extractExternalIdValueById(item.externalId, props.appSettings.jobExternalSystem);
        })
        return model;
    }

    const canApproveOrRequestChanges = () => {
        const { canSubmit, user } = props.login
        const completeUserData = props.listOfAccountUsers.find((fullUserData)=> { return user.id === fullUserData.id})
        const activities = completeUserData?.activities;
        let isAdmin = false;
        if (activities) {
            isAdmin = activities.includes('admin.account') || activities.includes('admin.user');
        }
        return canSubmit || isAdmin
    }

    const handleSubmit = () => {
        let userObj = {...{}, ...props.login.user};
        delete userObj.activities;
        let payload = {
            companyId: props.login.user.company.id, //*
            userId: props.edit.userId, //* 
            model: mapExternalIdToModel(model),
            startEpoch: props.edit.startEpoch,
            endEpoch: props.edit.endEpoch,
            startMoment: props.edit.startMoment,
            endMoment: props.edit.endMoment,
            range: props.edit.range, //*
            type: props.edit.type, // * Refactor, if an admin changes enabled types we need a hard value, not an int
            typeName: props.edit.typeName,
            status: "Submitted",
            currentUserId: userObj.id,
            currentUserTimezone: userObj.timezone,
            techName: props.edit.techName,
            techEmail: props.edit.techEmail,
            comments: userComments,
            weekDates: props.dateRanges.weekDates,
            notificationEmails: props.appSettings.emails,
            officeId: props.login.user.location.id,
            actionBy: props.login.user.name,
            techExtId: getExternalId(props.technician),
            wageType: getWageType(props.login, props.technician.id),
            technicianTimezone: props.technician.timezone
        };
        if (props.clockExports && props.clockExports.length) {
            payload.clockExports = props.clockExports;
        }

        if (props.edit.techOffice) {
            payload.techOffice = props.edit.techOffice;
        }
        if (props.edit.techLocation) {
            payload.techLocation = props.edit.techLocation;
        }

        if (props && props.edit && props.edit.avatar) {
            payload.avatar = props.edit.avatar;
        }
        
        props.dispatch({
            type: 'set_backdrop',
            payload: true
        });
        axiosInstance.post('/save', payload, requestHeaderConfig(props.login.authToken)).then(response => {
            props.dispatch({
                type: 'set_backdrop',
                payload: false
            });
            props.dispatch({
                type: 'set_application_page',
                payload: 'Home'
            });

            canApproveOrRequestChanges() ? navi.push('/') : navi.push('/myTimecards');
            
          }).catch((e) => {
            props.dispatch({
                type: 'set_backdrop',
                payload: false
            });
              console.warn(e, 'Could not save timecard');
          });
    };
    const handleConfirm = () => {
        setCertified(!mustBeCertified);
    };

    const formatDate = (date) => {
        return moment(date).format('MM/DD/YY').toString();
    };

    const unique = (id) => {
        let isUnique = uniqueJobItems.indexOf(id) < 0;
        if (isUnique) {
            uniqueJobItems.push(id);
        }
        return isUnique;
    };

    if (!model || !week || !additionalRows) { 
        return (
            <>
                <Container maxWidth="md" className={classes.container}>
                    <Grid item xs>
                        <Paper className={classes.paper}>
                            <h4 align="left">Loading</h4>
                            <CircularProgress />
                        </Paper>
                    </Grid>
                </Container>
            </>
        )
    }
    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="Timecard">
                    <TableHead>
                        <TableRow key={uuid()}>
                            <TableCell>Job</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Item Code</TableCell>
                            {props.appSettings.weekDayOrder && props.appSettings.weekDayOrder.length > 0 ? props.appSettings.weekDayOrder.map((day, index) => (
                                <TableCell className={classes.dateHeader} key={uuid()}>{day.day} <br/><sub>{formatDate(props.edit.weekDates[index])}</sub></TableCell>
                            )) : null}
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props && props.edit && props.edit.model ? props.edit.model.map((row) => (
                            <TableRow key={uuid()}>
                                <TableCell>
                                    <Typography className={classes.root}>
                                        {unique(row.jobNumber) && row.jobNumber !== '' && (
                                            <Link href={row.link} target="_blank">
                                                [ {row.jobNumber} ]
                                            </Link> 
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {row.location}
                                </TableCell>
                                <TableCell>
                                    {row.activity && (
                                        <>
                                            {row.activity}
                                        </> 
                                    )}
                                    {!row.activity && (
                                        <>
                                            {row.itemCode}
                                        </>
                                    )}
                                </TableCell>
                                {row.week.map((day) => (
                                    <TableCell align={'center'} key={uuid()}>
                                        {round(day.value)}
                                    </TableCell>
                                ))}
                                <TableCell align={'center'}>
                                    {round(row.weekTotal)}
                                </TableCell>
                            </TableRow>
                        )) : null }
                        <AdditionalRowContainer isEditTimeCard={true} />
                        <GrandTotals isEditTimeCard={true} week={week} />
                    </TableBody>
                </Table>
            </TableContainer>
            {props.appSettings.comments.enabled && (
                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className={classes.heading}>Add Comments</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField className={classes.comments} onChange={(e) => {setUserComments(e.target.value)}} value={userComments} id="outlined-multiline-static" label="Comments" multiline rows={4} variant="outlined"/>
                    </AccordionDetails>
                </Accordion>
            )}
            
            <Box>
                <Button className={classes.submit} variant="contained" color="primary" disabled={mustBeCertified} onClick={handleSubmit}>Submit</Button>
                <FormControlLabel
                    control={<Checkbox name="confirm" color="primary" onClick={handleConfirm}
                    style={{ pointerEvents: "auto" }}/>}
                    label="I have reviewed the above, and certify it to be true."
                    style={{ pointerEvents: "none" }}
                />
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(EditTimeCard);