import React, { useEffect } from "react";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import axiosInstance from "../utilities/axios";
import LoadingScreen from "./loading";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://servicetrade.com/">
        ServiceTrade
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#004680"
  },

}));


const Login = () => {
  const loginWithPKCEAuth0 = () => {
    const authoizeWithAuth0 = ({data}) => {
      const codeChallenge = data.codeChallenge;
      const state = data.state;
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;
      const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
      const callbackUrl = process.env.REACT_APP_AUTH0_CALLBACK_URL;
      const apiAudience = 'timecard/api';
      const scope = 'offline_access openid profile email';
      const href = `https://${domain}/authorize?response_type=code&code_challenge=${codeChallenge}&code_challenge_method=S256&client_id=${clientId}&redirect_uri=${callbackUrl}&scope=${scope}&audience=${apiAudience}&state=${state}`;

      window.location.href = href;
      return href;      
    }
    return axiosInstance.get('/initialize').then((response) => {
      authoizeWithAuth0(response)
    })
  }
  const classes = useStyles();

  useEffect(()=> {
    loginWithPKCEAuth0();
  }, [])

  return (<LoadingScreen />);
}

const mapStateToProps = state => ({
  account: state
});

export default connect(mapStateToProps)(Login);
