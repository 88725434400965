import moment from "moment"
import axiosInstance from "../utilities/axios";
import { analytics, convertDecimalToHoursToHourly } from '../utilities';
import { SUBMISSION, REQUEST_BUTTON } from '../utilities/constants/analytics'
import { dateFormatter } from "./utilities";
import { requestHeaderConfig } from "../utilities";

const getWeekStartDayUTC = (calendarStartDay, startMoment) => {
    let days = [];

    // collect the last 7 days
    for (let x = 0; x < 7; x++) {
        days.push(moment().subtract(x, 'days'));
    }

    for (let y = 0; y < 7; y++) {
        // when the most recent calendar start day is found, this is our week start
        if (days[y].toString().split(' ')[0] === calendarStartDay) {
            startMoment = days[y];
        }
    }
    return startMoment.format('MM/DD/YYYY');
};

export const handleItemCodeFormat = (view, laborDefault) => {
    let csvData = []
    let data = {}
    let total = 0
    let dataSelected = laborDefault.data.map((item) => item.header)
    let totalSelected = laborDefault.data.find(x => x.header === 'total');


    if (laborDefault.data !== undefined) {

        for (let item of laborDefault.data) {

            switch (item.header) {
                case "technician_name":
                    data[item.header] = view.techName
                    break;
                case "technician_id":
                    data[item.header] = view.userId
                    break;
                case "technician_email":
                    data[item.header] = view.techEmail
                    break;
                case "tech_office":
                    data[item.header] = view.techOffice
                    break;
                case "actionBy":
                    data[item.header] = view.actionBy
                    break;
                default:
                    break;
            }
        }
    }

    for (let item of view.model) {

        if (dataSelected.includes(item.itemCode)) {
            if (data[item.itemCode] === undefined) {
                data[item.itemCode] = item.weekTotal
                total += item.weekTotal
            } else {
                data[item.itemCode] += item.weekTotal
                total += item.weekTotal
            }
        }
        if (data[item.activity]) {
            data[item.activity] = data[item.activity].toFixed(2)
            data[item.activity] = Number(data[item.activity])
        }

    }

    if (view.additionalRows && view.additionalRows.length > 0) {
        for (let row of view.additionalRows) {
            if (dataSelected.includes(row.label)) {
                data[row.label] = row.Sun + row.Mon + row.Tue + row.Wed + row.Thu + row.Fri + row.Sat
                total += row.Sun + row.Mon + row.Tue + row.Wed + row.Thu + row.Fri + row.Sat
            }

        }
    }

    if (totalSelected) {
        data[totalSelected.header] = total
    } else {
        console.log("total not selected")
    }

    csvData.push(data)
    return csvData
}

export const handleItemCodeHeaders = (view, laborDefault) => {
    let header = laborDefault.data.map((item) => {
        return {
            label: item.alias,
            key: item.header
        }
    })
    return header
}

//  ******* CLOCK EVENT FUNCTIONS *************** //
export const handleClockHeaders = (view, clockDefault) => {

    let header = clockDefault.data.map((item) => {
        return {
            label: item.alias,
            key: item.header
        }
    })

    return header
}

export const groupClockEventData = (view, clockDefault, decimalFormatEnabled) => {
    let dataSelected = clockDefault.data.map((item) => item.header)
    let totalSelected = clockDefault.data.find(x => x.header === 'total');
    let csvData = []
    let data = {}
    let total = 0

    for (let item of clockDefault.data) {
        switch (item.header) {
            case "technician_name":
                data[item.header] = view.techName
                break;
            case "technician_id":
                data[item.header] = view.userId
                break;
            case "technician_email":
                data[item.header] = view.techEmail
                break;
            case 'companyId':
                data[item.header] = view.companyId
                break;
            case "tech_office":
                data[item.header] = view.techOffice
                break;
            case "actionBy":
                data[item.header] = view.actionBy
                break;

            default:
                break;
        }
    }

    for (let item of view.model) {
        if (dataSelected.includes(item.activity)) {

            if (data[item.activity] === undefined) {
                data[item.activity] = item.weekTotal
                total += item.weekTotal

            } else {
                data[item.activity] += item.weekTotal
                total += item.weekTotal
            }
        }
        if (data[item.activity]) {
            data[item.activity] = data[item.activity].toFixed(2)
            data[item.activity] = Number(data[item.activity])
        }
    }

    if (view.additionalRows) {
        for (let row of view.additionalRows) {
            if (dataSelected.includes(row.label)) {
                data[row.label] = row.Sun + row.Mon + row.Tue + row.Wed + row.Thu + row.Fri + row.Sat
                total += row.Sun + row.Mon + row.Tue + row.Wed + row.Thu + row.Fri + row.Sat
            }
        }
    }

    if (totalSelected) {
        data[totalSelected.header] = total
    } else {
        console.log("total not selected")
    }
    if (decimalFormatEnabled) {
        for (let item in data) {
            let value = data[item]
            if (typeof (value) === 'number' && item !== "technician_id") {
                data[item] = convertDecimalToHoursToHourly(value);
            }
        }
    }

    csvData.push(data)
    return csvData
}

export const addTimecardComments = async (props, comment) => {
    let admin = props.login.canManage || props.login.isAdmin || false
    if (comment.length > 0) {
        const PayloadObj = {
            timecardId: props.view._id,
            adminComment: admin,
            companyId: props.technician.company ? props.technician.company.id : props.technician.companies.id,
            userId: props.technician.id,
            name: props.login.user.name,
            msg: comment,
            timestamp: moment().format('lll')
        }
        try {
            const response = await axiosInstance.post('/comments', PayloadObj, requestHeaderConfig(props.login.authToken)).then(() => {
                props.dispatch({
                    type: 'set_alert',
                    payload: {
                        active: true,
                        message: 'Comment Saved '
                    }
                });
                return true;
            }).catch((e) => {
                props.dispatch({
                    type: 'set_alert',
                    payload: {
                        active: true,
                        message: 'Could not submit comment',
                        isError: true
                    }
                });
                return false;
            });
            return response;

        } catch (error) {
            console.error(error)
        }
    }
}

export const requestTimecardChange = (props, comment) => {
    let admin = props.login.canManage || props.login.isAdmin || false
    let companyId = props.technician.company.id || props.technician.company;
    if (comment.length > 0) {
        const PayloadObj = {
            timecardId: props.view._id,
            adminComment: admin,
            companyId: companyId,
            userId: props.view && props.view.userObject && props.view.userObject.id ? props.view.userObject.id : props.technician.id,
            name: props.login.user.name,
            msg: comment,
            timestamp: moment().format('lll')
        }
        try {
            const { data } = axiosInstance.post('/comments', PayloadObj, requestHeaderConfig(props.login.authToken))

        } catch (error) {
            console.error(error)
        }
    }
    const payload = {
        companyId: companyId,
        userId: props.view && props.view.userObject && props.view.userObject.id ? props.view.userObject.id : props.technician.id,
        emails: [props.view && props.view.techEmail ? props.view.techEmail : props.technician.email],
        timecards: [{
            id: props.view.id,
            email: props.view && props.view.techEmail ? props.view.techEmail : props.technician.email
        }]
    };
    try {
        axiosInstance.post('/request', payload, requestHeaderConfig(props.login.authToken))
        // analytics({ props: props, type: SUBMISSION, action: REQUEST_BUTTON, metadata: false })
    } catch (error) {
        console.error(error)
    }

    props.dispatch({
        type: 'set_alert',
        payload: {
            active: true,
            message: 'Request Succesful! '
        }
    });

}

export const sendEmailWithAttachedCSV = (props, inputEmail, message, headers, exportData) => {

    let accountId = !props.technician.account ? props.technician.id : props.technician.account.id
    let companyId = typeof (props.technician.company) === 'number' ? props.technician.company : props.technician.company.id

    const payload = {
        companyId: companyId,
        name: props.login.user.name,
        userId: props.technician.id,
        emails: [props.technician.email],
        inputEmail: inputEmail,
        headers: headers || false,
        exportData: exportData,
        message: message,
        filenName: props.view.techName + "_timeCard_" + props.view.accountId
    };

    try {
        axiosInstance.post('/email', payload, requestHeaderConfig(props.login.authToken))

    } catch (error) {
        console.error(error)
        return false;
    }

    return true;

}

export const handleCSVFormat = (props, clockItemHeader, view, startMoment, decimalFormatEnabled, clockItemExport, laborItemHeader, laborItemExport) => {
    let csvData = [];
    let totalRow = [];
    var grandTotals = {
        Sun: 0,
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
    }

    const getDefaultWeekDay = (startDay) => {
        var weekday = [{
            day: "Sun",
            value: 0
        }, {
            day: "Mon",
            value: 0
        }, {
            day: "Tue",
            value: 0
        }, {
            day: "Wed",
            value: 0
        }, {
            day: "Thu",
            value: 0
        }, {
            day: "Fri",
            value: 0
        }, {
            day: "Sat",
            value: 0
        }];
        // set the order of the week to match the set start day
        weekday.push.apply(weekday, weekday.splice(0, weekday.findIndex((week) => week.day === startDay)));
        return weekday;
    }
    let weekDay = getDefaultWeekDay(props.appSettings.startDay);
    switch (props.view.typeName) {
        case 'Clock Events':
            let clockHeader = [...clockItemHeader, view.weekDates[0], view.weekDates[1], view.weekDates[2], view.weekDates[3], view.weekDates[4], view.weekDates[5], view.weekDates[6], 'total']
            csvData.push(clockHeader)
            view.model.forEach((item) => {
                let csvRow = clockItemExport.map((val) => {
                    switch (val.header) {
                        case "activity":
                            val.value = item.activity
                            break;
                        case "location":
                            val.value = item.location
                            break;
                        case "companyName":
                            val.value = item.companyName
                            break;
                        case "technician_name":
                            val.value = view.techName
                            break;
                        case "jobType":
                        case "job_type":
                            val.value = item.jobType || item.job_type
                            break;
                        case "companyId":
                            val.value = view.companyId
                            break
                        case "jobNumber":
                            val.value = item.jobNumber
                            break;
                        case "technician_id":
                            val.value = item.userId
                            break;
                        case "Date":
                            dateFormatter((item.usedOn || item.eventTime) * 1000) // turn item.usedOn into millseconds
                            break;
                        case "!TIMEACT":
                            val.value = "TIMEACT"
                            break;
                        case "Item (quickbooks desktop)":
                            val.value = "Labor"
                            break;
                        case "PITEM (quickbooks desktop)":
                            val.value = ""
                            break;
                        case "XFERTOPAYROLL (quickbooks desktop)":
                            val.value = "N"
                            break;
                        case "total":
                            val.value = item.weekTotal
                            break;
                        case "BillingStatus (quickbooks desktop)":
                            val.value = 0
                            break;
                        case "BLANK":
                            val.value = ""
                            break;
                        case "BLANK2":
                            val.value = ""
                            break;
                        case "EMP":
                            val.value = view.techName.split(' ').reverse().join(',')
                            break;
                        case "QBD_JOB":
                            val.value = item.companyName + ":" + item.location
                            break;
                        case "item_code":
                            val.value = item.itemCode
                            break;
                        case "tech_office":
                            val.value = view.techOffice
                            break;
                        case "quantity":
                            val.value = item.quantity
                            break;
                        case "usedOn":
                            val.value = moment(item.usedOn * 1000).format('MM/DD/YYYY');
                            break;
                        case "externalId":
                            val.value = item.externalId || ''
                            break;
                        case "begin_date":
                            val.value = getWeekStartDayUTC(props.appSettings.startDay, moment(item.usedOn * 1000))
                            break;
                        case "locationCompanyId":
                            val.value = item.locationCompanyId || ''
                            break;
                        case "techExtId":
                            val.value = view.techExtId || '';
                            break;
                        case "locationId":
                            val.value = item.locationOfficeId || '';
                            break;
                        case "Sunday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Sun';
                            }).value;
                            grandTotals['Sun'] = grandTotals['Sun'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Monday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Mon';
                            }).value;
                            grandTotals['Mon'] = grandTotals['Mon'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Tuesday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Tue';
                            }).value;
                            grandTotals['Tue'] = grandTotals['Tue'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Wednesday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Wed';
                            }).value;
                            grandTotals['Wed'] = grandTotals['Wed'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Thursday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Thu';
                            }).value;
                            grandTotals['Thu'] = grandTotals['Thu'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Friday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Fri';
                            }).value;
                            grandTotals['Fri'] = grandTotals['Fri'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Saturday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Sat';
                            }).value;
                            grandTotals['Sat'] = grandTotals['Sat'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        default:
                    }
                    return val.value
                })

                if (decimalFormatEnabled) {
                    csvRow.push(convertDecimalToHoursToHourly(item.weekTotal))
                } else {
                    csvRow.push(item.weekTotal)
                }
                csvData.push(csvRow)
            });
            // For additional rows, the format / order of each day must match the job. Add a weektotal for the timecard
            if (props.view.additionalRows) {
                props.view.additionalRows.forEach((item) => {
                    let csvRow = [];
                    if (clockItemHeader.length > 3) {
                        for (let i = 3; i < clockItemHeader.length; i++) {
                            csvRow.push('');
                        }
                    }
                    csvRow.push("");
                    csvRow.push("");

                    csvRow.push(item.label);

                    for (let y = 0; y < weekDay.length; y++) {
                        csvRow.push(item[weekDay[y].day]);
                    }

                    csvRow.push(item.Sun + item.Mon + item.Tue + item.Wed + item.Thu + item.Fri + item.Sat)
                    csvData.push(csvRow);
                    grandTotals['Sun'] = grandTotals['Sun'] + item.Sun;
                    grandTotals['Mon'] = grandTotals['Mon'] + item.Mon;
                    grandTotals['Tue'] = grandTotals['Tue'] + item.Tue;
                    grandTotals['Wed'] = grandTotals['Wed'] + item.Wed;
                    grandTotals['Thu'] = grandTotals['Thu'] + item.Thu;
                    grandTotals['Fri'] = grandTotals['Fri'] + item.Fri;
                    grandTotals['Sat'] = grandTotals['Sat'] + item.Sat;
                });
            }

            if (clockItemHeader) {
                for (let i = 0; i < clockItemHeader.length - 1; i++) {
                    totalRow.push('');
                }
            }
            totalRow.push('Grand Total');
            for (let y = 0; y < weekDay.length; y++) {
                if (decimalFormatEnabled) {
                    totalRow.push(convertDecimalToHoursToHourly(grandTotals[weekDay[y].day].toFixed(2)));
                } else {
                    totalRow.push(grandTotals[weekDay[y].day].toFixed(2));
                }

            }
            if (decimalFormatEnabled) {
                totalRow.push(convertDecimalToHoursToHourly(grandTotals['Sun'] + grandTotals['Mon'] + grandTotals['Tue'] + grandTotals['Wed'] + grandTotals['Thu'] + grandTotals['Fri'] + grandTotals['Sat']));
            } else {
                totalRow.push((grandTotals['Sun'] + grandTotals['Mon'] + grandTotals['Tue'] + grandTotals['Wed'] + grandTotals['Thu'] + grandTotals['Fri'] + grandTotals['Sat']).toFixed(2));

            }
            csvData.push(totalRow);
            break;
        case 'Labor Items':
            let laborHeader = [...laborItemHeader, view.weekDates[0], view.weekDates[1], view.weekDates[2], view.weekDates[3], view.weekDates[4], view.weekDates[5], view.weekDates[6], 'total']
            if (laborHeader.filter(item => item.header === 'TIMEACT').length >= 1 ) laborHeader = laborItemHeader;
            if (laborItemExport.filter(item => item.header === 'TIMEACT').length > 0) {
                laborHeader = laborItemHeader
                laborItemExport = laborItemExport.reverse().slice(7).reverse();
             }
            csvData.push(laborHeader)
            view.model.forEach((item) => {
                let csvRow = laborItemExport.map((val) => {
                    switch (val.header) {
                    case "location":
                            val.linkToDB = item.location
                            break;
                        case "companyName":
                            val.linkToDB = item.companyName
                            break;
                        case "technician_name":
                            val.linkToDB = view.techName
                            break;
                        case "jobType":
                        case "job_type":
                            val.linkToDB = item.jobType || item.job_type
                            break;
                        case "companyId":
                            val.linkToDB = view.companyId
                            break
                        case "jobNumber":
                            val.linkToDB = item.jobNumber
                            break;
                        case "technician_id":
                            val.linkToDB = item.userId
                            break;
                        case "Date":
                            dateFormatter(item.usedOn * 1000) // turn item.usedOn into millseconds
                            break;
                        case "TIMEACT":
                            val.linkToDB = "TIMEACT"
                            break;
                        case "Item (quickbooks desktop)":
                            val.linkToDB = "Labor"
                            break;
                        case "PITEM (quickbooks desktop)":
                            val.linkToDB = ""
                            break;
                        case "XFERTOPAYROLL (quickbooks desktop)":
                            val.linkToDB = "N"
                            break;
                        case "total":
                            val.linkToDB = item.weekTotal
                            break;
                        case "BillingStatus (quickbooks desktop)":
                            val.linkToDB = 0
                            break;
                        case "BLANK":
                            val.linkToDB = ""
                            break;
                        case "BLANK2":
                            val.linkToDB = ""
                            break;
                        case "EMP":
                            val.linkToDB = view.techName.split(' ').reverse().join(',')
                            break;
                        case "QBD_JOB":
                            val.linkToDB = item.companyName + ":" + item.location
                            break;
                        case "item_code":
                            val.linkToDB = item.itemCode
                            break;
                        case "tech_office":
                            val.linkToDB = view.techOffice
                            break;
                        case "quantity":
                            val.linkToDB = item.quantity
                            break;
                        case "usedOn":
                            val.linkToDB = moment(item.usedOn * 1000).format('MM/DD/YYYY');
                            break;
                        case "externalId":
                            val.linkToDB = item.externalId || ''
                            break;
                        case "begin_date":
                            val.linkToDB = getWeekStartDayUTC(props.appSettings.startDay, moment(item.usedOn * 1000))
                            break;
                        case "locationCompanyId":
                            val.linkToDB = item.locationCompanyId;
                            break;
                        case "techExtId":
                            val.linkToDB = view.techExtId || '';
                            break;
                        case "locationId":
                            val.linkToDB = item.locationOfficeId || '';
                            break;
                        case "Sunday":
                            val.linkToDB = item.week.find((wObj) => {
                                return wObj.day === 'Sun';
                            }).value;
                            grandTotals['Sun'] = grandTotals['Sun'] + val.linkToDB
                            break;
                        case "Monday":
                            val.linkToDB = item.week.find((wObj) => {
                                return wObj.day === 'Mon';
                            }).value;
                            grandTotals['Mon'] = grandTotals['Mon'] + val.linkToDB
                            break;
                        case "Tuesday":
                            val.linkToDB = item.week.find((wObj) => {
                                return wObj.day === 'Tue';
                            }).value;
                            grandTotals['Tue'] = grandTotals['Tue'] + val.linkToDB
                            break;
                        case "Wednesday":
                            val.linkToDB = item.week.find((wObj) => {
                                return wObj.day === 'Wed';
                            }).value;
                            grandTotals['Wed'] = grandTotals['Wed'] + val.linkToDB
                            break;
                        case "Thursday":
                            val.linkToDB = item.week.find((wObj) => {
                                return wObj.day === 'Thu';
                            }).value;
                            grandTotals['Thu'] = grandTotals['Thu'] + val.linkToDB
                            break;
                        case "Friday":
                            val.linkToDB = item.week.find((wObj) => {
                                return wObj.day === 'Fri';
                            }).value;
                            grandTotals['Fri'] = grandTotals['Fri'] + val.linkToDB
                            break;
                        case "Saturday":
                            val.linkToDB = item.week.find((wObj) => {
                                return wObj.day === 'Sat';
                            }).value;
                            grandTotals['Sat'] = grandTotals['Sat'] + val.linkToDB
                            break;
                        default:
                    }
                    return val.linkToDB
                })
                if (laborItemExport.filter(item => item.header === 'TIMEACT').length < 1)  csvRow.push(item.weekTotal);
                csvData.push(csvRow);
            });
            // For additional rows, the format / order of each day must match the job.
            if (props.view.additionalRows && laborItemExport.filter(item => item.header === 'TIMEACT').length < 1 ) {
                props.view.additionalRows.forEach((item) => {
                    let csvRow = [];
                    if (laborItemHeader.length >= 3) {
                        for (let i = 2; i < laborItemHeader.length; i++) {
                            csvRow.push('');
                        }
                    }
                    csvRow.push('Totals');
                    csvRow.push(item.label);
                    for (let y = 0; y < weekDay.length; y++) {
                        csvRow.push(item[weekDay[y].day]);
                    }
                    csvRow.push(item.Sun + item.Mon + item.Tue + item.Wed + item.Thu + item.Fri + item.Sat)
                    csvData.push(csvRow);

                    grandTotals['Sun'] = grandTotals['Sun'] + item.Sun;
                    grandTotals['Mon'] = grandTotals['Mon'] + item.Mon;
                    grandTotals['Tue'] = grandTotals['Tue'] + item.Tue;
                    grandTotals['Wed'] = grandTotals['Wed'] + item.Wed;
                    grandTotals['Thu'] = grandTotals['Thu'] + item.Thu;
                    grandTotals['Fri'] = grandTotals['Fri'] + item.Fri;
                    grandTotals['Sat'] = grandTotals['Sat'] + item.Sat;
                });
            }

            if (laborItemHeader) {
                for (let i = 0; i < laborItemHeader.length - 1; i++) {
                    totalRow.push('');
                }
            }
            if (laborItemExport.filter(item => item.header === 'TIMEACT').length < 1 ) {
                totalRow.push('Grand Total');
                for (let y = 0; y < weekDay.length; y++) {
                    totalRow.push(grandTotals[weekDay[y].day].toFixed(2));
                }
                totalRow.push(grandTotals['Sun'] + grandTotals['Mon'] + grandTotals['Tue'] + grandTotals['Wed'] + grandTotals['Thu'] + grandTotals['Fri'] + grandTotals['Sat']);
                csvData.push(totalRow);
            }


            break;
        case 'Expenses':
            csvData.push(['job_number', 'technician_id', 'technician', 'location', 'item_code', props.view.weekDates[0], props.view.weekDates[1], props.view.weekDates[2], props.view.weekDates[3], props.view.weekDates[4], props.view.weekDates[5], props.view.weekDates[6], "total"])
            view.model.forEach((item) => {
                let csvRow = [];
                csvRow.push(item.jobNumber);
                csvRow.push(props.view.userId)
                csvRow.push(props.view.techName);
                csvRow.push(item.location);
                csvRow.push(item.itemCode);
                item.week.forEach((day) => {
                    csvRow.push(day.value);
                    grandTotals[day.day] = grandTotals[day.day] + day.value;
                })
                csvRow.push(item.weekTotal)
                csvData.push(csvRow);
            });
            // For additional rows, the format / order of each day must match the job. Add a weektotal for the timecard
            if (props.view.additionalRows) {
                props.view.additionalRows.forEach((item) => {
                    let csvRow = [];
                    csvRow.push('');

                    csvRow.push('Totals');
                    csvRow.push(item.label);
                    for (let y = 0; y < weekDay.length; y++) {
                        csvRow.push(item[weekDay[y].day]);
                    }
                    csvRow.push(item.Sun + item.Mon + item.Tue + item.Wed + item.Thu + item.Fri + item.Sat)
                    csvData.push(csvRow);

                    grandTotals['Sun'] = grandTotals['Sun'] + item.Sun;
                    grandTotals['Mon'] = grandTotals['Mon'] + item.Mon;
                    grandTotals['Tue'] = grandTotals['Tue'] + item.Tue;
                    grandTotals['Wed'] = grandTotals['Wed'] + item.Wed;
                    grandTotals['Thu'] = grandTotals['Thu'] + item.Thu;
                    grandTotals['Fri'] = grandTotals['Fri'] + item.Fri;
                    grandTotals['Sat'] = grandTotals['Sat'] + item.Sat;
                });
            }

            totalRow.push('');
            totalRow.push('');
            totalRow.push('');
            totalRow.push('');
            totalRow.push('Grand Total');
            for (let y = 0; y < weekDay.length; y++) {
                totalRow.push(grandTotals[weekDay[y].day]);
            }
            totalRow.push(grandTotals['Sun'] + grandTotals['Mon'] + grandTotals['Tue'] + grandTotals['Wed'] + grandTotals['Thu'] + grandTotals['Fri'] + grandTotals['Sat']);
            csvData.push(totalRow);
            break;
        default:
    }
    return csvData;
}

export const handleQBDFormat = (props, clockItemHeader, view, startMoment, clockItemExport, laborItemHeader, laborItemExport) => {
    let csvData = [];
    let totalRow = [];

    switch (props.view.typeName) {
        case 'Clock Events':
            let clockHeader = clockItemHeader
            csvData.push(clockHeader);
            view.model.forEach((item) => {
                let csvRow = clockItemExport.map((val) => {
                    switch (val.header) {
                        case "activity":
                            val.value = item.activity
                            break;
                            case "location":
                                val.value = item.location
                                break;
                            case "companyName":
                                val.value = item.companyName
                                break;
                            case "technician_name":
                                val.value = view.techName
                                break;
                            case "jobType":
                            case "job_type":
                                val.value = item.jobType || item.job_type
                                break;
                            case "companyId":
                                val.value = view.companyId
                                break
                            case "jobNumber":
                                val.value = item.jobNumber
                                break;
                            case "technician_id":
                                val.value = item.userId
                                break;
                            case "Date":
                                val.value = dateFormatter((item.usedOn || item.eventTime) * 1000) // turn item.usedOn into millseconds
                                break;
                            case "!TIMEACT":
                                val.value = "TIMEACT"
                                break;
                            case "Item (quickbooks desktop)":
                                val.value = "Labor"
                                break;
                            case "PITEM (quickbooks desktop)":
                                val.value = ""
                                break;
                            case "XFERTOPAYROLL (quickbooks desktop)":
                                val.value = "N"
                                break;
                            case "total":
                                val.value = item.weekTotal
                                break;
                            case "BillingStatus (quickbooks desktop)":
                                val.value = 0
                                break;
                            case "BLANK":
                                val.value = ""
                                break;
                            case "BLANK2":
                                val.value = ""
                                break;
                            case "EMP":
                                val.value = view.techName.split(' ').reverse().join(',')
                                break;
                            case "QBD_JOB":
                                val.value = item.companyName + ":" + item.location
                                break;
                            case "item_code":
                                val.value = item.itemCode
                                break;
                            case "tech_office":
                                val.value = view.techOffice
                                break;
                            case "usedOn":
                                val.value = moment((item.usedOn || item.eventTime) * 1000).format('MM/DD/YYYY');
                                break;
                            case "quantity":
                                val.value = item.quantity
                                break;
                            case "begin_date":
                                val.value = getWeekStartDayUTC(props.appSettings.startDay, moment((item.usedOn || item.eventTime) * 1000))
                                break;
                            case "locationCompanyId":
                                val.value = item.locationCompanyId;
                                break;
                            case "techExtId":
                                val.value = view.techExtId || '';
                                break;
                            case "locationId":
                                val.value = item.locationOfficeId || '';
                                break;
                            case "externalId":
                                val.value = item.externalId || ''
                                break;
                        default:
                    }
                    return val.value
                })

                csvData.push(csvRow.filter(item => item !== undefined));
            });

            csvData.push(totalRow);
            break;
        case 'Labor Items':
            let laborHeader = laborItemHeader
            csvData.push(laborHeader)
            view.model.forEach((item) => {
                let csvRow = laborItemExport.map((val) => {
                    switch (val.header) {
                        case "location":
                            val.value = item.location
                            break;
                        case "companyName":
                            val.value = item.companyName
                            break;
                        case "technician_name":
                            val.value = view.techName
                            break;
                        case "jobType":
                        case "job_type":
                            val.value = item.jobType || item.job_type
                            break;
                        case "companyId":
                            val.value = view.companyId
                            break
                        case "jobNumber":
                            val.value = item.jobNumber
                            break;
                        case "technician_id":
                            val.value = item.userId
                            break;
                        case "Date":
                            val.value = dateFormatter(item.usedOn * 1000) // turn item.usedOn into millseconds
                            break;
                        case "!TIMEACT":
                            val.value = "TIMEACT"
                            break;
                        case "Item (quickbooks desktop)":
                            val.value = "Labor"
                            break;
                        case "PITEM (quickbooks desktop)":
                            val.value = ""
                            break;
                        case "XFERTOPAYROLL (quickbooks desktop)":
                            val.value = "N"
                            break;
                        case "total":
                            val.value = item.weekTotal
                            break;
                        case "BillingStatus (quickbooks desktop)":
                            val.value = 0
                            break;
                        case "BLANK":
                            val.value = ""
                            break;
                        case "BLANK2":
                            val.value = ""
                            break;
                        case "EMP":
                            val.value = view.techName.split(' ').reverse().join(',')
                            break;
                        case "QBD_JOB":
                            val.value = item.companyName + ":" + item.location
                            break;
                        case "item_code":
                            val.value = item.itemCode
                            break;
                        case "tech_office":
                            val.value = view.techOffice
                            break;
                        case "usedOn":
                            val.value = moment(item.usedOn * 1000).format('MM/DD/YYYY');
                            break;
                        case "quantity":
                            val.value = item.quantity
                            break;
                        case "externalId":
                            val.value = item.externalId || ''
                            break;
                        case "begin_date":
                            val.value = getWeekStartDayUTC(props.appSettings.startDay, moment(item.usedOn * 1000))
                            break;
                        case "locationCompanyId":
                            val.value = item.locationCompanyId || '';
                            break;
                        case "techExtId":
                            val.value = view.techExtId || '';
                            break;
                        case "locationId":
                            val.value = item.locationOfficeId || '';
                            break;
                        default:
                    }
                    return val.value
                })

                csvData.push(csvRow.filter(item => item !== undefined));
            });

            csvData.push(totalRow);

            break;

        default:
    }

    return csvData;
}
