import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import DayCounter from './dayCounter';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { round, convertDecimalToHoursToHourly } from '../../utilities';

    const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
          maxWidth: 360,
          backgroundColor: theme.palette.background.paper
        },
        paper: {
          padding: theme.spacing(2),
          color: theme.palette.text.secondary,
        },
        container: {
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(1),
        },
        additionalRows: {
            maxWidth: 30
        },
        comments: {
            width: '100%'
        },
        accordion: {
            width: '100%'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        submit: {
            margin: 10
        },
        summary: {
            borderBottom: 'none',
            // width: 92,
        }
      }));

  const AdditionalRow = (props) => {
    const classes = useStyles();
    const { isViewTimeCard, isEditTimeCard } = props;
    let [row, setRow] = useState(props.row);
    let renderDecimalFormatClock = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;

    // listen for changes to the row, update the state
    useEffect(() => {
        setRow(props.row);
    },[props.row]);

    return (
        <>
            <TableRow key={uuid()}>
                <TableCell className={props.readOnly ? classes.summary : ''} colSpan={props.readOnly && 0 }>
                    <Typography className={classes.root}>
                        {props.row.label}
                    </Typography>
                </TableCell>
                <TableCell className={props.readOnly ? classes.summary : ''} ></TableCell>
                {(props.readOnly && props.appSettings && props.appSettings.clockEventTypes && props.appSettings.clockEventTypes.onbreak && props.appSettings.clockEventTypes.onbreak === true) && (
                    <TableCell className={props.readOnly ? classes.summary : ''}></TableCell>
                )}
                {isEditTimeCard && (<><TableCell className={props.readOnly ? classes.summary : ''} ></TableCell></>)}
                {isViewTimeCard && (
                                <> 
                                    <TableCell className={props.readOnly ? classes.summary : ''}></TableCell>
                                    <TableCell className={props.readOnly ? classes.summary : ''}></TableCell>
                                </>
                            )}
                {!isViewTimeCard && props.readOnly && (
                                <> 
                                    <TableCell className={props.readOnly ? classes.summary : ''}></TableCell>
                                </>
                            )}
                {props.appSettings.weekDayOrder && props.appSettings.weekDayOrder.length > 0 ? props.appSettings.weekDayOrder.map((day) => (
                    <DayCounter readOnly={props.readOnly} row={row} setRow={setRow} day={day}/>
                )) : null }
                
                <TableCell align={'center'} className={props.readOnly ? classes.summary : ''}>
                    {renderDecimalFormatClock && props.timeCardType === 1 ? convertDecimalToHoursToHourly(round(row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat'])) 
                    : round(row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat'])}
                </TableCell>
            </TableRow>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(AdditionalRow);