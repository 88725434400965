import axiosInstance from '../utilities/axios';
import {
    TEMPLATE_REQUEST,
    TEMPLATE_REQUEST_SUCCESS,
    TEMPLATE_REQUEST_FAIL,
    TEMPLATE_REQUEST_UPDATE,
    TEMPLATE_UPDATE_SUCCESS,
    TEMPLATE_DELETE_REQUEST,
    TEMPLATE_DELETE_FAIL,
    TEMPLATE_DELETE_SUCCESS,
    LABOR_HEADER_UPDATE,
    WEEKDAY_ORDER_SETUP,
    UPDATE_UNIVERSAL_HEADER

} from '.././utilities/constants/templateConstants';
import { requestHeaderConfig } from '../utilities';


export const getTemplate = (params) => async (dispatch) => {

    try {
        dispatch({ type: TEMPLATE_REQUEST })

        let payload = {};

        if (params) {
            payload = {
                ...requestHeaderConfig(params.login.authToken)
            }
        }
        const { data } = await axiosInstance.get('/template', payload)
        dispatch({
            type: TEMPLATE_REQUEST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: TEMPLATE_REQUEST_FAIL,
            payload: error.message
        })

    }
}

export const updateTemplate = (template) => async (dispatch) => {
    try {
        dispatch({ type: TEMPLATE_REQUEST_UPDATE })

        const { data } = await axiosInstance.put('/template/', template)
        dispatch({
            type: TEMPLATE_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: TEMPLATE_REQUEST_FAIL,
            payload: error.message
        })
    }
}

export const updateTemplateData = async (updatedTemplate, props) => {
    try {
        let list = await axiosInstance.put('/template', updatedTemplate, requestHeaderConfig(props.login.authToken)).then((response) => {
            return response.data;
        });
        return list;
    } catch (error) {
        console.error(error)

    }
}

export const addTemplate = async (template, props) => {

    try {
        const data = await axiosInstance.post('/template/', {payload: template, accountId: props.login.user.account.id }, requestHeaderConfig(props.login.authToken)).then((resp) => {
            return resp.data;
        })
        return data;
    } catch (error) {
        console.log(error)
    }

}

export const deleteTemplate = (templateId) => async(dispatch, props) => {

    dispatch({type: TEMPLATE_DELETE_REQUEST})
    try {
        const params = {}
        const { data } = await axiosInstance.delete('/template/' + templateId, params, requestHeaderConfig(props.login.authToken))
        dispatch({type: TEMPLATE_DELETE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({type: TEMPLATE_DELETE_FAIL, payload: error.message})
    }

}

export const updateHeader = (header) => async(dispatch, getState) => {
    try {
        dispatch({type: LABOR_HEADER_UPDATE, payload: header})
    } catch (error) {
        dispatch({type: TEMPLATE_DELETE_FAIL, payload: error.message})
    }
}

export const formatWeekDayOrder = (weekdayArray) => async(dispatch) =>{

   const weekDayFormatted =  weekdayArray.map((item) => {
        let weekHeader = ""
        switch (item.day) {
            case "Sun":
                weekHeader = "Sunday"
                break;
            case "Mon":
                weekHeader = "Monday"
                break;
            case "Tue":
                weekHeader = "Tuesday"
                break;
            case "Wed":
                weekHeader = "Wednesday"
                break;
            case "Thu":
                weekHeader = "Thursday"
                break;
            case "Fri":
                weekHeader = "Friday"
                break;
            case "Sat":
                weekHeader = "Saturday"
                break;
            default:
                break;
        }
        return { ...item, header: weekHeader, index: '-' }
    })

    dispatch({type: WEEKDAY_ORDER_SETUP, payload: weekDayFormatted})
}

export const viewTimeCardHeader = (weekdayArray) => async(dispatch) => {
    dispatch({type: UPDATE_UNIVERSAL_HEADER, payload: weekdayArray})
}


export const updateDefaultTemplate = (props, templateType) => {
    axiosInstance.post('/settings', {
        companyId: props.login.user.company.id,
        key: templateType,
        value: props.templateModel
    }, requestHeaderConfig(props.login.authToken)).then(response => {
        let updatedSettingsObj = response.data;
        props.dispatch({
            type: 'set_settings',
            payload: updatedSettingsObj
        });
        props.dispatch({
            type: 'set_alert',
            payload: {
                active: true,
                message: 'Default Template Updated'
            }
        });
    }).catch((e) => {
        console.warn(e, 'Could not save settings for : Expense Code');
    });
}
