import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import DeleteButton from './deleteJobItemButton';
import Fade from '@material-ui/core/Fade';
import axiosInstance from '../../utilities/axios';
import { requestHeaderConfig } from '../../utilities';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    dateContainer: {
      marginTop: 0,
      minWidth: 150
    },
    hide: {
        display: 'none'
    },
    error: {
        border: '1px solid #ff0000'
    }
  }));

  const JobItem = (props) => {
    const classes = useStyles();
    const didInitialize = useRef(false);
    // when no records are found, rather than display an edit row, prompt them to add a new one
    let [disable, setDisableServiceLine] = useState((props.libItems.find(l => l.id === props.model.libItemId) && props.libItems.find(l => l.id === props.model.libItemId).serviceLine !== null));
    let [show, setShow] = useState(true);
    let [displayNone, setDisplayNone] = useState(false);
    let [payload, setPayload] = useState(false);
    let [serviceLine, setServiceLine] = useState(props.serviceLines.find(s => s.id === props.model.serviceLine) || 0);


    const validatePayload = (payload) => {
        // if any are undefined, remove the action, so the backend does not attempt to save to servicetrade
        if (!payload.serviceLine) delete payload.action;
        if (!payload.libItem) delete payload.action;
        if (!payload.quantity) delete payload.action;
        if (payload.usedOn < 0) delete payload.action; // if before Jan 1, 1970
        return payload
    };

    useEffect(() => {
        if (!show) {
            setPayload({...payload, ...{
                index: payload.model.index,
                action: 'DELETE',
                jobId: payload.model.jobItemId
            }})
            setTimeout(() => {
                setDisplayNone(true);
            }, 500);
        }
    }, [show]);

    useEffect(async () => {
        if (!payload) {
            await axiosInstance.get('/jobs',{
                params: {
                    companyId: props.login.user.company.id,
                    userId: props.login.user.id,
                    index: props.model.index
                },
                ...requestHeaderConfig(props.login.authToken)
                }).then((response) => {
                let model = response.data;
                setPayload({
                    index: model.index,
                    jobId: model.jobItemId,
                    libItem: props.libItems.find(l => l.id === model.libItemId),
                    quantity: model.quantity || 1,
                    usedOn: moment(model.usedOn ? new Date(model.usedOn * 1000) : new Date()).unix(),
                    serviceLine: 0, // update prior to api
                    source: {
                        type: 'tech',
                        userId: model.userId
                    },
                    model: model
                });
                setServiceLine(props.serviceLines.find(s => s.id === model.serviceLine))
            }).catch((e) => {
                console.warn(e, 'Could not fetch job');
            });
        }
    }, []);

    useEffect(async () => {
        if (didInitialize.current) {
            if (payload.libItem && payload.libItem.serviceLine !== null) {
                // A lib item with a default serviceLine should disable the service line dropdown
                setDisableServiceLine(true);
                // when selecting a new lib item, the service line should be set to it's default
                setServiceLine(payload.libItem.serviceLine);
            } else {
                if (payload.libItem) {
                    console.log(payload.libItem, 'disabled')
                } else {
                    console.log(payload, 'libtem is undefined')
                }
                // A lib item without a default serviceLine should allow the user to select a serviceLine
                setDisableServiceLine(false);
            }
            payload.serviceLine = serviceLine;
            await axiosInstance.put('/jobs/draft', {
                companyId: props.login.user.company.id,
                userId: props.login.user.id,
                job: validatePayload(payload)
            }, requestHeaderConfig(props.login.authToken)).catch((e) => {
                console.warn(e, 'Could not save draft');
            });
        } else {
            didInitialize.current =  true;
        }
    }, [payload]);

    if (!payload) {
        return (<></>);
    }

    return (
            <Fade in={show} className={displayNone ? classes.hide : ''}>
                <Grid container justify="space-around">
                    <FormControl variant="outlined">
                    <Autocomplete
                            noOptionsText="Select job item from dropdown"
                            className={!payload.libItem ? classes.error : ''}
                            value={payload.libItem}
                            onChange={(e, newValue) => {
                                setPayload({...payload, ...{libItem: newValue, action: 'PUT'}});
                            }}
                            options={props.libItems}
                            getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                    return option;
                                } else {
                                    return option.code + ' ' + option.name;
                                }
                            }}
                            getOptionSelected={(option, value) => {
                                return option.id === value.id
                            }}
                            style={{ width: 300, textAlign: 'left' }}
                            renderInput={(params) => <TextField style={{textAlign: 'left' }} {...params} variant="outlined" />}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <Autocomplete
                            className={!payload.libItem ? classes.error : ''}
                            disabled={disable}
                            value={serviceLine}
                            onChange={(e, newValue) => {
                                console.log(newValue, 'New Value')
                                setPayload({...payload, ...{serviceLine: newValue, action: 'PUT'}});
                            }}
                            getOptionSelected={(option, value) => {
                                if (typeof value === 'string') {
                                    return option.id === value;
                                } else {
                                    return option.id === value.id
                                }
                            }}
                            options={props.serviceLines}
                            getOptionLabel={(option) => {
                                return option.abbr + ' ' + option.name;
                            }}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <TextField style={{ width: 75 }} type="number" value={payload.quantity} onChange={(e) => {
                            setPayload({...payload, ...{quantity: +e.target.value, action: 'PUT'}});
                        }} variant="outlined" />
                    </FormControl>
                    <FormControl variant="outlined">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                autoOk={true}
                                className={classes.dateContainer}
                                variant="inline"
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                margin="normal"
                                value={payload.usedOn * 1000}
                                style={{ width: 180 }}
                                onChange={(e, selectedDate) => {
                                    setPayload({...payload, ...{usedOn: moment(selectedDate).unix(), action: 'PUT'}});
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <DeleteButton model={payload} setShow={setShow} />
                </Grid>
            </Fade>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(JobItem);

