import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import moment from 'moment';
import Linegraph from './index';

const UsageGraphById = (props) => {
    const [timeStamps, setTimeStamp] = useState([]);

    useEffect(async () => {
        let dataObject = {}
        await axiosInstance.get('/analytics/usageById', { params: { daysBack: props.analyticsDateRange, accountId: props.usageGraphId } }).then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                let timeStamp = moment(response.data[i].timestamp).format().slice(0,10)
                if (!dataObject[timeStamp]) {
                    dataObject[timeStamp] = 1;
                } else {
                    dataObject[timeStamp] += 1
                }
            }
            setTimeStamp(dataObject);
        });
    }, [props.analyticsDateRange])

    return (
        <>
            <Linegraph timeStamp={{...timeStamps}} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(UsageGraphById);