import React, { useEffect } from 'react'
import Linegraph from '../graphClickAnalytics'
import CompanyGraph from '../graphCompanySubmissions'
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccessReport from '../graphClickAnalytics/access';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux'
import { getClickActionAnalytics } from '../../actions/analyticsActions'

const ViewAnalytics = (props) => {
    const dispatch = useDispatch()
    const analytics = useSelector((state) => state.analytics)
    const { CompanySubmissions } = analytics

    const [days, setDays] = React.useState(7);

    useEffect(() => {
        props.dispatch({
            type: 'set_application_page',
            payload: 'Analytics'
        });
        dispatch(getClickActionAnalytics(days))
    }, [days, dispatch])

    const handleChange = (event) => {
        props.dispatch({
            type: 'set_analytics_date_range',
            payload: +event.target.value
        });

        setDays(+event.target.value);
    };

    return (
        <>
            <Container maxWidth="lg" style={{ marginBottom: 25 }}>
                <Grid item xs={12} style={{ marginBottom: 15 }}>
                    <Paper style={{ paddingTop: 10, paddingBottom: 10 }} >
                        <FormControl style={{ paddingLeft: 30 }} component="fieldset">
                            <FormLabel component="legend">Activity Range: Last {days} days</FormLabel>
                            <RadioGroup defaultValue={days.toString()} onChange={handleChange} row aria-label="gender" name="row-radio-buttons-group">
                                <FormControlLabel value="7" control={<Radio />} label="Past 7 Days" />
                                <FormControlLabel value="14" control={<Radio />} label="Past 14 Days" />
                                <FormControlLabel value="30" control={<Radio />} label="Past 30 Days" />
                                <FormControlLabel value="60" control={<Radio />} label="Past 60 Days" />
                                <FormControlLabel value="90" control={<Radio />} label="Past 90 Days" />
                            </RadioGroup>
                        </FormControl>

                        {days !== 0 &&
                            <Linegraph

                            />}
                    </Paper>
                </Grid>

                <Grid style={{ marginTop: 35, marginBottom: 35 }} item xs={12}>
                    {days !== 0 &&
                        <Paper>
                            <CompanyGraph
                                dataObj={CompanySubmissions}
                            />
                        </Paper>}
                      
                </Grid>

                <Grid item xs={12}>
                    <AccessReport />
                </Grid>


            </Container>
        </>
    )
}



const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps)(ViewAnalytics);

