import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useHistory } from "react-router-dom";
import { requestHeaderConfig } from '../../utilities';
import axiosInstance from '../../utilities/axios';
const useStyles = makeStyles((theme) => ({
    log: {
        marginTop: 5,
        marginLeft: 5
    }
}));

function Row(props) {
    const { row } = props;
    const navi = useHistory();
    const [open, setOpen] = React.useState(true);

    const hasDetails = row.details && row.details.length > 0;

    const getDefaultWeekDay = (startDay) => {
        var weekday = [{
            day: "Sun",
            value: 0
        }, {
            day: "Mon",
            value: 0
        }, {
            day: "Tue",
            value: 0
        }, {
            day: "Wed",
            value: 0
        }, {
            day: "Thu",
            value: 0
        }, {
            day: "Fri",
            value: 0
        }, {
            day: "Sat",
            value: 0
        }];
        // set the order of the week to match the set start day
        weekday.push.apply(weekday, weekday.splice(0, weekday.findIndex((week) => week.day === startDay)));
        return weekday;
    }

    const getTimecardById = async (id) => {
        let timecard = await axiosInstance.get('/timecard', {
            params: {
                id: id,
            },
            ...requestHeaderConfig(props.login.authToken)
        });
        props.dispatch({
            type: 'set_backdrop',
            payload: false
        });
        return timecard.data[0]
    }

    const navigateTo = async (details) => {
        console.log(details, 'The params in nav')

        props.dispatch({
            type: 'set_backdrop',
            payload: true
        });
        let timecard = await getTimecardById(details.id)
        // for every model (row) in the view
        timecard.model = timecard.model.map((model) => {
            let weekDay = getDefaultWeekDay(props.appSettings.startDay);
            // re-order the week days
            model.week = model.week.map((wModel, index, wArray) => {
                // update the value of the day that is already in the correct order with the matching day value in wArray
                weekDay[index].value = wArray.find(week => week.day === weekDay[index].day).value
                // replace the old, potentially incorrect week day with the new 
                return weekDay[index];
            });
            return model;
        });
        props.dispatch({
            type: 'set_view_timecard',
            payload: timecard
        });
        navi.push('/viewTimecard');
    }

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={{backgroundColor: "#f3f5f6"}}>
          <TableCell style={{width: '67px'}}>
            {hasDetails && (<IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>)}
          </TableCell>
          <TableCell component="th" scope="row" colSpan={6}>
            <Typography variant="subtitle2">{row.log}</Typography>
          </TableCell>
          <TableCell component="th" scope="row"></TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Office</TableCell>
                      <TableCell align="right">Week</TableCell>
                      <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row && row.details && row.details.map((detailsRow) => (
                      <TableRow key={detailsRow.id}>
                        <TableCell component="th" scope="row">
                          {detailsRow.techName}
                        </TableCell>
                        <TableCell>{detailsRow.techOffice}</TableCell>
                        <TableCell align="right">{detailsRow.range}</TableCell>
                        <TableCell align="right">
                          {detailsRow.typeName}
                        </TableCell>
                        <TableCell align="right">
                            <Button color="primary" onClick={(e) => { return navigateTo(detailsRow) }}>View</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}

const AuditLogReport = (props) => {
    const classes = useStyles();
    let [audit, setAuditLog] = useState([]);
    let [start, setStartDate] = useState(moment().format('L'));
    let [end, setEndDate] = useState(moment().format('L'));
    let [isLoading, setLoading] = useState(false);

    props.dispatch({
        type: 'set_application_page',
        payload: 'Audit Log'
    });

    const fetchAuditLogs = async () => {
        let payload = {user: props.login.user };
        await axiosInstance.post('/analytics/audit', payload, requestHeaderConfig(props.login.authToken)).then(async (response) => {
            setAuditLog(response.data.sort((a,b) => { return b.time - a.time}) );
        });
        setLoading(false);   
    }
    
    useEffect(async () => {
        props.dispatch({
            type: 'set_audit_filter',
            payload: {...props.auditFilter, ...{
                start: start,
                end: end
            }}
        });
        setLoading(true)
        await fetchAuditLogs();
    }, [])

    if (isLoading) {
        return (<LinearProgress />)
    }
      
    return (
        <>
            <Container maxWidth="lg">
                <Grid item xs>
                <TableContainer component={Paper} sx={{ minWidth: 650 }}>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Logs</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {audit.map((row) => (
                                <Row key={row.id} row={row} login={props.login} appSettings={props.appSettings} dispatch={props.dispatch} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
        reportDateLabel: state.reportDateRanges.label
    };
};
export default connect(mapStateToProps)(AuditLogReport);