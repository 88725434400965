import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';

const StacID = (props) => {
    const [stac, setStac] = useState('');

    const handleChange = (e, value, type) => {
        if (type === 'stac') {
            setStac(value)
        }
    }

    useEffect(() => {
        // may be able to retire this first dispatch
        props.dispatch({
            type: 'set_template_model',
            payload: {
                stacId: stac,
                isStacConnector: true,
                data: [],
                groupByItemCode:"UNGROUPED"
            }
        });
    }, [stac])

  return (
        <TextField
            id="stac-id"
            label="STAC ID"
            helperText="Found in your recipe URL. e.g. YXL0sHI7SKda"
            onChange={(e) => {
                return handleChange(e, e.target.value, 'stac')
            }}
            value={stac}
        />
  );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(StacID);