import React, { useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import axiosInstance from "../../utilities/axios";
import Alert from '../alert';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://servicetrade.com/">
        ServiceTrade
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  done: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#52af77'
  }
}));

const Unsubscribe = (props) => {
    const classes = useStyles();
    let [email, setEmail] = useState('');
    const [showError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    let [unsubscribedSuccessfully, setUnsubscribed] = useState(false);
    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    let token = getParameterByName('token');

    const handleSubmit = async e => {
        e.preventDefault();

        if (token !== null && token !== '') {
            setLoading(true);
            var data = {
                email: email,
                token: getParameterByName('token')
            };
            await axiosInstance.post('/unsubscribe', data).then((result) => {
                if (result.data.success === false) {
                    props.dispatch({
                        type: 'set_alert',
                        payload: {
                            active: true,
                            isError: true,
                            message: result.data.message
                        }
                    });
                    throw result;
                } else if (result.data.success) {
                    props.dispatch({
                        type: 'set_alert',
                        payload: {
                            active: true,
                            isError: false,
                            message: 'Unsubscribed successfully'
                        }
                    });
                }
                return result;
            }).catch(() => {
                setError(true);
                setLoading(false);
            });
        } else {
            props.dispatch({
                type: 'set_alert',
                payload: {
                    active: true,
                    isError: true,
                    message: 'Token invalid. Please follow the link from your email'
                }
            });
        }
    };
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <EmailOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Unsubscribe
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email to unsubscribe"
                        name="login"
                        autoFocus
                        error={showError}
                        helperText={showError ? 'Please check email' : ''}
                        placeholder="Enter the email to unsubscribe"
                        autoComplete="off"
                        disabled={unsubscribedSuccessfully}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSubmit}
                        disabled={(isLoading)}
                    >
                        Unsubscribe
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Alert />
                <Copyright />
            </Box>
        </Container>
    );
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(Unsubscribe);