import React, { useEffect, useState } from 'react';
import './styles/App.css';
import Dashboard from './components/dashboard';
import Login from './components/login';
import { connect } from 'react-redux';
import Unsubscribe from './components/unsubscribe';
import Authorize from './components/authorize';
import Error from './components/error';
import LogoutPage from './components/dashboard/logoutPage';


function App(props) {
    let [isLoggedIn, setLogin] = useState(props?.login?.authenticated);
    
    useEffect(() => {
        if (props.login.authenticated) {
            setLogin(true);
        } else {
            setLogin(false);
        }

    }, [props.login, setLogin])

    const isUnsubscribeLandingPage = () => {
        return window.location.pathname === '/unsubscribe';
    };
    const isAuthorizeLandingPage = () => {
        return window.location.pathname === '/authorize';
    };
    const isSubscriptionErrorLandingPage = () => {
        return window.location.pathname === '/error';
    };
    const isLogoutPage = () => {
        return window.location.pathname === '/logout';
    }

    if (isUnsubscribeLandingPage()) {
        return (
            <Unsubscribe />
        )
    }
    if (isAuthorizeLandingPage()) {
        return (
            <Authorize />
        )
    }
    if (isSubscriptionErrorLandingPage()) {
        return (
            <Error />
        )
    }
    if (!isLoggedIn) {
        return (
            <Login />
        )
    }

    if (isLogoutPage()) {
        return (
            <LogoutPage />
        )
    }
    return (
        <Dashboard />
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(App);