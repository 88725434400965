import React from 'react';
import StacID from './templateStacInput';
import Token from './templateTokenInput';
import { connect } from 'react-redux';

const TemplateStacConnector = () => {

  return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <StacID/>
            <Token/>
        </div>
  );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(TemplateStacConnector);