import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    job: {
        fontSize: 11,
        marginRight: 159,
        marginLeft: -45
    },
    service: {
        fontSize: 11,
        marginRight: 125
    },
    quanity: {
        fontSize: 11,
        width: 100
    },
    date: {
        fontSize: 11,
        marginLeft: -85
    }
  }));

const ModalHeader = (props) => {
    const classes = useStyles();

    function a11yProps(index) {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }

    return (
        <Tabs
            indicatorColor="primary"
            textColor="primary"
            aria-label="disabled tabs example"
        >
            <Tab className={classes.job} value={0} label="Job Item" {...a11yProps(0)} disabled/>
            <Tab className={classes.service} value={1} label="Service Line" {...a11yProps(1)} disabled />
            <Tab className={classes.quanity} value={2} label="Quantity" {...a11yProps(2)} disabled />
            <Tab className={classes.date} value={3} label="Used On" {...a11yProps(3)} disabled />
        </Tabs>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(ModalHeader);