import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const AlertBar = (props) => {
  const classes = useStyles();
  let [open, setOpen] = useState(false);

  useEffect(() => {
      setOpen(props.alert.active);
    }, [props.alert]);
    
  const handleClose = (event, reason) => {
    props.dispatch({
        type: 'set_alert',
        payload: {
            active: false,
            isError: false,
            message: ''
          }
    });
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.alert.isError ? 'error' : props.alert.isInfo ? 'info' : 'success'}>
          {props.alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(AlertBar);