import React, { useEffect } from 'react';
import JobItemIcon from '@material-ui/icons/Work';
import ClockEventsIcon from '@material-ui/icons/ScheduleRounded';
import ExpensesIcon from '@material-ui/icons/MonetizationOnOutlined';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    stepTwo: {
      width: '200px',
      height: '200px'
    }
  }));

const TemplateTypeSelection = (props) => {
    let [type, setType] = React.useState('Labor');
    const classes = useStyles();

    const handleTypeChange = (e, type) => {
        if (type !== null) {
            setType(type);
        }
    };

    const isDisabled = (type) => {
        return props.appSettings && props.appSettings[type] && !props.appSettings[type].enabled
    }

    useEffect(() => {
        // may be able to retire this first dispatch
        props.dispatch({
            type: 'set_template_type',
            payload: type
        });
        props.dispatch({
            type: 'set_template_model',
            payload: {
                type: type
            }
        })
    }, [type])

  return (
    <ToggleButtonGroup
        value={type}
        exclusive
        onChange={handleTypeChange}
        aria-label="Select a template type"
    >
        <ToggleButton className={classes.stepTwo} value="Labor" aria-label="Labor" disabled={isDisabled('jobItems')}>
            <Tooltip title="Labor Export Template" aria-label="Labor Export Template">
                <JobItemIcon> Labor</JobItemIcon>
            </Tooltip>
            Labor
        </ToggleButton>
        <ToggleButton className={classes.stepTwo} value="Clock" aria-label="Clock Event" disabled={isDisabled('clockEvents')}> 
            <Tooltip title="Clock Event Export Template" aria-label="Clock Event Export Template">
                <ClockEventsIcon> Clock</ClockEventsIcon>
            </Tooltip>
            Clock
        </ToggleButton>
        <ToggleButton className={classes.stepTwo} value="Expenses" aria-label="Expenses" disabled={isDisabled('expenses')}>
            <Tooltip title="Expenses Export Template" aria-label="Expenses Export Template">
                <ExpensesIcon> Expenses</ExpensesIcon>
            </Tooltip>
            Expenses
        </ToggleButton>
    </ToggleButtonGroup>
  );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(TemplateTypeSelection);