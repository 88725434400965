import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";
import axiosInstance from '../../../utilities/axios';
import { NAVIGATION, SUBMIT_TIMECARD_NAVIGATION_BUTTON } from '../../../utilities/constants/analytics';
import { analytics } from '../../../utilities';
import { requestHeaderConfig } from '../../../utilities';
const useStyles = makeStyles((theme) => ({
    submit: {
        margin: 10
    }
  }));

function Submit(props) {
    const classes = useStyles();
    let navi = useHistory();

    const setInitialTechnician = (data) => {
        if (data && data.userList && props.login.isAdmin) {
            // find and return the first user that is a tech
            return data.userList.find((u) => u.isTech || u.isHelper);
        } else {
            // return the current logged in user, since current user is a tech
            return props.login.user;
        }
    }

    const handleNavigation = () => {
        if (!props.appSettings.clockEvents.enabled && !props.appSettings.expenses.enabled && !props.appSettings.jobItems.enabled) {
            props.dispatch({
                type: 'set_alert',
                payload: {
                    active: true,
                    isInfo: true,
                    message: 'Please verify one or more timecard types are enabled. Or contact your company\'s Administrator'
                }
            });
            return false;
        } else {
            // set the initial tech when an admin clicks the "Submit new Timecard" button.
            // this way the default tech is reset back to the first tech in the list on initial page load
            axiosInstance.get('/listUsers', {
                params: {
                    companyId: props.login.user.company.id
                  },
                  ...requestHeaderConfig(props.login.authToken)
                })
                .then(function (response) {
                    // analytics({props: props, type: NAVIGATION, action: SUBMIT_TIMECARD_NAVIGATION_BUTTON, metadata: false})
                    props.dispatch({
                        type: 'set_technician',
                        payload: setInitialTechnician(response.data)
                    });
                    props.dispatch({
                        type: 'set_users',
                        payload: response.data.userList
                    });
                    navi.push('/submitTimeCard');
                })
                .catch(function (error) {
                    console.log(error, ': An error has occured while retrieving the settings');
                });
        }
    }
    return (
        <>
            {(props.login.canSubmit || props.login.isAdmin) && (
                <Button
                    onClick={handleNavigation}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    startIcon={<AddIcon />}
                >Submit Timecard</Button>)}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(Submit);