import React, { useEffect } from 'react';
import ColumnSelection from './templateColumnSelection';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

const TemplateColumnContainer = (props) => {
    let [step, setStep] = React.useState(0);

    useEffect(() => {
        if (!props.templateEditMode) {
            if (step === 0 && props.incrementTemplateSubStep !== 0) {
                setStep(props.incrementTemplateSubStep)
            }
            if (step === 1 && props.incrementTemplateSubStep === 0) {
                setStep(props.incrementTemplateSubStep)
            }
        }
    }, [props.incrementTemplateSubStep])

    const getStepContent = (stepIndex, edit) => {
        if (edit) {
            switch (stepIndex) {
                case 0:
                    return (
                        <> 
                            <div>
                                <Typography variant="overline" display="block" gutterBottom>Select your columns</Typography>
                                <ColumnSelection shouldFetchItemCodes={props.templateModel.groupByItemCode} />
                            </div>
                        </>);
                default:
                    return 'Unknown stepIndex';
            }
        }

        switch (stepIndex) {
            default:
                return (
                    <> 
                        <div>
                            <Typography variant="overline" display="block" gutterBottom>Select your columns</Typography>
                            <ColumnSelection shouldFetchItemCodes={false} />
                        </div>
                    </>);
        }
    }

    return (
        <>
            <Typography>{getStepContent(step, props.templateEditMode)}</Typography>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(TemplateColumnContainer);