import React, { useState } from 'react';
import { connect } from 'react-redux';
import JobItem from './addJobItem';
import axiosInstance from '../../utilities/axios';
import { makeStyles } from '@material-ui/core/styles';
import AddJobItemButton from './addJobItemButton';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    dateContainer: {
      marginTop: 0,
    }
  }));
const AddModalList = (props) => {
    const classes = useStyles();
    let [jobItems, setJobItems] = useState([]);    

    const handleAdd = async () => {
        console.log(props.modal.content[0], 'Clicked Add')
        await axiosInstance.put('/jobs/draft', {
            companyId: props.login.user.company.id,
            userId: props.login.user.id,
            job: {
                index: false,
                jobItemId: 0,
                jobId: props.modal.content[0].jobId,
                jobNumber: props.modal.content[0].jobNumber,
                libItemId: 0,
                quantity: 0,
                serviceLine: 0,
                source: {
                    type: 'tech',
                    userId: props.technician && props.technician.id ? props.technician.id : 0
                },
                usedOn: 0
            }}, requestHeaderConfig(props.login.authToken)).then((response) => {
                setJobItems([...jobItems, ...response.data]);
            }).catch((e) => {
                console.warn(e, 'Could not update job item');
            });
    };

    return (
        <>
            {jobItems.length > 0 ? jobItems.map((item) => (
                <JobItem model={item}/>
            )) : null}
            <AddJobItemButton onClick={handleAdd}/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(AddModalList);