import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    root: {
        textAlign: 'left'
    }
}));

const ImportUsers = (props => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const handleImport = () => {
      setLoading(true);

      axiosInstance.get('/import', {
        params: {
            companyId: props.login.user.company.id
          },
          ...requestHeaderConfig(props.login.authToken)
        })
        .then(function (response) {
            let importedUsers = response.data;
            props.dispatch({
                type: 'set_imported_users',
                payload: importedUsers
            });
            props.dispatch({
              type: 'set_alert',
              payload: {
                active: true,
                message: 'Import Successful'
              }
          });
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error, ': An error has occured while retrieving the settings');
        });
  };

  return (
    <>
        <div className={classes.root}>
        {!loading && (
            <Button
            onClick={handleImport}
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<GroupAddIcon />}
            style={{marginBottom: 20}}
        > Refresh From ServiceTrade </Button>
        )}
        {loading && (
            <LinearProgress />
        )}
      </div>
    </>
  );
});

const mapStateToProps = (state) => {
    return {
      ...state
    };
  };
  
  export default connect(mapStateToProps)(ImportUsers);
