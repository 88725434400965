import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import SwitchOptions from './switchOptions';
import StartDay from './startDay';
import NotificationEmails from './notificationEmails';
import AdditionalRows from './additionalRows';
import ExpenseCodes from './expenseCodes';
import UserSettings from './userSettings';
import Limit from './limit';
import TemplateSettings from './templateSettings'
import axiosInstance from '../../utilities/axios';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExternalSystems from './externalSystems';
import { requestHeaderConfig } from '../../utilities';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  }
}));

  const setInitialListArray = (defaultIsEnabled, props) => {
      if (!props.appSettings.limitByTech && !Array.isArray(props.appSettings.limitByTech)) {
          if (defaultIsEnabled) {
              return {
                  leftArr: [],
                  rightArr: props.officeList
              }
          } else {
              return {
                  leftArr: props.officeList,
                  rightArr: []
              }
          }
      } else {
          return {
              leftArr: props.officeList.filter((office) => {
                  return !props.appSettings.limitByTech.find((selection) => {
                      return selection.id === office.id;
                  })
              }),
              rightArr: props.appSettings.limitByTech
          }
      }
  }

const Settings = (props) => {
  let [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const initial = setInitialListArray(props.appSettings && props.appSettings.limitTechByOffice && props.appSettings.limitTechByOffice.enabled ? props.appSettings.limitTechByOffice.enabled : false, props);

  const hasFeature = (name) => {
    let features = props.login.settings.features;
    let hasFeature = false;
    if (features) {
        let feature = features.find((f) => { return f === name });
        if (!feature) {
            hasFeature = false;
        } else {
            hasFeature = true;
        }
    }
    return hasFeature;
  }

  props.dispatch({
    type: 'set_application_page',
    payload: 'Settings'
  });

  var jobItemFields = {
    title: 'Job Items',
    name: 'jobItems',
    options: [{
      label: "Enabled",
      name: 'jobItems',
      id: "jobItems_enabled",
      key: 'enabled'
    }, {
      label: "Submittable",
      name: 'jobItems',
      id: "jobItems_submittable",
      key: 'submittable'
    }, {
      label: "Combine By Item Code",
      name: 'jobItems',
      id: "jobItems_combine",
      key: 'combine',
      hide: true
    }, {
      label: "Allow Inline Editing",
      name: 'jobItems',
      id: "jobItems_edit",
      key: 'edit'
    }, {
      label: "Show Item Code Summary in Export",
      name: 'jobItems',
      id: "jobItems_itemCodeSummary",
      key: 'itemCodeSummary'
    }]
  }

  var clockEventsFields = {
    title: 'Clock Events',
    name: 'clockEvents',
    options: [{
      label: "Enabled",
      name: 'clockEvents',
      id: "clockEvents_enabled",
      key: 'enabled'
    }, {
      label: "Submittable",
      name: 'clockEvents',
      id: "clockEvents_submittable",
      key: 'submittable'
    }, {
      label: "Combine By Item Code",
      name: 'clockEvents',
      id: "clockEvents_combine",
      key: 'combine',
      hide: true
    }]
  }

  var expensesFields = {
    title: 'Expenses',
    name: 'expenses',
    options: [{
      label: "Enabled",
      name: 'expenses',
      id: "expense_enabled",
      key: 'enabled'
    }, {
      label: "Submittable",
      name: 'expenses',
      id: "expense_submittable",
      key: "submittable"
    }, {
      label: "Combine By Item Code",
      name: 'expenses',
      id: "expense_combine",
      key: "combine",
      hide: true
    }]
  }
  
  var clockEventTypeFields = {
    title: 'Clock Event Types',
    name: 'clockEventTypes',
    options: [{
      label: "Job Prep",
      name: 'clockEventTypes',
      id: "jobPrep",
      key: "jobPrep"
    }, {
      label: "Enroute",
      name: 'clockEventTypes',
      id: "enroute",
      key: "enroute"
    }, {
      label: "Onsite",
      name: 'clockEventTypes',
      id: "onsite",
      key: "onsite"
    }, {
      label: "On Break",
      name: 'clockEventTypes',
      id: "onbreak",
      key: "onbreak"
    }]
  }
  
  var commentsFields = {
    title: 'Comments',
    name: 'comments',
    options: [{
      label: "Enabled",
      name: 'comments',
      id: "comments",
      key: "enabled" //used to match the object key in the redux store
    }]
  }
  var qbdFields = {
    title: 'Billable Time',
    name: 'billable',
    options: [{
      label: "Enabled",
      name: 'billable',
      id: "billable",
      key: "enabled" //used to match the object key in the redux store
    }]
  }
  var limitFields = {
    title: 'Limit Technicians Shown By Office',
    name: 'limitTechByOffice',
    options: [{
      label: "Enabled",
      name: 'limitTechByOffice',
      id: "limitTechByOffice",
      key: "enabled"
    }]
  }
  var weeklyFields = {
    title: 'Display Reports By Week',
    name: 'weeklyReports',
    options: [{
      label: "Enabled",
      name: 'weeklyReports',
      id: "weeklyReports",
      key: "enabled"
    }]
  }

  var formatClockEventsByDecimal = {
    title: 'Display clock events in time format',
    name: 'clockEventDecimalFormat',
    options: [{
      label: "Enabled",
      name: 'clockEventDecimalFormat',
      id: "clockEventDecimalFormat",
      key: "enabled"
    }]
  }
  var showArchivedTimecards = {
    title: 'Show Archived Timecards',
    name: 'showArchivedTimecards',
    options: [{
      label: "Show",
      name: 'showArchivedTimecards',
      id: "showArchivedTimecards",
      key: "show"
    }]
  }
  var filterByManager = {
    title: 'Filter By Manager',
    name: 'filterByManager',
    options: [{
      label: "Enabled",
      name: 'filterByManager',
      id: "filterByManager",
      key: "enabled"
    }],
    default: {enabled: false},
    shouldUpdateUsersOnChange: true 
  }
  var sortByLastName = {
    title: 'Sort Timecards By Last Name',
    name: 'sortByLastName',
    options: [{
      label: "Enabled",
      name: 'sortByLastName',
      id: "sortByLastName",
      key: "enabled"
    }]
  }

  useEffect(async () => {
    await axiosInstance.get('/settings', {
      params: {
        companyId: props.login.user.company.id
      },
      ...requestHeaderConfig(props.login.authToken)
    })
      .then(function (response) {
        let updatedSettingsObj = response.data;
        props.dispatch({
          type: 'set_settings',
          payload: updatedSettingsObj
        });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error, ': An error has occured while retrieving the settings');
      });
  }, []);

  if (isLoading) {
    return (<>
      <div>Loading</div>
    </>);
  }

  if (props.login && window.Appcues && props.login.user && props.login.user.firstName !== 'ServiceTrade') {
    let user = props.login.user;
    let firstName = user.firstName;
    let lastName = user.lastName;
    window.Appcues.page();
    window.Appcues.identify(`${user.firstName} ${user.lastName}`, {
        firstName,
        lastName,
        companyName: user.company.name,
        name: `${firstName} ${lastName}`,
        platform: 'Timecard',
    }); 
  }
  return (
    <>
      <Container style={{ paddingTop: 20 }} maxWidth="lg">
        <Paper>
          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Additional Rows</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AdditionalRows />
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Calendar Start Day</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StartDay />
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Clock Event Types</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SwitchOptions fields={clockEventTypeFields} />
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Comments</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SwitchOptions fields={commentsFields} />
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Expense Codes</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ExpenseCodes />
            </AccordionDetails>
          </Accordion>


          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color="primary"><strong>Notification Emails</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <NotificationEmails />
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color="primary"><strong>External Systems</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ExternalSystems/>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Template Settings</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TemplateSettings />
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Type Settings</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <SwitchOptions fields={jobItemFields} />
                <SwitchOptions fields={clockEventsFields} />
                <SwitchOptions fields={expensesFields} />
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>User Settings</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UserSettings />
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Other Settings</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs>
                <Limit left={initial.leftArr} right={initial.rightArr}/>
                </Grid>
                <Grid item xs>
                <SwitchOptions fields={weeklyFields} />
                </Grid>
                <Grid item xs>
                <SwitchOptions fields={formatClockEventsByDecimal} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Quickbook Desktop Settings</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SwitchOptions fields={qbdFields} />
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Archive Settings</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
                 <SwitchOptions fields={showArchivedTimecards} />
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
              <Typography color='primary'><strong>Manager Settings</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
                 <SwitchOptions fields={filterByManager} />
            </AccordionDetails>
          </Accordion>
          {hasFeature('timecard_review') && (
            <Accordion className={classes.accordion}>
              <AccordionSummary  aria-controls="panel1a-content" id="panel1a-header" expandIcon={<ExpandMoreIcon />} >
                <Typography color='primary'><strong>Review Timecard Settings</strong></Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <SwitchOptions fields={sortByLastName} />
              </AccordionDetails>
            </Accordion>
          )}
        </Paper>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state
  };
};

export default connect(mapStateToProps)(Settings);