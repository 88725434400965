import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import { requestTimecardChange } from '../../../../actions/viewTimecardActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper';



const useStyles = makeStyles((theme) => ({

    comments: {
        width: '100%'
    },
    button: {
        margin: 10
    }
}));

const RequestChange = (props) => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [comment, setComment] = useState('')
    const {setStatus, setButtonsLoading, buttonsLoading} = props;

    const handleChange = (e) => {
        setComment(e.target.value)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleRequestChange = () => {
        setButtonsLoading(true);
        requestTimecardChange(props, comment)
        setStatus('Changes Requested')
        if (typeof (props.view.comments) === 'string') {
            if (comment.length > 0) {
                if (props.view.comments.length > 0) {
                    props.setComments([
                        {
                            name: props.view.techName,
                            adminComment: false,
                            msg: props.allComments,
                            timestamp: 'timestamp cannot be retrieved'
                        },
                        {
                            name: props.login.user.name,
                            avatar: props.login.user.avatar.small,
                            adminComment: props.login.user.avatar || false,
                            msg: comment,
                            timestamp: moment().format('lll')
                        }
                    ])
                } else {
                    props.setComments([
                        {
                            name: props.login.user.name,
                            avatar: props.login.user.avatar.small,
                            adminComment: props.login.user.avatar || false,
                            msg: comment,
                            timestamp: moment().format('lll')
                        }
                    ])

                }
            }
        } else {
            props.setComments([...props.allComments, {
                name: props.login.user.name,
                avatar: props.login.user.avatar.small,
                adminComment: props.login.canManage || false,
                msg: comment,
                timestamp: moment().format('lll')
            }])
            setButtonsLoading(false);
        }
        props.dispatch({
            type: 'set_alert',
            payload: {
                active: true,
                message: 'Comment Saved '
            }
        });
        setComment('')
        setOpen(false)
    };

    const closeOnly = () => {
        setOpen(false)
    }

    return (
        <div>
            {!buttonsLoading &&  <Button variant='contained' color='secondary' onClick={handleClickOpen}>
                {"Request Change"}
            </Button>}
           
            <Dialog
                open={open}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle style={{ color: '#004680' }} id="alert-dialog-title">
                    {"Reason for Requesting Change"}
                </DialogTitle>

                <DialogContent>
                    <Paper>
                        <TextField value={comment} onChange={handleChange} className={classes.comments} label="Comments" multiline rows={4} variant="outlined" />
                    </Paper>
                </DialogContent>
                <DialogActions style={{ paddingRight: 14 }} >
                    <Button color='white' className={classes.button} onClick={closeOnly}>Cancel</Button>
                    <Button style={{ backgroundColor: '#004680', color: 'white' }} color='primary' className={classes.button} onClick={handleRequestChange}>Submit</Button>
                </DialogActions>

            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(RequestChange);
