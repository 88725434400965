import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import SelectFileType from './selectFileType';
import { makeStyles } from '@material-ui/core/styles';
import { templateTypes } from '../../../Types/TemplateTypes';
import { getTemplate } from '../../../actions/templateActions'
import mTemplates from '../../utilities/templates'
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosInstance from '../../../utilities/axios';
import { v4 as uuid } from 'uuid';
import {
    handleItemCodeHeaders,
    handleItemCodeFormat,
    handleClockHeaders,
    groupClockEventData,
    handleCSVFormat,
    handleQBDFormat
} from '../../../actions/exportActions'
import Modal from '@mui/material/Modal';
import ExportIcon from '@mui/icons-material/ArrowDownward';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { requestHeaderConfig } from '../../../utilities';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontSize: 10,
        width: 200
    },
    customBadge: {
        color: "#004680"
    },
    primary: {
        color: '#00000061'
    },
    selections: {
        textAlign: 'center',
    },
    download: {
        marginTop: '15px'
    },
    container: {
        width: '250px',
        padding: '15px'
    },
}));

const Submit = (props) => {
    const classes = useStyles();
    const { laborDefault, clockDefault } = props.appSettings
    const { setSelectionModel, userList } = props
    const dispatch = useDispatch();
    const [openExportModal, setExportModal] = useState(false);
    let [bulkActions, setBulkActions] = useState(openExportModal ? props.constTimecards : []);
    let [laborExports, setLaborExports] = useState([]);
    let [clockExports, setClockExports] = useState([]);
    let [expenseExports, setExpenseExports] = useState([]);
    let [weekDayOrder, setWeekDayOrder] = useState([]);
    const [loading, setIsLoading] = useState(false);
    let decimalFormatEnabled = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;
    
    const hasFeature = (name) => {
        let features = props.appSettings.features;
        let hasFeature = false;
        if (features) {
            let feature = features.find((f) => { return f === name });
            if (!feature) {
                hasFeature = false;
            } else {
                hasFeature = true;
            }
        }
        return hasFeature;
    }

    const handleDownload = async (headers, body, template) => {
        setIsLoading(true)
        const batchId = uuid();

        function executeApiCall(batch, headers, token, stacId, accountId, userId, batchId, index) {
            return axiosInstance.post('/connector', {
                store: true,
                batch: batch,
                stacId: stacId,
                batchId: batchId,
                index: index
            }, requestHeaderConfig(props.login.authToken));
        }
          
        async function executeBatchApiCall(headers, body, token, stacId, accountId, userId) {
            const batchSize = 100;
            const bodyLength = body.length;
            const promises = [];
            let index = 0; 

            for (let i = 0; i < bodyLength; i += batchSize) {
              const batch = body.slice(i, i + batchSize);
              promises.push(executeApiCall(batch, headers, token, stacId, accountId, userId, batchId, index));
              index++;
            }
          
            try {
              const results = await Promise.all(promises);
              results.forEach((response, index) => {
                console.log(`Batch ${index + 1} completed with status ${response.statusText}`);
              });
              return results;
            } catch (error) {
              console.error('Error executing batch API calls:', error);
              return [];
            }
        }


        await executeBatchApiCall(
            headers, 
            body, 
            template.token, 
            template.stacId, 
            props.appSettings.id, 
            props.login.user.id
        );

        return await axiosInstance.post('/connector', {
                headers: headers,
                stacId: template.stacId,
                user: props.login.user.id,
                batchId: batchId,
                features: props.appSettings.features
            }, requestHeaderConfig(props.login.authToken, {
                "stacBasicToken": template.token
            })
            )
            .then(function (response) {
                let files = response.data;
                const prepareFileForDownload = (data) => {
                    let fileContents = data.statusCode ? data : data.contents;
                    if (typeof fileContents === 'object' && fileContents.statusCode) {
                        fileContents = [fileContents]
                    } else if (!fileContents.length) {
                        fileContents = [{'': ''}]
                    }

                    function convertArrayOfObjectsToCSV(array) {
                        let header = Object.keys(array[0]).join(',');
                        let csv = array.map(obj => {
                            return Object.values(obj).join(',');
                        }).join('\n');
                        return header + '\n' + csv;
                    }

                    // Check QBD Functionality, there was a reason why we had a check for data.contents. Re-test
                    if (typeof fileContents === 'string') {
                        let csvContent = data.contents;
                        return csvContent;
                    } else {
                        return convertArrayOfObjectsToCSV(fileContents)
                    }
                };

                const downloadCSV = (csv, filename) => {
                    let csvFile;
                    let downloadLink;
                    csvFile = new Blob([csv], {type: "text/csv;charset=utf-8;"});
                    if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(csvFile, filename);
                    } else {
                        downloadLink = document.createElement("a");
                        downloadLink.download = filename;
                        downloadLink.href = window.URL.createObjectURL(csvFile);
                        downloadLink.style.display = "none";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                    }
                }

                // const downloadFile = (file, fileName) => {
                //     let anchor = document.createElement("a");
                //     anchor.href = file;
                //     anchor.download = fileName || "error.csv";
                //     document.body.appendChild(anchor);
                //     anchor.click();
                //     document.body.removeChild(anchor);
                // }
                let individualFiles = Object.keys(files);
                for (let x = 0; x < individualFiles.length; x++) {
                    let file = files[individualFiles[x]];
                    if (file && file.type && file.type.toLowerCase() === 'file') {
                        let readyFile = prepareFileForDownload(file)
                        downloadCSV(readyFile, file.name)
                    }
                }
                setIsLoading(false)

                return response;
            })
            .catch(function (error) {
                console.log(error, ': An error has occured while retrieving the settings');
            });
    };

    // ** START data for labor items template **
    // **************************************
    let [laborHeader, setLaborHeader] = useState([])
    let [laborItemTemp, setLaborItemTemp] = useState()
    let [laborTemp, setLaborTemp] = useState([])
    let [laborExportHeader, setExportHeader] = useState([])

    // ** START data for clock events template **
    // ****************************************
    const [clockEventHeader, setClockEventHeader] = useState([])
    const [clockEventTemp, setClockEventTemp] = useState([])
    const [clockEvent, setClockEvent] = useState([])
    const [clockExportHeader, setClockExportHeader] = useState([])

    // ** START data for file download **
    // ****************************************
    let [laborDefaultName, setLaborDefaultName] = useState(props.appSettings.laborDefault.name);
    let [clockDefaultName, setClockDefaultName] = useState(props.appSettings.clockDefault.name)
    let [fileExtension, setFileExtension] = useState('csv');
    let [fileExtensionClock, setFileExtensionClock] = useState('csv');


    useEffect(() => {
        setExportModal(props.showExportModal);
    }, [props.showExportModal]);

    const handleCloseExportModal = () => {
        props.dispatch({
            type: 'close_export_modal'
        });
    }
    useEffect(() => {
        let preSortedLaborTemplate = [];
        let preSortedClockTemplate = [];
        if (laborDefault !== undefined) {
            if (laborDefault.isStacConnector) { // For some reason, the "isStacConnector" flag is not being passed BACK to the UI from the DB"
                preSortedLaborTemplate = mTemplates.labor.sort(function (a, b) { return a.index - b.index })
                setLaborItemTemp(preSortedLaborTemplate)
            } else {
                // the user somehow added a false, incomplete template, and boolean false was saved as the data array. 
                // should verify the add template modal is handling edge cases.
                if (laborDefault.data) {
                    preSortedLaborTemplate = laborDefault.data.sort(function (a, b) { return a.index - b.index })
                    setLaborItemTemp(preSortedLaborTemplate);
                } else {
                    preSortedLaborTemplate = [
                        {
                          header: "jobNumber",
                          index: 0,
                          value: "",
                          alias: "jobNumber"
                        },
                        {
                          header: "technician_id",
                          index: 1,
                          value: "",
                          alias: "technician_id"
                        },
                        {
                          header: "technician_name",
                          index: 2,
                          value: "",
                          alias: "technician_name"
                        },
                        {
                          header: "location",
                          index: 3,
                          value: "",
                          alias: "location"
                        },
                        {
                          header: "item_code",
                          index: 4,
                          value: "",
                          alias: "item_code"
                        }
                      ];
                    setLaborItemTemp(preSortedLaborTemplate)
                }
            }
        }
        if (clockDefault !== undefined) {
            if (clockDefault.isStacConnector) { // For some reason, the "isStacConnector" flag is not being passed BACK to the UI from the DB"
                preSortedClockTemplate = mTemplates.clock.sort(function (a, b) { return a.index - b.index })
                setClockEventTemp(preSortedClockTemplate)
            } else {
                preSortedClockTemplate = clockDefault.data.sort(function (a, b) { return a.index - b.index })
                setClockEventTemp(preSortedClockTemplate)
            }
        }
        if (props.appSettings.weekDayOrder !== undefined) {
            const sortWeekDay = (weekday, startDay) => {
                // set the order of the week to match the set start day
                weekday.push.apply(weekday, weekday.splice(0, weekday.findIndex((week) => week.day === startDay)));
                return weekday;
            }
            let weekOrder = props.appSettings.weekDayOrder.map((item) => {
                let header = "";
                switch (item.day) {
                    case "Sun":
                        header = "Sunday"
                        break;
                    case "Mon":
                        header = "Monday"
                        break;
                    case "Tue":
                        header = "Tuesday"
                        break;
                    case "Wed":
                        header = "Wednesday"
                        break;
                    case "Thu":
                        header = "Thursday"
                        break;
                    case "Fri":
                        header = "Friday"
                        break;
                    case "Sat":
                        header = "Saturday"
                        break;
                    default:
                        break;
                }
                return { ...item, header: header, index: '-' }
            });
            setWeekDayOrder(sortWeekDay(weekOrder, props.appSettings.startDay))
        }

        if (preSortedLaborTemplate) {
            setLaborTemp([...preSortedLaborTemplate, ...weekDayOrder])
            setLaborHeader(preSortedLaborTemplate.map((item) => {
                return item.alias
            }))
        }

        if (preSortedClockTemplate) {
            setClockEvent([...preSortedClockTemplate, ...weekDayOrder])
            setClockEventHeader(preSortedClockTemplate.map((item) => {
                return item.alias
            }))
        }
        setBulkActions(props.bulkActions);
        // eslint-disable-next-line
    }, [props.bulkActions, laborItemTemp, clockEventTemp, laborDefault])

    useEffect(async () => {
        let laborExports = [];
        let clockExports = [];
        let expenseExports = [];
        let header = [];
        const removeExtraHeaders = (expJson) => {
            // remove extra header rows by first field 'job_number'
            let newArr = [];
            if (expJson.length) {
                let header = expJson[0];
                newArr = expJson.filter(row => row[0] !== 'job_number');
                newArr.unshift(header);
            }
            return newArr;
        }

        // convert dateRange string of "MMM DD" to array of individual dates in a format of MM/DD/YYYY, where each date is between the start and end dates
        const convertDateRange = (dateRange) => {
            let dates = [];
            let start = moment(dateRange.split(' - ')[0], 'MMM DD');
            let end = moment(dateRange.split(' - ')[1], 'MMM DD');
            while (start <= end) {
                dates.push(start.format('MM/DD/YYYY'));
                start = moment(start).add(1, 'days');
            }
            return dates;
        }
        // consider what should happen when dates differ between types
        for (let x = 0; x < (openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).length; x++) {
            let view = openExportModal && props.constTimecards.length ? props.constTimecards[x] : bulkActions[x];
            if (!view.weekDates) {
                if (!view.dateRange && view.range) {
                    view.weekDates = convertDateRange(view.range)
                } else if (view.dateRange && !view.range) {
                    view.weekDates = convertDateRange(view.dateRange)
                }
            }
            setLaborItemTemp(laborItemTemp.sort(function (a, b) { return a.index - b.index }))
            view.userList = userList;
            switch (view.typeName) {
                case 'Labor Items':
                    if (laborDefault.isStacConnector === true) {
                        header = [...laborHeader, view.weekDates[0], view.weekDates[1], view.weekDates[2], view.weekDates[3], view.weekDates[4], view.weekDates[5], view.weekDates[6], 'total'];
                        setExportHeader(header);
                        view.appSettings = props.appSettings;
                        laborExports = [...laborExports, ...handleCSVFormat(view, laborHeader, laborTemp)];
                    } else if (laborDefault.groupByItemCode === false || laborDefault.groupByItemCode === templateTypes.UNGROUPED || laborDefault.groupByItemCode === null) {
                        header = [...laborHeader, view.weekDates[0], view.weekDates[1], view.weekDates[2], view.weekDates[3], view.weekDates[4], view.weekDates[5], view.weekDates[6], 'total'];
                        if (laborTemp.filter(item => item.header === 'TIMEACT').length > 0) header = laborHeader;
                        setExportHeader(header);
                        view.appSettings = props.appSettings;
                        laborExports = [...laborExports, ...handleCSVFormat(view, laborHeader, laborTemp)];
                    } else if (laborDefault.groupByItemCode === true || laborDefault.groupByItemCode === templateTypes.GROUPED) {
                        header = handleItemCodeHeaders(view, laborDefault)
                        setExportHeader(header)
                        let data = handleItemCodeFormat(view, laborDefault)
                        laborExports = [...laborExports, ...data]
                    } else if (laborDefault.groupByItemCode === templateTypes.QBD) {
                        header = laborHeader
                        setExportHeader(header);
                        setExportHeader(header && header.length ? [header.join('\t')] : header);
                        view.appSettings = props.appSettings;
                        laborExports = [...laborExports, ...handleQBDFormat(view, laborHeader, laborTemp)].map(item => {
                            return item.filter(value => value !== undefined)
                        });
                       
                        if (laborExports && laborExports.length) {
                            laborExports = laborExports.map((c) => {
                                return [c.join('\t')];
                            });
                        }
                    }
                    break;
                case 'Clock Events':
                    if (clockDefault.groupByItemCode === false || clockDefault.groupByItemCode === templateTypes.UNGROUPED) {
                        header = [...clockEventHeader, view.weekDates[0], view.weekDates[1], view.weekDates[2], view.weekDates[3], view.weekDates[4], view.weekDates[5], view.weekDates[6], "total"];
                        setClockExportHeader(header)
                        setClockEventTemp(clockEventTemp.sort(function (a, b) { return a.index - b.index }))
                        view.appSettings = props.appSettings;
                        let data = handleCSVFormat(view, clockEventHeader, clockEvent, decimalFormatEnabled)
                        clockExports = [...clockExports, ...data];
                    } else if (clockDefault.groupByItemCode === true || clockDefault.groupByItemCode === templateTypes.GROUPED) {
                        header = handleClockHeaders(view, clockDefault)
                        setClockExportHeader(header)
                        let data = groupClockEventData(view, clockDefault, decimalFormatEnabled)
                        clockExports = [...clockExports, ...data]
                    } else if (clockDefault.groupByItemCode === templateTypes.QBD) {
                        header = clockEventHeader;
                        // this joins the headers by tab seperated value as required by quickbooks
                        setClockExportHeader(header && header.length ? [header.join('\t')] : header);
                        view.appSettings = props.appSettings;
                        clockExports = [...clockExports, ...handleQBDFormat(view, clockEventHeader, clockEvent)].map(item => {
                             return item.filter(value => value !== undefined)
                        })
                        if (clockExports && clockExports.length) {
                            clockExports = clockExports.map((c) => {
                                return [c.join('\t')];
                            });
                        }
                    }
                    break;
                case 'Expenses':
                    header = ['job_number', 'technician_id', 'technician', 'location', 'item_code', view.weekDates[0], view.weekDates[1], view.weekDates[2], view.weekDates[3], view.weekDates[4], view.weekDates[5], view.weekDates[6], "total"];
                    setExportHeader(header);
                    view.appSettings = props.appSettings;
                    expenseExports = [...expenseExports, ...handleCSVFormat(view, header)];
                    break;
                default:
            }
        }
        setLaborExports(laborExports)
        setClockExports(clockExports)
        setExpenseExports(removeExtraHeaders(expenseExports));
        // eslint-disable-next-line
    }, [bulkActions, laborItemTemp, laborTemp, laborHeader, laborDefault.groupByItemCode]);

    useEffect(() => {
        dispatch(getTemplate(props));
        if (laborDefault.groupByItemCode === 'QBD') setFileExtension('iif');
        if (clockDefault.groupByItemCode === 'QBD') setFileExtension('iif');
    },[])

    const handleLaborItemTemplateChange = (selectedName, templateType) => {
        props.dispatch({ type: 'set_placeHolder',payload: openExportModal && bulkActions.length ? bulkActions : props.constTimecards});
        props.dispatch({ type: 'set_bulkActions',payload: []});
        setSelectionModel([])
        let selected = props.templateList.templates.find(item => item.name === selectedName);
        if (templateType === 'laborDefault') {
            if (selected.groupByItemCode === 'QBD') setFileExtension('iif');
            if (selected.groupByItemCode !== 'QBD') setFileExtension('csv');
        }
        if (templateType === 'clockDefault') {
            if (selected.groupByItemCode === 'QBD') setFileExtensionClock('iif');
            if (selected.groupByItemCode !== 'QBD') setFileExtensionClock('csv');
        }
        let updateTemp = {
            name: selected.name,
            type: selected.type,
            data: selected.data,
            groupByItemCode: selected.groupByItemCode,
            isStacConnector: selected.isStacConnector,
        }

        axiosInstance.post('/settings', {
            companyId: props.login.user.company.id,
            key: templateType,
            value: updateTemp
        }, requestHeaderConfig(props.login.authToken)).then(response => {
            let updatedSettingsObj = response.data;
            props.dispatch({
                type: 'set_settings',
                payload: updatedSettingsObj
            });
        }).catch((e) => {
            console.warn(e, 'Could not save settings for : Expense Code');
        });

        if (templateType === 'clockDefault') {
            setClockDefaultName(selectedName);
            setLaborDefaultName(laborDefaultName);
        }
        if (templateType === 'laborDefault')  {
            setLaborDefaultName(selectedName);
            setClockDefaultName(clockDefaultName);
        }

    }

    const filterLaborTemplates = (arr) => {
        if (arr && arr.length) {
            return arr.filter(item => item.type === "Labor")
        } else {
            return [];
        }
    }

    const filterClockTemplates = (arr) => {
        if (arr && arr.length) {
            return arr.filter(item => item.type === "Clock")
        } else {
            return [];
        }
    }

    const handleTSVDownload = () => {
        let tsv = '';
        tsv = tsv + clockExportHeader + '\n';
        clockExports.forEach((event) => {
            tsv += event[0] + '\n';
        })
        let link = document.createElement('a');
        link.download = 'timecard-Clock-Event-Export.iif';
        link.href = 'data:text/tab-separated-values,' + encodeURIComponent(tsv);
        link.click();
    }
    const handleLaborTSVDownload = () => {
        let tsv = '';
        tsv = tsv + laborExportHeader + '\n';

        laborExports.forEach((event) => {
            tsv += event[0] + '\n';
        })
        let link = document.createElement('a');
        link.download = 'timecard-Labor-Export.iif';
        link.href = 'data:text/tab-separated-values,' + encodeURIComponent(tsv);
        link.click();
    }

    const mainLaborTemplateHTML = () => {
        if (props && props.login && props.login.user && props.login.user.email && props.login.user.email.includes('@servicetrade.com') || hasFeature('master_timecard_export')) {
            return (<CSVLink filename={`main-template.csv`} data={laborExports} headers={laborExportHeader}>
            <Button className={classes.download} variant="contained" disabled={(openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).filter(e => e.typeName === 'Labor Items').length === 0} startIcon={<ExportIcon />}>Get Main Template</Button>
            </CSVLink>)
        } else {
            return ''
        }
    }
    const mainClockTemplateHTML = () => {
        if (props && props.login && props.login.user && props.login.user.email && props.login.user.email.includes('@servicetrade.com')) {
            return (<CSVLink filename={`main-template.csv`} data={clockExports} headers={clockExportHeader}>
            <Button className={classes.download} variant="contained" disabled={(openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).filter(e => e.typeName === 'Clock Events').length === 0} startIcon={<ExportIcon />}>Get Main Template</Button>
        </CSVLink>)
        } else {
            return ''
        }
    }

    return (
        <>
                <Modal
                    open={openExportModal}
                    onClose={handleCloseExportModal}
                    aria-labelledby="Exports"
                    aria-describedby="Export Selected Timecards"
                >
                    <Box sx={{ ...style }}>
                        <h2>Export Selected Timecards ( {openExportModal && !bulkActions.length && props.constTimecards.length ? props.constTimecards.length : bulkActions.length} )</h2>
                        <div><h3>Select Export Type</h3></div>
                        <Stack spacing={{ xs: 2, sm: 2, md: 2 }} direction="row" spacing={3}>
                            <Box component="div" className={classes.container} sx={{ border: '1px dashed grey' }}>
                                <Box className={classes.selections}>
                                    <div><strong>Labor Items</strong></div>
                                    <div>{'('+(openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).filter(e => e.typeName === 'Labor Items').length+') row(s) selected'}</div><br/>
                                    <div>Select a template</div><br/>
                                    <SelectFileType className={classes.dropdown} value={laborDefaultName} setValue={(e) => handleLaborItemTemplateChange(e, "laborDefault")} data={filterLaborTemplates(props.templateList.templates).map(item => item.name)}/>

                                    {filterLaborTemplates(props.templateList.templates).filter((arr) => arr.name === laborDefaultName && arr.isStacConnector === true)[0] !== undefined ? <><a onClick={() => {
                                        return handleDownload(laborExportHeader, laborExports, filterLaborTemplates(props.templateList.templates).filter((arr) => arr.name === laborDefaultName && arr.isStacConnector === true)[0])
                                    }}>
                                        {loading ? <CircularProgress size={15}/> : 
                                        <>
                                            <Button className={classes.download} variant="contained" startIcon={<ExportIcon />}>Download</Button>
                                        </>}
                                    </a> {mainLaborTemplateHTML()}</> :
                                    <>
                                        {filterLaborTemplates(props.templateList.templates).filter((arr) => arr.name === laborDefaultName)[0] && filterLaborTemplates(props.templateList.templates).filter((arr) => arr.name === laborDefaultName)[0].groupByItemCode === 'QBD' ? 
                                        <a onClick={handleLaborTSVDownload}>
                                            <Button className={classes.download} variant="contained" startIcon={<ExportIcon />}>Download</Button>  
                                        </a> : <CSVLink filename={`timecard-Labor-Item-Export.csv`} data={laborExports} headers={laborExportHeader}>
                                        <Button className={classes.download} variant="contained" disabled={(openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).filter(e => e.typeName === 'Labor Items').length === 0} startIcon={<ExportIcon />}>Download</Button>
                                        </CSVLink>}
                                    </>}
                                    
                                </Box>
                            </Box> 
                            <Box component="div" className={classes.container} sx={{ border: '1px dashed grey' }}>
                                <Box className={classes.selections}>
                                    <div><strong>Clock Events</strong></div>
                                    <div>{'('+(openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).filter(e => e.typeName === 'Clock Events').length+') row(s) selected'}</div><br/>
                                    <div>Select a template</div><br/>
                                    <SelectFileType value={clockDefaultName} setValue={(e) => handleLaborItemTemplateChange(e, "clockDefault")} data={filterClockTemplates(props.templateList.templates).map(item => item.name)}/>
                                    
                                    {filterClockTemplates(props.templateList.templates).filter((arr) => arr.name === clockDefaultName && arr.isStacConnector === true)[0] !== undefined ? <><a onClick={() => {
                                        return handleDownload(clockExportHeader, clockExports, filterClockTemplates(props.templateList.templates).filter((arr) => arr.name === clockDefaultName && arr.isStacConnector === true)[0])
                                    }}>
                                        {loading ? <CircularProgress size={15}/> : <Button className={classes.download} variant="contained" startIcon={<ExportIcon />}>Downloads</Button>}
                                    </a> {mainClockTemplateHTML()}</>  :
                                    <>
                                        {filterClockTemplates(props.templateList.templates).filter((arr) => arr.name === clockDefaultName)[0] && filterClockTemplates(props.templateList.templates).filter((arr) => arr.name === clockDefaultName)[0].groupByItemCode === 'QBD' ? 
                                        <a onClick={handleTSVDownload}>
                                            <Button className={classes.download} variant="contained" startIcon={<ExportIcon />}>Download</Button>  
                                        </a> : <CSVLink filename={`timecard-Clock-Event-Export.csv`} data={clockExports} headers={clockExportHeader}>
                                        <Button className={classes.download} variant="contained" disabled={(openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).filter(e => e.typeName === 'Clock Events').length === 0} startIcon={<ExportIcon />}>Download</Button>
                                        </CSVLink>}
                                    </>}
                                </Box>
                            </Box>
                            <Box component="div" className={classes.container} sx={{ border: '1px dashed grey' }}>
                                <Box className={classes.selections} style={{marginTop: '25%'}}>
                                    <div><strong>Expenses</strong></div>
                                    <div>{'('+(openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).filter(e => e.typeName === 'Expenses').length+') row(s) selected'}</div>
                                    <CSVLink filename={`timecard-Expenses-Export.csv`} data={expenseExports}>
                                        <Button className={classes.download} variant="contained" disabled={(openExportModal && props.constTimecards.length ? props.constTimecards : bulkActions).filter(e => e.typeName === 'Expenses').length === 0} startIcon={<ExportIcon />}>
                                            Download
                                        </Button>  
                                    </CSVLink>
                                </Box>
                            </Box>
                        </Stack>
                    </Box>
                </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(Submit);
