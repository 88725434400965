const labor = {
	"data": [{
			"header": "jobNumber",
			"index": 0,
			"value": "",
			"alias": "Job Number",
			"id": 1
		},
		{
			"header": "technician_id",
			"index": 1,
			"value": "",
			"alias": "Technician ID",
			"id": 2
		},
		{
			"header": "technician_name",
			"index": 2,
			"value": "",
			"alias": "Technician Name",
			"id": 3
		},
		{
			"header": "job_type",
			"index": 3,
			"value": "",
			"alias": "Job Type",
			"id": 4
		},
		{
			"header": "item_code",
			"index": 4,
			"value": "",
			"alias": "Item Code",
			"id": 5
		},
		{
			"header": "technician_email",
			"index": 5,
			"value": "",
			"alias": "Technician Email",
			"id": 6
		},
		{
			"header": "companyId",
			"index": 6,
			"value": "",
			"alias": "Vendor ID", // A company id is from the user object
			"id": 7
		},
		{
			"header": "location",
			"index": 7,
			"value": "",
			"alias": "Location",
			"id": 8
		},
		{
			"header": "companyName",
			"index": 8,
			"value": "",
			"alias": "Company Name",
			"id": 9
		},
		{
			"header": "tech_office",
			"index": 9,
			"value": "",
			"alias": "Technicians Office",
			"id": 10
		},
		{
			"header": "Date",
			"index": 10,
			"value": "",
			"alias": "Date",
			"id": 12
		},
		{
			"header": "Item (quickbooks desktop)",
			"index": 11,
			"value": "",
			"alias": "ITEM",
			"id": 13
		},
		{
			"header": "BLANK",
			"index": 12,
			"value": "",
			"alias": "BLANK - (Change as needed)",
			"id": 15
		},
		{
			"header": "XFERTOPAYROLL (quickbooks desktop)",
			"index": 13,
			"value": "",
			"alias": "XFERTOPAYROLL",
			"id": 16
		},
		{
			"header": "BillingStatus (quickbooks desktop)",
			"index": 14,
			"value": "",
			"alias": "BILLINGSTATUS",
			"id": 17
		},
		{
			"header": "total",
			"index": 15,
			"value": "",
			"alias": "Total",
			"id": 18
		},
		{
			"header": "actionBy",
			"index": 16,
			"value": "",
			"alias": "Action By",
			"id": 19
		},
		{
			"header": "timecardId",
			"index": 17,
			"value": "",
			"alias": "Timecard ID",
			"id": 20
		},
		{
			"header": "quantity",
			"index": 18,
			"value": "",
			"alias": "Quantity",
			"id": 21
		},
		{
			"header": "usedOn",
			"index": 19,
			"value": "",
			"alias": "Used On Date",
			"id": 22
		},
		{
			"header": "EMP",
			"index": 20,
			"value": "",
			"alias": "EMP",
			"id": 23
		},
		{
			"header": "QBD_JOB",
			"index": 21,
			"value": "",
			"alias": "JOB 1 (Company : Location)",
			"id": 24
		},
		{
			"header": "QBD_JOB2",
			"index": 22,
			"value": "",
			"alias": "JOB 2 (Company : Job)",
			"id": 25
		},
		{
			"header": "BLANK2",
			"index": 23,
			"value": "",
			"alias": "BLANK 2 (change as needed)",
			"id": 26
		},
		{
			"header": "begin_date",
			"index": 24,
			"value": "",
			"alias": "Begin Date",
			"id": 27
		},
		{
			"header": "externalId",
			"index": 25,
			"value": "",
			"alias": "External ID",
			"id": 28
		},
		{
			"header": "locationCompanyId",
			"index": 26,
			"value": "",
			"alias": "Company ID", // A location
			"id": 29
		},
		{
			"header": "locationId",
			"index": 27,
			"value": "",
			"alias": "Location ID",
			"id": 30
		},
		{
			"header": "techExtId",
			"index": 28,
			"value": "",
			"alias": "Technician External ID",
			"id": 31
		},
		{
			"header": "wageType",
			"index": 29,
			"value": "",
			"alias": "Wage Type",
			"id": 32
		},
		{
			"header": "clockInTimestamp",
			"index": 30,
			"value": "",
			"alias": "Clock In Time",
			"id": 33
		},
		{
			"header": "clockOutTimestamp",
			"index": 31,
			"value": "",
			"alias": "Clock Out Time",
			"id": 34
		},
		{
			"header": "clockInID",
			"index": 32,
			"value": "",
			"alias": "Clock In ID",
			"id": 35
		},
		{
			"header": "clockOutID",
			"index": 33,
			"value": "",
			"alias": "Clock Out ID",
			"id": 36
		},
		{
			"header": "calendarStartDay",
			"index": 34,
			"value": "",
			"alias": "Calendar Start Day",
			"id": 37
		},
		{
			"header": "approvedTimestamp",
			"index": 35,
			"value": "",
			"alias": "Approved Timestamp",
			"id": 38
		},
		{
			"header": "week_start",
			"index": 36,
			"value": "",
			"alias": "Week Start Timestamp",
			"id": 39
		},
		{
			"header": "week_end",
			"index": 37,
			"value": "",
			"alias": "Week End Timestamp",
			"id": 40
		},
		{
			"header": "libItemID",
			"index": 38,
			"value": "",
			"alias": "libItemID",
			"id": 41
		},
		{
			"header": "serviceLine",
			"index": 39,
			"value": "",
			"alias": "Service Line ID",
			"id": 42
		},
		{
			"header": "serviceLineName",
			"index": 40,
			"value": "",
			"alias": "Service Line Name",
			"id": 43
		},
		{
			"header": "serviceLineAbbr",
			"index": 41,
			"value": "",
			"alias": "Service Line Abbr.",
			"id": 44
		},
		{
			"header": "serviceLineTrade",
			"index": 42,
			"value": "",
			"alias": "Service Line Trade.",
			"id": 45
		},
		{
			"header": "union",
			"index": 43,
			"value": "",
			"alias": "Union",
			"id": 46
		},
		{
			"header": "jobItemCost",
			"index": 44,
			"value": "",
			"alias": "Job Item Cost",
			"id": 47
		},
		{
			"header": "officeId",
			"index": 45,
			"value": "",
			"alias": "Technician Office ID",
			"id": 48
		}, 
		{
			"header": "jobOfficeId",
			"index": 46,
			"value": "",
			"alias": "Job Office ID",
			"id": 49
		}, 
		{
			"header": "technicianTimezone",
			"index": 47,
			"value": "",
			"alias": "Technician Timezone",
			"id": 50
		}, 
		{
			"header": "splitClockPairSequence",
			"index": 48,
			"value": "",
			"alias": "Split Clock Pair Sequence",
			"id": 51
		}, 
		{
			"header": "jobName",
			"index": 49,
			"value": "",
			"alias": "Job Name",
			"id": 52
		},
		{
			"header": "QBD_JOB3",
			"index": 50,
			"value": "",
			"alias": "JOB 3 (Company : Location : Job Type + Job Number)",
			"id": 53
		},
		{
			"header": "QBD_JOB4",
			"index": 51,
			"value": "",
			"alias": "JOB 4 (Company : Location : Job Name)",
			"id": 54
		},
		{
			"header": "jobOffice",
			"index": 52,
			"value": "",
			"alias": "JOB Office",
			"id": 55
		},
	],
	"groupByItemCode": "UNGROUPED",
	"name": "[U] Labor",
	"type": "Labor",
};

export default labor.data;