import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import EditModalList from './editModalList';
import AddModalList from './addModalList';
import Header from './modalHeader';
import { requestHeaderConfig } from '../../utilities';

const EditModal = (props) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        props.dispatch({
            type: 'set_edit_mode',
            payload: false
        });
        props.dispatch({
            type: 'save_job_modal',
            payload: false
        });
    };

    const handleSave = async () => {
        await axiosInstance.post('/jobs', {
            companyId: props.login.user.company.id,
            userId: props.login.user.id,
            timezone: props.technician.timezone
          }, requestHeaderConfig(props.login.authToken)).then((response) => {
            props.dispatch({
                type: 'set_alert',
                payload: {
                  active: true,
                  message: 'Job Modified'
                }
            });
            props.setReload(props.count + 1);
            handleClose();
          }).catch((e) => {
              console.warn(e, 'Could not save Jobs');
          });
    }

    useEffect(() => {
        if (!open === props.modal.open) {
            setOpen(props.modal.open);
        }
    },[props.modal]);

    if (!open) { 
        return (
            <>
                
            </>
        )
    }

    return (
        <div>
            <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Use this form to perform an inline edit on the data.
                    </DialogContentText>
                    <Header/>
                    <EditModalList/>
                    <AddModalList/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(EditModal);