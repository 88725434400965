import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { convertDecimalToHoursToHourly, requestHeaderConfig } from '../../utilities'
import axiosInstance from '../../utilities/axios';

const useStyles = makeStyles((theme) => ({
    additionalRows: {
        maxWidth: 40,
        margin: '0 auto',
        textAlign: 'center'
    },
    '@global': {
        'div > input.MuiInputBase-input': {
            textAlign: 'center',
            fontSize: 14
        }
    },
    summary: {
        borderBottom: 'none',
        textAlign: 'center'
        // paddingRight: 9
    },
}));

const calculate = (rows) => {
    let total = rows ? rows.map((row) => {
        return row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat']
    }) : rows;

    if (Array.isArray(total) && total.length === 0) {
        return 0;
    } else {
        return total.reduce((total, value) => total + value)
    }
};

const DayCounter = (props) => {
    const classes = useStyles();
    let [value, setValue] = useState(props.row[props.day.day]);
    let [error, setError] = useState(false);
    let range = props.dateRanges.label;
    let timeCardType = props.timeCardType;
    let accountId = props.login.user.account.id;
    let userId = props.technician.id;
    let [isDisabled, setDisabled] = useState(false);
    let renderDecimalFormatClock = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;
    const format = {
        regex: /^\d+(\.\d{0,2})?$/
    }

    useEffect(() => {
        setDisabled(props.isProcessing)
    }, [props.isProcessing]);

    const handleDayChange = (row, target, el, shouldFormat) => {
        row[target.day] = +el.target.value;

        if (shouldFormat) {
            row[target.day] = +row[target.day].toFixed(2);
        }
        // do a check if not a number before saving
        var data = {
            range: range,
            timeCardType: timeCardType,
            userId: userId,
            id: row.id,
            day: target.day,
            value: +row[target.day],
            additionalRows: props.timeCardRows
        };
        props.dispatch({
            type: 'set_backdrop',
            payload: true
        });
        axiosInstance.post('/updateRow', data, requestHeaderConfig(props.login.authToken)).then(() => {
            props.dispatch({
                type: 'set_backdrop',
                payload: false
            });
            props.dispatch({
                type: 'set_grand_total',
                payload: calculate(props.timeCardRows)
            });
        }).catch((e) => {
            console.log(e, 'An error has occured while attempting to update day');
            props.dispatch({
                type: 'set_backdrop',
                payload: false
            });
        });
        props.dispatch({
            type: 'update_timecard_rows',
            payload: props.timeCardRows
        });

    }

    return (
        <TableCell align={props.readOnly ? 'right' : ''} className={props.readOnly ? classes.summary : ''} key={uuid()}>
            {props.readOnly && (
                <Typography>{renderDecimalFormatClock && props.timeCardType === 1
                    ? convertDecimalToHoursToHourly(value)
                    : value}
                </Typography>
            )}
            {!props.readOnly && (<form className={classes.additionalRows} noValidate autoComplete="off">
                <TextField error={error} className={classes.inputField} onChange={(el) => {
                    if (!format.regex.test(el.target.value) && isNaN(el.target.value)) {
                        setError(true)
                    }
                }} disabled={isDisabled} onBlur={(el) => {
                    if (format.regex.test(el.target.value)) {
                        handleDayChange(props.row, props.day, el);
                    } else if (!isNaN(el.target.value)) {
                        handleDayChange(props.row, props.day, el, true);
                    }
                }} defaultValue={renderDecimalFormatClock && props.timeCardType === 1
                    ? convertDecimalToHoursToHourly(value)
                    : value}inputProps={{ 'aria-label': 'additionalRow', 'min': '0', 'maxLength': '5' }} />
            </form>)}
        </TableCell>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(DayCounter);