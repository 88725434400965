import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { requestHeaderConfig } from '../../utilities';
import axiosInstance from '../../utilities/axios';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
    },
    isDefault: {
        marginLeft: 10,
        fontSize: 15
    },
    headIcon: {
        fontSize: 15,
        marginTop: 4
    },
    description: {
        alignItems: 'left'
    }
  }));


const Emails = (props) => {
    const classes = useStyles();
    let [model, setModel] = useState([]);

    const handleDelete = (row) => {
        axiosInstance.post('/settings', {
            companyId: props.login.user.company.id,
            key: 'emails',
            value: model.filter((email) => { return email.id !== row.id})
          }, requestHeaderConfig(props.login.authToken)).then(response => {
              let updatedSettingsObj = response.data;
              props.dispatch({
                  type: 'set_settings',
                  payload: updatedSettingsObj
              })
          }).catch((e) => {
              console.warn(e, 'Could not save settings for : Notification Emails');
          });
    };

    useEffect(() => {
        setModel(props.appSettings.emails);
    },[props.appSettings]);

    return (
        <>
            {model.map((row) => (
                <TableRow key={uuid()}>
                    <TableCell style={{width: '20%'}} component="th" scope="row">
                        {row.office}
                        {row.isDefault && (
                            <MailOutlineIcon className={classes.isDefault}/>
                        )}
                    </TableCell>
                    <TableCell align="left">
                        {row.email}
                        <IconButton aria-label="delete" className={classes.margin} onClick={e => handleDelete(row)}>
                        <DeleteIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(Emails);