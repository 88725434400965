import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import axiosInstance from '../../utilities/axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Time from '@material-ui/icons/AccessTime';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from "react-router-dom";
import AdditionalRowContainer from './additionalRowContainer';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { round, analytics, convertDecimalToHoursToHourly, translate, requestHeaderConfig } from '../../utilities';
import Summary from './summary';
import { formatDate } from '../utilities/dateUtilities'
import { SUBMISSION, SUBMIT_TIMECARD } from '../../utilities/constants/analytics';
import SubmitTimecardBtn from './submitTimecardBtn';


const useStyles = makeStyles((theme) => ({
    jobNumber: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: 'none'
    },
    editIcon: {
        fontSize: 15,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1),
    },
    additionalRows: {
        maxWidth: 30
    },
    comments: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    submit: {
        margin: 10
    },
    dateHeader: {
        textAlign: 'center'
    },
    timecardDay: {
        textAlign: 'center'
    },
    jobHeader: {
        minWidth: 140
    },
    time: {
        display: 'flex',
        marginTop: 10
    }
}));

const TimeCard = (props) => {
    const classes = useStyles();
    let model = props.tableRows;
    let week = props.appSettings.weekDayOrder;
    let [userComments, setUserComments] = useState("");
    let [mustBeCertified, setCertified] = useState(true);
    let [summaryExpanded, setSummaryExpanded] = React.useState(true);
    let [commentsExpanded, setCommentsExpanded] = React.useState(false);
    let [isLocked, setLocked] = useState(false);
    let uniqueJobItems = [];
    let additionalRows = props.appSettings.additionalRows
    let renderDecimalFormatClock = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;
    let navi = useHistory();
    let uniqueJobExternalIds = {};
    props.dispatch({
        type: 'set_application_page',
        payload: 'Submit a Timecard'
    });

    const isLaborItem = () => {
        return props.timeCardType === 0;
    }
    const canPerformEdit = () => {
        // if user has can submit permission, or is an ST Admin
        return props.login.canSubmit || props.login.isAdmin;
    }

    const collectTableRows = (tableRows, row) => {
        let allUnGroupedRows = [];
        let jobNumber = row.jobNumber;
        let jobItems = tableRows.filter((item) => item.jobNumber === jobNumber);
        for (let x = 0; x < jobItems.length; x++) {
            let item = jobItems[x];
            if (item._metadata) {
                let unGroupedRow = item._metadata;
                unGroupedRow.forEach((uItem) => {
                    allUnGroupedRows.push(uItem);
                });
            } else {
                allUnGroupedRows.push(item);
            }
        }

        return allUnGroupedRows;
    };

    const handleOpenEditModal = (row) => {
        props.dispatch({
            type: 'set_edit_mode',
            payload: {
                open: true,
                content: collectTableRows(props.tableRows, row)
            }
        })
    }

    const trimServiceLines = (userObj) => {
        delete userObj.serviceLines;
        delete userObj.roles;
        return userObj;
    };

    const getExternalId = (technician) => {
        let extId = '';
        console.log(technician, 'The technician')
        if (technician.externalId) {
            return technician.externalId;
        } else if (technician.externalIds) {
            extId = technician.externalIds;
            let values = Object.values(extId);
            if (values.length && values.filter(id => id !== '').length) {
                extId = values.filter(id => id !== '')[0];
            } 
        }
        return extId;
    }

    const getTechOfficeId = (technician) => {
        return technician.location.id
    }

    const getWageType = (login, techId) => {
        return login && login.userList && login.userList.length && login.userList.filter((user) => user.id === techId)[0] ? login.userList.filter((user) => user.id === techId)[0].wageType || '' : ''
    }
    const extractExternalIdValueById = (externalIds, id) => {
        let value = '';
        if (externalIds && externalIds.length && typeof externalIds !== 'string') {
            externalIds.forEach((idObj) => {
                if (idObj.id === id) {
                    value = idObj.value;
                }
            });
        }
        return value;
    }
    const mapExternalIdToModel = async (model) => {
        const listOfUniqueJobs = new Set();
        model.map((item) => {
            listOfUniqueJobs.add(item.jobId);
            if (item.serviceLine) {
                const serviceLine = props.serviceLines.find((service) => { return service.id === item.serviceLine});
                if (serviceLine && serviceLine.name && serviceLine.abbr && serviceLine.trade) {
                    item.serviceLineName = serviceLine.name;
                    item.serviceLineAbbr = serviceLine.abbr;
                    item.serviceLineTrade = serviceLine.trade;
                }
            }
            return item;
        });
        // for each unique job, get the external id with a promise all

        const externalIdPromises = [];
        listOfUniqueJobs.forEach((jobId) => {
            externalIdPromises.push(axiosInstance.post('/externalId', {
                job: jobId,
                system: props.appSettings.jobExternalSystem
            }, requestHeaderConfig(props.login.authToken)));
        });

        const externalIdResponses = await Promise.all(externalIdPromises);

        externalIdResponses.forEach((response) => {
            const jobId = response.data.job;
            const externalId = response.data.value;
            model.map((item) => {
                if (item.jobId === jobId) {
                    uniqueJobExternalIds[item.jobId] = externalId;
                    item.externalId = externalId;
                }
                return item;
            });
        });
        return model;
    }

    const mapExternalIdToUncombinedEntries = async (model) => {
        model.map((item) => {
            if (item.jobId) {
                item.externalId = uniqueJobExternalIds[item.jobId];
            }
            return item;
        });
        return model;
    }
    const handleSubmit = async () => {
        let userObj = { ...{}, ...props.login.user };
        delete userObj.activities;
        let typeNames = ['Labor Items', 'Clock Events', 'Expenses'];
        let payload = {
            companyId: props.login.user.company.id, //*
            userId: props.technician.id, //*
            model: model,
            startEpoch: props.dateRanges.startDay,
            endEpoch: props.dateRanges.endDay,
            startMoment: props.dateRanges.startMoment,
            endMoment: props.dateRanges.endMoment,
            range: props.dateRanges.label, //*
            type: props.timeCardType, // * Refactor, if an admin changes enabled types we need a hard value, not an int
            typeName: typeNames[props.timeCardType],
            status: "Submitted",
            techName: props.technician.name,
            techEmail: props.technician.email,
            currentUserId: userObj.id,
            currentUserTimezone: userObj.timezone,
            techOffice: props.technician.location.name,
            techLocation: props.technician.location,
            avatar: props.technician.avatar,
            comments: userComments.length > 0 ? [{ name: props.login.user.name, avatar: props.login.user.avatar.small, adminComment: props.login.canManage, msg: userComments, timestamp: moment().format('lll') }] : [],
            weekDates: props.dateRanges.weekDates,
            notificationEmails: props.appSettings.emails,
            officeId: getTechOfficeId(props.technician),
            actionBy: props.login.user.name,
            techExtId: getExternalId(props.technician),
            wageType: getWageType(props.login, props.technician.id),
            additionalRows: props.timeCardRows,
            unCombinedEntries: props.unCombinedEntries,
            technicianTimezone: props.technician.timezone
        };
        if (props.clockExports && props.clockExports.length) {
            payload.clockExports = props.clockExports;
        }
        props.dispatch({
            type: 'set_backdrop',
            payload: true
        });

        if (props.appSettings.jobExternalSystem && payload.model[0].jobNumber !== '') {
            payload.model = await mapExternalIdToModel(payload.model);
            if (payload.unCombinedEntries && payload.unCombinedEntries.length) {
                payload.unCombinedEntries = await mapExternalIdToUncombinedEntries(payload.unCombinedEntries);
            }
        }

        axiosInstance.post('/save', payload, requestHeaderConfig(props.login.authToken)).then(response => {
            props.dispatch({
                type: 'set_backdrop',
                payload: false
            });
            props.dispatch({
                type: 'set_alert',
                payload: {
                    active: true,
                    message: 'Timecard Successfully Submitted'
                }
            });
            props.dispatch({
                type: 'set_application_page',
                payload: 'Home'
            });
            if ((props.login.canSubmit || props.login.isAdmin)) {
                // Admin: Review Timecards
                navi.push('/timecards');
            } else {
                // Tech: My Timecards
                navi.push('/myTimecards');
            }
        }, requestHeaderConfig(props.login.authToken)).catch((e) => {
            console.warn(e, 'Could not save timecard');
            props.dispatch({
                type: 'set_backdrop',
                payload: false
            });
        });
    };
    const handleConfirm = () => {
        setCertified(!mustBeCertified);
    };

    const unique = (id) => {
        let isUnique = uniqueJobItems.indexOf(id) < 0;
        if (isUnique) {
            uniqueJobItems.push(id);
        }
        return isUnique;
    };

    useEffect(() => {
        if (isLocked !== props.isLocked.status) {
            setLocked(!isLocked);
        }
    }, [props.isLocked])

    useEffect(() => {
        // reset this to true each time the date range changes
        setCertified(true);
    }, [props.currentDateRange])

    if (!model || !week || !additionalRows) {
        return (
            <>
                <Container maxWidth="md" className={classes.container}>
                    <Grid item xs>
                        <Paper className={classes.paper}>
                            <h4 align="left">Loading</h4>
                            <CircularProgress />
                        </Paper>
                    </Grid>
                </Container>
            </>
        )
    }
    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="Timecard">
                    <TableHead>
                        <TableRow key={uuid()}>
                            <TableCell className={classes.jobHeader}>Job & Location </TableCell>
                            <TableCell>Item Code</TableCell>
                            {props.appSettings.weekDayOrder && props.appSettings.weekDayOrder.length > 0 ? props.appSettings.weekDayOrder.map((day, index) => (
                                <TableCell className={classes.dateHeader} key={uuid()}>{day.day} <br /><sub>{formatDate(props.dateRanges.weekDates[index]).slice(0, 5)}</sub></TableCell>
                            )) : null}
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props && props.tableRows ? props.tableRows.map((row) => (
                            <TableRow hover={true} className={classes.hoverSelect} key={uuid()}>
                                <TableCell>
                                    <Typography className={classes.jobNumber}>
                                        {unique(row.jobNumber) && row.jobNumber !== '' && (
                                            <>
                                                {props && props.appSettings && props.appSettings.jobItems && props.appSettings.jobItems.edit && isLaborItem() && canPerformEdit() && (
                                                        <IconButton onClick={() => {
                                                            handleOpenEditModal(row);
                                                        }} aria-label="Edit">
                                                            <EditIcon className={classes.editIcon} />
                                                        </IconButton>
                                                )}
                                                <Link href={row.link} target="_blank">
                                                    [ {row.jobNumber + "\n"} ] - <Chip label={translate(row.jobType || row.job_type, row)} size="small" />
                                                </Link>
                                                <Typography style={{ marginTop: 5 }} >{row.location}</Typography>
                                            </>
                                        )}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    {row.activity && (
                                        <>
                                            {row.activity}
                                        </>
                                    )}
                                    {!row.activity && (
                                        <>
                                            {row.itemCode}
                                        </>
                                    )}
                                </TableCell>
                                {row.week.map((day) => (
                                    <TableCell className={classes.timecardDay} key={uuid()}>
                                        {props.timeCardType === 1 && renderDecimalFormatClock 
                                        ? convertDecimalToHoursToHourly(round(day.value)) 
                                        : round(day.value)}
                                        
                                    </TableCell>
                                ))}
                                <TableCell align={'center'}>
                                {props.timeCardType === 1 && renderDecimalFormatClock 
                                        ? convertDecimalToHoursToHourly(round(row.weekTotal)) 
                                        : round(row.weekTotal)}
                                </TableCell>
                            </TableRow>
                        )) : null}
                        <AdditionalRowContainer />
                    </TableBody>
                </Table>
            </TableContainer>
            <Accordion className={classes.accordion} expanded={summaryExpanded} onChange={() => {
                summaryExpanded ? setSummaryExpanded(false) : setSummaryExpanded(true);
            }}>
                <AccordionSummary style={{ backgroundColor: "#FFFEF1" }} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography style={{ paddingRight: 4 }} className={classes.heading}>
                        <strong>View Summary:</strong>
                    </Typography>
                    {
                        summaryExpanded ?  <ExpandLessIcon /> : <ExpandMoreIcon /> 
                    }
                </AccordionSummary>
                <AccordionDetails>
                    <Summary />
                </AccordionDetails>
            </Accordion>

            {props.appSettings.comments.enabled && (
                <Accordion className={classes.accordion} expanded={commentsExpanded} onChange={() => {
                    commentsExpanded ? setCommentsExpanded(false) : setCommentsExpanded(true);
                }}>
                    <AccordionSummary style={{ backgroundColor: "#FFFEF1" }} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography style={{ paddingRight: 4 }} className={classes.heading}>
                            <strong>Add Comments:</strong>
                        </Typography>
                        {
                        commentsExpanded ?  <ExpandLessIcon /> : <ExpandMoreIcon /> 
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField className={classes.comments} onChange={(e) => { setUserComments(e.target.value) }} value={userComments} id="outlined-multiline-static" label="Comments" multiline rows={4} variant="outlined" />
                    </AccordionDetails>
                </Accordion>
            )}
                {!isLocked && (
                    <SubmitTimecardBtn handleSubmit={handleSubmit} handleConfirm={handleConfirm} mustBeCertified={mustBeCertified} setCertified={setCertified} range={props.dateRanges.label} type={props.timeCardType} appSettings={props.appSettings} login={props.login} technician={props.technician} timecardReleaseStatus={props.timecardReleaseStatus} />
                )}
                {isLocked && (
                    <Box className={classes.time}>
                        <Time></Time>
                        <Typography style={{ paddingRight: 4 }} className={classes.heading}>
                                <strong>Submitted on </strong>{props.isLocked.submittedOn}
                        </Typography>
                    </Box>
                )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(TimeCard);
