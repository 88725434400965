import axiosInstance from '../utilities/axios';
import { requestHeaderConfig } from '../utilities';
export const getUserImage = async (props, id) => {
    try {
        const response = await axiosInstance.get('/listUsers', {
            params: {
                companyId: props.login.user.company.id
              },
              ...requestHeaderConfig(props.login.authToken)
            })

        let user = response.data.userList.filter(user => user.id === id)
        return user[0].avatar.small
    } catch (error) {
        console.error(error)
    }
}


export const dateFormatter = (date) => {
    let newDate = new Date(date);
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear()
    let dateInMonth = newDate.getDate();
 
    if (String(month).length === 1)  month = '0' + String(month);
    if (String(dateInMonth).length === 1) dateInMonth = '0' + String(dateInMonth);
    return month + '/' + dateInMonth + '/' + year;

  }