import React from "react";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import Box from '@mui/material/Box'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://servicetrade.com/">
        ServiceTrade
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  }
}));


const ErrorForm = (props) => {
    const classes = useStyles();

    const handleLogout = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;
      const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
      const returnTo = process.env.REACT_APP_AUTH0_RETURN_TO;
      window.location.href = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`

      props.dispatch({
        type: 'exit'
      });
  }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    maxWidth: '400px',
                    margin: 'auto',
                    marginTop: '50px',
                    minHeight: '400px'
                }}
            >
            <div className={classes.paper}>
                <Typography component="h4" variant="h4">A valid subscription is required. Please contact your account manager. <a onClick={handleLogout}>Click here to try again.</a></Typography>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Box>
        </Container>
    );
}

const mapStateToProps = state => ({
  account: state
});

export default connect(mapStateToProps)(ErrorForm);
