import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const SelectEmail = (props) => {

    const onChange = (field) => {
        props.dispatch({
            type: 'set_selected_email',
            payload: field.target.value
        });
    }

    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <FormHelperText>Email</FormHelperText>
            <Select
                value={props.selectedEmail}
                label="Email"
                onChange={onChange}
            >
                {props.listOfAccountUsers.map((user) => {
                    return (<MenuItem key={user.email} value={user.email}>{user.name}</MenuItem>)
                })}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(SelectEmail);