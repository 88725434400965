import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconCheck from '@material-ui/icons/Check';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { v4 as uuid } from 'uuid';
import Import from './import';
import axiosInstance from '../../utilities/axios';
import ManageCheckbox from './manageCheckbox';
import SubmitCheckbox from './submitCheckbox';
import UnionCheckbox from './unionCheckbox';
import ManageApprovedTimecardsCheckbox from './manageApprovedTimecardsCheckbox';
import WageType from './wageType';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1),
    }
}));

const UserSettings = (props) => {
    const classes = useStyles();
    let [model, setModel] = useState(false) // set default state after login

    const hasFeature = (name) => {
        let features = props.appSettings.features;
        let hasFeature = false;
        if (features) {
            let feature = features.find((f) => { return f === name });
            if (!feature) {
                hasFeature = false;
            } else {
                hasFeature = true;
            }
        }
        return hasFeature;
    }

    useEffect(() => {
        axiosInstance.get('/listUsers', {
            params: {
                companyId: props.login.user.company.id
            },
            ...requestHeaderConfig(props.login.authToken)
        })
            .then(function (response) {
                if (response && response.data && response.data.userList) {
                    // When importing a fresh list from service trade, it overwrites the permissions.
                    // It also forces the admin to logout and log back in to make the redux store for props.login.userList to be updated.
                    props.dispatch({
                        type: 'set_users',
                        payload: response.data.userList
                    });
                    setModel(response.data.userList);
                } else {
                    setModel([]);
                }

            })
            .catch(function (error) {
                console.log(error, ': An error has occured while retrieving the settings');
            });
    }, [props.imported]);

    useEffect(()=> {
        setModel(props.listOfAccountUsers);
    }, [props.listOfAccountUsers]) 

    if (!model) {
        return (
            <></>
        )
    }

    return (
        <>
            <TableContainer>
                <Import />
                <Table size='small' className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Wage Type</TableCell>
                            <TableCell align="right">Is Helper</TableCell>
                            <TableCell align="right">Is Tech</TableCell>
                            {hasFeature('union') && (<TableCell align="right">Union</TableCell>)}
                            {hasFeature('admin_approval_required_to_request_changes') && props.login.isAdmin  && (<TableCell align="right">Manage Approved Timecards</TableCell>)}
                            <TableCell align="right">Manage All Timecards</TableCell>
                            <TableCell align="right">Manage Timecard Settings</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {model && model.map((row) => (
                            <TableRow key={uuid()}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <WageType row={row}/>
                                </TableCell>
                                <TableCell align="right">
                                    {row.isHelper && (
                                        <>
                                            <IconCheck />
                                        </>
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    {row.isTech && (
                                        <>
                                            <IconCheck />
                                        </>
                                    )}
                                </TableCell>
                                {hasFeature('union') && (<TableCell align="right">
                                    <FormControlLabel
                                        control={
                                            <UnionCheckbox row={row} />
                                        }
                                    />
                                </TableCell>)}
                                {hasFeature('admin_approval_required_to_request_changes') && props.login.isAdmin && (<TableCell align="right">
                                    <FormControlLabel
                                        control={
                                            <ManageApprovedTimecardsCheckbox row={row} />
                                        }
                                    />
                                </TableCell>)}
                                <TableCell align="right">
                                    <FormControlLabel
                                        control={
                                            <SubmitCheckbox row={row} />
                                        }
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <FormControlLabel
                                        control={
                                            <ManageCheckbox row={row} />
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(UserSettings);