export const NAVIGATION = 'NAVIGATION';
export const SUBMISSION = 'SUBMISSION';
export const CLICK_EVENT = 'CLICK_EVENT';
export const SUBMIT_TIMECARD_NAVIGATION_BUTTON = 'Clicked "Submit Timecard" button on Timecard Report screen';
export const REQUEST_DATA = 'REQUEST_DATA'
export const REQUEST_DATA_FAIL = 'REQUEST_DATA_FAIL'
export const REQUEST_COMPANY_DATA_FAIL = "REQUEST_COMPANY_DATA_FAIL"
export const SUBMIT_TIMECARD = 'Clicked "Submit" button on Timecard Entry screen';
export const SUBMIT_TIMECARD_EDIT = 'Clicked "Submit" button on Timecard Edit screen';
export const APPROVE_BUTTON = 'Clicked "Approve" Timecard button';
export const REQUEST_BUTTON = 'Clicked "Request Changes" Timecard button';
export const SELECT_TECHNICIAN = 'Selected a new technician';
export const REQUEST_TECH_SUBMISSION = 'REQUEST_TECH_SUBMISSION'
export const REQUEST_ADMIN_SUBMISSION = "REQUEST_ADMIN_SUBMISSION"
export const REQUEST_CLICK_SUBMISSIONS = "REQUEST_CLICK_SUBMISSIONS"
export const REQUEST_CLICK_NAVIGATION = "REQUEST_CLICK_NAVIGATION"
export const REQUEST_COMPANY_SUBMISSIONS = "REQUEST_COMPANY_SUBMISSIONS"
