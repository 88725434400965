
import React from 'react'
import {Bar} from 'react-chartjs-2'

const CompanyGraph = (props) => {

    return (
        <div>
               <Bar
                data={{
                    labels: Object.keys(props.dataObj),
                    datasets: [{
                        label: 'Admin Submissions',
                        data: Object.values(props.dataObj).map(item => item.adminSubmission),
                        backgroundColor: '#004680',
                        borderColor: '#004680',
                        borderWidth: 1,
                    }, {
                        label: "Tech Submissions",
                        data: Object.values(props.dataObj).map(item => item.techSubmission),
                        backgroundColor: '#FBB034',
                        borderColor: '#FBB034',
                        borderWidth: 1
                    }],  
                }}
                height={300}
                width={100}
                options={{
                    maintainAspectRatio: false,
                   
                }}
            />
     
        </div>
    )
}
 
export default CompanyGraph