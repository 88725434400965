import { useState } from 'react';
import { connect } from 'react-redux';
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import LaborIcon from '@mui/icons-material/Work';
import ClockEventsIcon from '@mui/icons-material/WatchLater';
import ExpensesIcon from '@mui/icons-material/Paid';
import { makeStyles } from '@material-ui/core/styles';
import ExportSelectedButton from './ExportSelectedButton';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
    fieldset: {
        width: 420,
        display: "flex",
        justifyContent: "space-evenly",
        height:40,
        paddingTop:5
    },
    primary: {
        color: '#004680'
    },
    secondary: {
        color: '#FBB034'
    }
}));

const QuickSearchToolbar = (props) => {
    const classes = useStyles();
    const isAdmin = props.login.canManage || props.login.isAdmin || false;
    const [showLabor, setShowLabor] = useState(false);
    const [showClock, setShowClock] = useState(false);
    const [showExpenses, setShowExpenses] = useState(false);

    const handleChangeLabor = () => {
        setShowLabor(!showLabor);
        props.dispatch({
            type: 'set_show_unsubmitted_labor',
            payload: !showLabor
        });
    }
    const handleChangeClock = () => {
        setShowClock(!showClock);
        props.dispatch({
            type: 'set_show_unsubmitted_clock',
            payload: !showClock
        });
    }
    const handleChangeExpenses = () => {
        setShowExpenses(!showExpenses)
        props.dispatch({
            type: 'set_show_unsubmitted_expenses',
            payload: !showExpenses
        });
    }
    return (
        <Stack style={{ padding: 10 }} spacing={{ xs: 6, sm: 6, md: 6 }} justifyContent="space-between" direction="row" spacing={3}>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search By Name…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            onClick={props.clearSearch}
                        >
                            {props.value && <ClearIcon fontSize="small" />}
                        </IconButton>
                    ),
                }}

            />
            {isAdmin && (<div className={classes.fieldset}>
                <p>Show Unsubmitted: </p>
                <LaborIcon className={showLabor ? classes.secondary : classes.primary} tooltip={'Labor'} onClick={handleChangeLabor}></LaborIcon>
                <ClockEventsIcon className={showClock ? classes.secondary : classes.primary} tooltip={'Clock Events'} onClick={handleChangeClock}></ClockEventsIcon>
                <ExpensesIcon className={showExpenses ? classes.secondary : classes.primary} tooltip={'Expenses'} onClick={handleChangeExpenses}></ExpensesIcon>
            </div>)}
        <ExportSelectedButton />
        </Stack>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(QuickSearchToolbar);