import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store, persistor } from './utilities/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Auth0Provider } from '@auth0/auth0-react';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#004680"
        },
        secondary: {
            main: "#FBB034"
        }
    }
})


ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    >
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme = {theme}>
                    <App />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </Auth0Provider>, document.getElementById('root'));
