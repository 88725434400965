import axios from 'axios';
import { handleLogout } from '../utilities';

const axiosInstance = axios.create({
    baseURL: '/api'
});

axiosInstance.interceptors.response.use(
    response => {
        // a positive response. no need to do anything
        return response;
    }, error => {
        if (error.response) {
            const status = error.response.status;
            if (status >= 400 && status < 500) {
                if (status === 401 && error?.response?.data && error?.response?.data?.redirectUrl) {
                    handleLogout(error.response.data.redirectUrl)
                }
            } else if (status >= 500) {
                // on 5xx consider redirecting the user to a page that collects the error and reports on it
            }
        } else if (error.request) {
            // an error with the request that fails to receive a reply from the server should also be collected and reported
        } else {
            // Perhaps unlikely to reach here, but the error should be collected and reported.
        }
        return Promise.reject(error);
    }
)
export default axiosInstance;