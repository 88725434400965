import { convertDecimalToHoursToHourly, translate } from '../utilities';
import {dateFormatter} from '../actions/utilities';
import moment from 'moment';

const hasFeature = (name, appSettings) => {
    let features = appSettings.features;
    let hasFeature = false;
    if (features) {
        let feature = features.find((f) => { return f === name });
        if (!feature) {
            hasFeature = false;
        } else {
            hasFeature = true;
        }
    }
    return hasFeature;
}

const getWeekStartDayUTC = (calendarStartDay, startMoment) => {
    let days = [];

    // collect the last 7 days
    for (let x = 0; x < 7; x++) {
        days.push(moment().subtract(x, 'days'));
    }

    for (let y = 0; y < 7; y++) {
        // when the most recent calendar start day is found, this is our week start
        if (days[y].toString().split(' ')[0] === calendarStartDay) {
            startMoment = days[y];
        }
    }

    return startMoment.format('MM/DD/YYYY');
};

export const handleItemCodeHeaders = (view, laborDefault) => {
    let header = laborDefault.data.map((item) => {
        return {
            label: item.alias,
            key: item.header
        }
    })

    return header
}


export const handleItemCodeFormat = (view, laborDefault) => {
    let csvData = []
    let data = {}
    let total = 0
    let dataSelected = laborDefault.data.map((item) => item.header)
    let totalSelected = laborDefault.data.find(x => x.header === 'total');

    for (let item of laborDefault.data) {
        switch (item.header) {
            // Any column not represented here must be hidden on template column selection when grouped
            case "technician_name":
                data[item.header] = view.techName
                break;
            case "technician_id":
                data[item.header] = view.userId
                break;
            case "technician_email":
                data[item.header] = view.techEmail
                break;
            case "tech_office":
                data[item.header] = view.techOffice
                break;
            case "actionBy":
                data[item.header] = view.actionBy
                break;
            case "techExtId":
                data[item.header] = view.techExtId
                if (typeof data[item.header] === 'object') {
                    data[item.header] = Object.values(data[item.header])[0]
                }
                if (data[item.header] === '' && view.userList) {
                    let latestUserData = view.userList.find((user) => view.userId === user.id);
                    data[item.header] = latestUserData ? latestUserData.externalId : '';
                }
                break;
            default:
                break;
        }
    }

    for (let item of view.model) {
        if (dataSelected.includes(item.itemCode)) {

            if (data[item.itemCode] === undefined) {
                data[item.itemCode] = item.weekTotal
                total += item.weekTotal
            } else {
                data[item.itemCode] += item.weekTotal
                total += item.weekTotal
            }
        }
    }

    if (view.additionalRows) {
        for (let row of view.additionalRows) {
            if (dataSelected.includes(row.label)) {
                data[row.label] = row.Sun + row.Mon + row.Tue + row.Wed + row.Thu + row.Fri + row.Sat
                total += row.Sun + row.Mon + row.Tue + row.Wed + row.Thu + row.Fri + row.Sat
            }


        }
    }

    if (totalSelected) {
        data[totalSelected.header] = total
    }

    csvData.push(data)
    return csvData
}


//  ******* CLOCK EVENT FUNCTIONS *************** //
export const handleClockHeaders = (view, clockDefault) => {
    let header = clockDefault.data.map((item) => {
        return {
            label: item.alias,
            key: item.header
        }
    })

    return header
}

export const groupClockEventData = (view, clockDefault, decimalFormatEnabled) => {
    let dataSelected = clockDefault.data.map((item) => item.header)
    let totalSelected = clockDefault.data.find(x => x.header === 'total');
    let csvData = []
    let data = {}
    let total = 0

    for (let item of clockDefault.data) {
        switch (item.header) {
            case "technician_name":
                data[item.header] = view.techName
                break;
            case "technician_id":
                data[item.header] = view.userId
                break;
            case "technician_email":
                data[item.header] = view.techEmail
                break;
            case 'companyId':
                data[item.header] = view.companyId
                break;
            case "tech_office":
                data[item.header] = view.techOffice
                break;
            case "actionBy":
                data[item.header] = view.actionBy
                break;
            case "techExtId":
                data[item.header] = view.techExtId
                if (typeof data[item.header] === 'object') {
                    data[item.header] = Object.values(data[item.header])[0]
                }
                if (data[item.header] === '' && view.userList) {
                    let latestUserData = view.userList.find((user) => view.userId === user.id);
                    data[item.header] = latestUserData ? latestUserData.externalId : '';
                }
                break;
            default:
                break;
        }
    }

    for (let item of view.model) {
        if (dataSelected.includes(item.activity)) {
            if (data[item.activity] === undefined) {
                data[item.activity] = item.weekTotal
                total += item.weekTotal
            } else {
                data[item.activity] += item.weekTotal
                total += item.weekTotal
            }
        }
    }

    if (view.additionalRows) {
        for (let row of view.additionalRows) {
            if (dataSelected.includes(row.label)) {

                data[row.label] = row.Sun + row.Mon + row.Tue + row.Wed + row.Thu + row.Fri + row.Sat
                total += row.Sun + row.Mon + row.Tue + row.Wed + row.Thu + row.Fri + row.Sat
            }
        }
    }

    if (totalSelected) {
        data[totalSelected.header] = total
    }

    if (decimalFormatEnabled) {
        for (let item in data) {
            let value = data[item]
            if (typeof (value) === 'number' && item !== "technician_id") {
                data[item] = convertDecimalToHoursToHourly(value);
            }
        }
    }

    csvData.push(data)
    return csvData

}



export const handleCSVFormat = (view, header, data, decimalFormatEnabled) => {
    let csvData = [];
    const appSettings = view.appSettings;
    var grandTotals = {
        Sun: 0,
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
    }
    const getDefaultWeekDay = (startDay) => {
        var weekday = [{
            day: "Sun",
            value: 0
        }, {
            day: "Mon",
            value: 0
        }, {
            day: "Tue",
            value: 0
        }, {
            day: "Wed",
            value: 0
        }, {
            day: "Thu",
            value: 0
        }, {
            day: "Fri",
            value: 0
        }, {
            day: "Sat",
            value: 0
        }];
        // set the order of the week to match the set start day
        weekday.push.apply(weekday, weekday.splice(0, weekday.findIndex((week) => week.day === startDay)));
        return weekday;
    }
    let weekDay = getDefaultWeekDay(appSettings.startDay);
    switch (view.typeName) {
        case 'Clock Events':
            let defaultModel = view.model;
            let exportModel = view.clockExports;
            let switchModel = '';
            if (view.clockExports && view.clockExports.length) {
                switchModel = exportModel;
            } else {
                switchModel = defaultModel;
            }
            switchModel.forEach((item) => {
                let csvRow = data.map((val) => {
                    switch (val.header) {
                        case "activity":
                            val.value = item.activity
                            break;
                        case "technicianTimezone":
                            val.value = view.technicianTimezone
                            break;
                        case "splitClockPairSequence": 
                            val.value = item.splitClockPairSequence
                            break;
                        case "officeId": 
                            val.value = view.officeId
                            break;
                        case "jobOfficeId": 
                            val.value = item.jobOfficeId
                            break;
                        case "actionBy":
                            val.value = view.actionBy
                            break;
                        case "timecardId":
                            val.value = view._id
                            break;
                        case "location":
                            val.value = item.location
                            break;
                        case "companyName":
                            val.value = item.companyName
                            break;
                        case "technician_name":
                            val.value = view.techName
                            break;
                        case "technician_email":
                            val.value = view.techEmail
                            break;
                        case "jobType":
                        case "job_type":
                            val.value = item.jobType || item.job_type
                            break;
                        case "jobName":
                            val.value = item.jobName
                            break;
                        case "jobOffice":
                            val.value = item?.jobOffice?.name || "";
                            break;
                        case "companyId":
                            val.value = view.companyId
                            break
                        case "jobNumber":
                            val.value = item.jobNumber
                            break;
                        case "technician_id":
                            val.value = item.userId || view.userId || item.technician_id
                            break;
                        case "Date":
                            val.value = dateFormatter((item.usedOn || item.eventTime) * 1000) // turn item.usedOn into millseconds
                            break;
                        case "TIMEACT":
                            val.value = "TIMEACT"
                            break;
                        case "Item (quickbooks desktop)":
                            val.value = "Labor"
                            break;
                        case "PITEM (quickbooks desktop)":
                            val.value = ""
                            break;
                        case "XFERTOPAYROLL (quickbooks desktop)":
                            val.value = "N"
                            break;
                        case "total":
                            val.value = item.weekTotal.toFixed(2);
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "BillingStatus (quickbooks desktop)":
                            val.value = 0
                            break;
                        case "BLANK":
                            val.value = ""
                            break;
                        case "BLANK2":
                            val.value = ""
                            break;
                        case "EMP":
                            val.value = view.techName.split(' ').reverse().join(',')
                            break;
                        case "QBD_JOB":
                            val.value = item.companyName + ":" + item.location
                            break;
                        case "item_code":
                            val.value = item.itemCode
                            break;
                        case "item_cost":
                            val.value = item.libItemCost
                            break;
                        case "item_price":
                            val.value = item.libItemPrice
                            break;
                        case "item_taxable":
                            val.value = item.libItemTaxable
                            break;
                        case "tech_office":
                            val.value = view.techOffice
                            break;
                        case "usedOn":
                            val.value = moment((item.usedOn || item.eventTime) * 1000).format('MM/DD/YYYY');
                            break;
                        case "quantity":
                            val.value = item.quantity
                            break;
                        case "jobItemCost":
                            val.value = item.jobItemCost
                            break;
                        case "externalId":
                            if (Array.isArray(item.externalId) && item.externalId.length ) {
                                item.externalId = item.externalId.find((ext) => { 
                                    return ext.id === view.appSettings.jobExternalSystem
                                })
                                if (item.externalId && item.externalId.value) {
                                    item.externalId = item.externalId.value
                                }
                            }
                            if (item && item.externalId && item.externalId.value && item.externalId.value !== '') {
                                val.value = item.externalId.value || ''
                            } else {
                                val.value = item.externalId || ''
                            }
                            break;
                        case "begin_date":
                            val.value = getWeekStartDayUTC(appSettings.startDay, moment((item.usedOn || item.eventTime)  * 1000))
                            break;
                        case "locationCompanyId":
                            val.value = item.locationCompanyId || '';
                            break;
                        case "techExtId":
                            val.value = view.techExtId || '';
                            if (typeof val.value === 'object') {
                                val.value = Object.values(val.value)[0]
                            }
                            // if the tech was not refreshed in TC after adding the extId and after the TC submission, get the latest value on the user object
                            if (val.value === '') {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.externalId : '';
                            }
                            break;
                        case "union":
                            val.value = view.union || '';
                            if (typeof val.value === 'object') {
                                val.value = Object.values(val.value)[0]
                            }
                            if (val.value === '' && view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? (latestUserData.isUnion ? "Yes" : "No") : '';
                            }
                            break;
                        case "wageType":
                            val.value = view.wageType || '';
                            if (view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.wageType : view.wageType;
                            }
                            break;
                        case "locationId":
                            val.value = item.locationOfficeId || '';
                            break;
                        case "clockInTimestamp":
                            val.value = item.clockInTimestamp
                            break;
                        case "clockOutTimestamp":
                            val.value = item.clockOutTimestamp
                            break;
                        case "clockInID":
                            val.value = item.clockInID
                            break;
                        case "clockOutID":
                            val.value = item.clockOutID
                            break;
                        case "calendarStartDay":
                            val.value = view.calendarStartDay || view.appSettings.startDay
                            break;
                        case "approvedTimestamp":
                            val.value = view.approvedTimestamp
                            break;
                        case "week_start":
                            val.value = view.startMoment;
                            break;
                        case "week_end":
                            val.value = view.endMoment;
                            break;
                        case "libItemID":
                            val.value = item.libItemId;
                            break;
                        case "jobprep":
                            val.value = item.activity === val.header ? item.weekTotal : '';
                            if (decimalFormatEnabled && item.activity === val.header) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "enroute":
                            val.value = item.activity === val.header ? item.weekTotal : '';
                            if (decimalFormatEnabled && item.activity === val.header) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "onsite":
                            val.value = item.activity === val.header ? item.weekTotal : '';
                            if (decimalFormatEnabled && item.activity === val.header) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "onbreak":
                            val.value = item.activity === val.header ? item.weekTotal : '';
                            if (decimalFormatEnabled && item.activity === val.header) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Sunday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Sun';
                            }).value.toFixed(2);
                            grandTotals['Sun'] = grandTotals['Sun'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Monday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Mon';
                            }).value.toFixed(2);
                            grandTotals['Mon'] = grandTotals['Mon'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Tuesday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Tue';
                            }).value.toFixed(2);
                            grandTotals['Tue'] = grandTotals['Tue'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Wednesday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Wed';
                            }).value.toFixed(2);
                            grandTotals['Wed'] = grandTotals['Wed'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Thursday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Thu';
                            }).value.toFixed(2);
                            grandTotals['Thu'] = grandTotals['Thu'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Friday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Fri';
                            }).value.toFixed(2);
                            grandTotals['Fri'] = grandTotals['Fri'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        case "Saturday":
                            val.value = item.week.find((wObj) => {
                                return wObj.day === 'Sat';
                            }).value.toFixed(2);
                            grandTotals['Sat'] = grandTotals['Sat'] + val.value
                            if (decimalFormatEnabled) { val.value = convertDecimalToHoursToHourly(val.value) }
                            break;
                        default:
                            break;
                    }
                    return val.value
                })

                if (decimalFormatEnabled) {
                    csvRow.push(convertDecimalToHoursToHourly(item.weekTotal))
                } else {
                    csvRow.push(item.weekTotal.toFixed(2))
                }

                csvData.push(csvRow)
            });
            // For additional rows, the format / order of each day must match the job. Add a weektotal for the timecard
            if (view.additionalRows) {
                view.additionalRows.forEach((item) => {
                    let csvRow = [];
                    if (header.length > 3) {
                        for (let i = 3; i < header.length; i++) {
                            csvRow.push('');
                        }
                    }
                    csvRow.push("");
                    csvRow.push("");
                    csvRow.push(item.label);
                    for (let y = 0; y < weekDay.length; y++) {
                        if (decimalFormatEnabled) {
                            csvRow.push(convertDecimalToHoursToHourly(item[weekDay[y].day]));
                        } else {
                            csvRow.push(item[weekDay[y].day]);
                        }
                    }

                    if (decimalFormatEnabled) {
                        csvRow.push(convertDecimalToHoursToHourly(item.Sun + item.Mon + item.Tue + item.Wed + item.Thu + item.Fri + item.Sat))
                    } else {
                        csvRow.push(item.Sun + item.Mon + item.Tue + item.Wed + item.Thu + item.Fri + item.Sat)
                    }

                    csvData.push(csvRow);
                    grandTotals['Sun'] = grandTotals['Sun'] + item.Sun;
                    grandTotals['Mon'] = grandTotals['Mon'] + item.Mon;
                    grandTotals['Tue'] = grandTotals['Tue'] + item.Tue;
                    grandTotals['Wed'] = grandTotals['Wed'] + item.Wed;
                    grandTotals['Thu'] = grandTotals['Thu'] + item.Thu;
                    grandTotals['Fri'] = grandTotals['Fri'] + item.Fri;
                    grandTotals['Sat'] = grandTotals['Sat'] + item.Sat;
                });
            }
            break;
        case 'Labor Items':
            let records = hasFeature('unCombined', appSettings) && view.unCombinedEntries ? 'unCombinedEntries' : 'model';
            let itemCodeTotals = {};

            const getItemCodeGrandTotals = (itemCodes, item, day, value) => {
                if (Object.keys(itemCodes).length === 0) {
                    itemCodes[item.itemCode] = {};
                    itemCodes[item.itemCode][day] = 0;
                    itemCodes[item.itemCode]['grand'] = 0;
                } else if (Object.keys(itemCodes).length !== 0 && !itemCodes[item.itemCode]) {
                    itemCodes[item.itemCode] = {};
                    itemCodes[item.itemCode][day] = 0;
                    itemCodes[item.itemCode]['grand'] = 0;
                } else if (!itemCodes[item.itemCode][day] && itemCodes[item.itemCode][day] !== 0) {
                    itemCodes[item.itemCode][day] = 0;
                } else {
                    itemCodes[item.itemCode][day] = itemCodes[item.itemCode][day] + value;
                    itemCodes[item.itemCode]['grand'] = itemCodes[item.itemCode]['grand'] + value;
                }
            }
            if (data.filter(item => item.header === 'TIMEACT').length >= 1 ) data = data.reverse().slice(7).reverse();
            
            view[records].forEach((item) => {
                let csvRow = data.map((val) => {

                    switch (val.header) {
                        case "location":
                            val.value = item.location
                            break;
                        case "technicianTimezone":
                            val.value = view.technicianTimezone
                            break;
                        case "officeId": 
                            val.value = view.officeId
                            break;
                        case "jobOfficeId": 
                            val.value = item.jobOfficeId
                            break;
                        case "actionBy":
                            val.value = view.actionBy
                            break;
                        case "timecardId":
                            val.value = view._id
                            break;
                        case "companyName":
                            val.value = item.companyName
                            break;
                        case "technician_name":
                            val.value = view.techName
                            break;
                        case "technician_email":
                            val.value = view.techEmail
                            break;
                        case "jobType":
                        case "job_type":
                            val.value = item.jobType || item.job_type
                            break;
                        case "companyId":
                            val.value = view.companyId
                            break;
                        case "jobNumber":
                            val.value = item.jobNumber
                            break;
                        case "jobName":
                            val.value = item.jobName
                            break;
                        case "jobOffice":
                            val.value = item?.jobOffice?.name || ""
                            break;
                        case "technician_id":
                            val.value = item.userId || item.technician_id
                            break;
                        case "Date":
                            val.value = dateFormatter(item.usedOn * 1000) // turn item.usedOn into millseconds
                            break;
                        case "TIMEACT":
                            val.value = "TIMEACT"
                            break;
                        case "Item (quickbooks desktop)":
                            val.value = "Labor"
                            break;
                        case "PITEM (quickbooks desktop)":
                            val.value = ""
                            break;
                        case "XFERTOPAYROLL (quickbooks desktop)":
                            val.value = "N"
                            break;
                        case "Total":
                            break;
                        case "total":
                            if (hasFeature('unCombined', appSettings)) {
                                val.value = item.quantity;
                            } else {
                                val.value = item.weekTotal;
                            }
                            break;
                        case "BillingStatus (quickbooks desktop)":
                            val.value = 0
                            break;
                        case "BLANK":
                            val.value = ""
                            break;
                        case "BLANK2":
                            val.value = ""
                            break;
                        case "EMP":
                            val.value = view.techName.split(' ').reverse().join(',')
                            break;
                        case "QBD_JOB":
                            val.value = item.companyName + ":" + item.location
                            break;
                        case "item_code":
                            val.value = item.itemCode
                            if (!itemCodeTotals[item.itemCode]) {
                                itemCodeTotals[item.itemCode] = {
                                    label: item.itemCode,
                                    Sun: 0,
                                    Mon: 0,
                                    Tue: 0, 
                                    Wed: 0, 
                                    Thu: 0,
                                    Fri: 0,
                                    Sat: 0,
                                    grand: 0
                                };
                            }
                            break;
                        case "item_cost":
                            val.value = item.libItemCost
                            break;
                        case "item_price":
                            val.value = item.libItemPrice
                            break;
                        case "item_taxable":
                            val.value = item.libItemTaxable
                            break;
                        case "tech_office":
                            val.value = view.techOffice
                            break;
                        case "usedOn":
                            if (item.metaInfo && item.metaInfo.entryUsedOn) {
                                val.value = moment(item.metaInfo.entryUsedOn * 1000).format('MM/DD/YYYY');
                            } else {
                                val.value = moment(item.usedOn * 1000).format('MM/DD/YYYY');
                            }
                            break;
                        case "quantity": // convert the job type from machine to friendly in export
                            val.value = item.quantity
                            break;
                        case "jobItemCost":
                            val.value = item.jobItemCost
                            break;
                        case "externalId":
                            if (Array.isArray(item.externalId) && item.externalId.length ) {
                                item.externalId = item.externalId.find((ext) => { 
                                    return ext.id === view.appSettings.jobExternalSystem
                                })
                                if (item.externalId && item.externalId.value) {
                                    item.externalId = item.externalId.value
                                }
                            }
                            if (item && item.externalId && item.externalId.value && item.externalId.value !== '') {
                                val.value = item.externalId.value || ''
                            } else {
                                val.value = item.externalId || ''
                            } 
                            break;
                        case "begin_date":
                            val.value = getWeekStartDayUTC(appSettings.startDay, moment(item.usedOn * 1000))
                            break;
                        case "locationCompanyId":
                            val.value = item.locationCompanyId || '';
                            break;
                        case "techExtId":
                            val.value = view.techExtId || '';
                            if (typeof val.value === 'object') {
                                val.value = Object.values(val.value)[0]
                            }
                            if (val.value === '' && view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.externalId : '';
                            }
                            break;
                        case "union":
                            val.value = view.union || '';
                            if (typeof val.value === 'object') {
                                val.value = Object.values(val.value)[0]
                            }
                            if (val.value === '' && view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? (latestUserData.isUnion ? "Yes" : "No") : '';
                            }
                            break;
                        case "wageType":
                            val.value = view.wageType || '';
                            if (view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.wageType : view.wageType;
                            }
                            break;
                        case "locationId":
                            val.value = item.locationOfficeId || '';
                            break;
                        case "clockInTimestamp":
                            val.value = view.clockInTimestamp
                            break;
                        case "clockOutTimestamp":
                            val.value = view.clockOutTimestamp
                            break;
                        case "clockInID":
                            val.value = view.clockInID
                            break;
                        case "clockOutID":
                            val.value = view.clockOutID
                            break;
                        case "calendarStartDay":
                            val.value = view.calendarStartDay || view.appSettings.startDay;
                            break;
                        case "approvedTimestamp":
                            val.value = view.approvedTimestamp
                            break;
                        case "week_start":
                            val.value = view.startMoment;
                            break;
                        case "week_end":
                            val.value = view.endMoment;
                            break;
                        case "libItemID":
                            val.value = item.libItemId;
                            break;
                        case "serviceLine":
                            val.value = item.serviceLine;
                            break;
                        case "serviceLineName":
                            val.value = item.serviceLineName;
                            break;
                        case "serviceLineAbbr":
                            val.value = item.serviceLineAbbr;
                            break;
                        case "serviceLineTrade":
                            val.value = item.serviceLineTrade;
                            break;
                        case "Sunday":
                            if (hasFeature('unCombined', appSettings) && item.unGroupedWeek && item.unGroupedWeek.length) {
                                val.value = item.unGroupedWeek.find((wObj) => {
                                    return wObj.day === 'Sun';
                                }).value;
                            } else {
                                val.value = item.week.find((wObj) => {
                                    return wObj.day === 'Sun';
                                }).value;
                            }

                            getItemCodeGrandTotals(itemCodeTotals, item, 'Sun', val.value)
                            grandTotals['Sun'] = grandTotals['Sun'] + val.value
                            break;
                        case "Monday":
                            if (hasFeature('unCombined', appSettings) && item.unGroupedWeek && item.unGroupedWeek.length) {
                                val.value = item.unGroupedWeek.find((wObj) => {
                                    return wObj.day === 'Mon';
                                }).value;
                            } else {
                                val.value = item.week.find((wObj) => {
                                    return wObj.day === 'Mon';
                                }).value;
                            }
                            getItemCodeGrandTotals(itemCodeTotals, item, 'Mon', val.value)
                            grandTotals['Mon'] = grandTotals['Mon'] + val.value
                            break;
                        case "Tuesday":
                            if (hasFeature('unCombined', appSettings) && item.unGroupedWeek && item.unGroupedWeek.length) {
                                val.value = item.unGroupedWeek.find((wObj) => {
                                    return wObj.day === 'Tue';
                                }).value;
                            } else {
                                val.value = item.week.find((wObj) => {
                                    return wObj.day === 'Tue';
                                }).value;
                            }
                            getItemCodeGrandTotals(itemCodeTotals, item, 'Tue', val.value)
                            grandTotals['Tue'] = grandTotals['Tue'] + val.value
                            break;
                        case "Wednesday":
                            if (hasFeature('unCombined', appSettings) && item.unGroupedWeek && item.unGroupedWeek.length) {
                                val.value = item.unGroupedWeek.find((wObj) => {
                                    return wObj.day === 'Wed';
                                }).value;
                            } else {
                                val.value = item.week.find((wObj) => {
                                    return wObj.day === 'Wed';
                                }).value;
                            }
                            getItemCodeGrandTotals(itemCodeTotals, item, 'Wed', val.value)
                            grandTotals['Wed'] = grandTotals['Wed'] + val.value
                            break;
                        case "Thursday":
                            if (hasFeature('unCombined', appSettings) && item.unGroupedWeek && item.unGroupedWeek.length) {
                                val.value = item.unGroupedWeek.find((wObj) => {
                                    return wObj.day === 'Thu';
                                }).value;
                            } else {
                                val.value = item.week.find((wObj) => {
                                    return wObj.day === 'Thu';
                                }).value;
                            }
                            getItemCodeGrandTotals(itemCodeTotals, item, 'Thu', val.value)
                            grandTotals['Thu'] = grandTotals['Thu'] + val.value
                            break;
                        case "Friday":
                            if (hasFeature('unCombined', appSettings) && item.unGroupedWeek && item.unGroupedWeek.length) {
                                val.value = item.unGroupedWeek.find((wObj) => {
                                    return wObj.day === 'Fri';
                                }).value;
                            } else {
                                val.value = item.week.find((wObj) => {
                                    return wObj.day === 'Fri';
                                }).value;
                            }
                            getItemCodeGrandTotals(itemCodeTotals, item, 'Fri', val.value)
                            grandTotals['Fri'] = grandTotals['Fri'] + val.value
                            break;
                        case "Saturday":
                            if (hasFeature('unCombined', appSettings) && item.unGroupedWeek && item.unGroupedWeek.length) {
                                val.value = item.unGroupedWeek.find((wObj) => {
                                    return wObj.day === 'Sat';
                                }).value;
                            } else {
                                val.value = item.week.find((wObj) => {
                                    return wObj.day === 'Sat';
                                }).value;
                            }
                            getItemCodeGrandTotals(itemCodeTotals, item, 'Sat', val.value)
                            grandTotals['Sat'] = grandTotals['Sat'] + val.value
                            break;
                        default:
                    }
                    return val.value
                })

                if (data.filter(item => item.header === 'TIMEACT').length < 1  ) {
                    if (hasFeature('unCombined', appSettings)) {
                        csvRow.push(item.quantity);
                    } else {
                        csvRow.push(item.weekTotal);
                    }
                }
                csvData.push(csvRow);
            });

            // For additional rows, the format / order of each day must match the job. Add a weektotal for the timecard
            if (view.additionalRows && data.filter(item => item.header === 'TIMEACT').length < 1) {
                view.additionalRows.forEach((item) => {
                    let csvRow = [];
                    if (header.length >= 3) {
                        for (let i = 2; i < header.length; i++) {
                            csvRow.push('');
                        }
                    }
                    if (!view[records] || !view[records].length) {
                        csvRow[1] = view.userId;
                        csvRow[2] = view.techName;
                    }
                    csvRow.push('Totals');
                    csvRow.push(item.label);
                    for (let y = 0; y < weekDay.length; y++) {
                        csvRow.push(item[weekDay[y].day]);
                    }
                    csvRow.push(item.Sun + item.Mon + item.Tue + item.Wed + item.Thu + item.Fri + item.Sat)
                    csvData.push(csvRow);

                    grandTotals['Sun'] = grandTotals['Sun'] + item.Sun;
                    grandTotals['Mon'] = grandTotals['Mon'] + item.Mon;
                    grandTotals['Tue'] = grandTotals['Tue'] + item.Tue;
                    grandTotals['Wed'] = grandTotals['Wed'] + item.Wed;
                    grandTotals['Thu'] = grandTotals['Thu'] + item.Thu;
                    grandTotals['Fri'] = grandTotals['Fri'] + item.Fri;
                    grandTotals['Sat'] = grandTotals['Sat'] + item.Sat;
                });
                
                if (view.appSettings && view.appSettings.jobItems && view.appSettings.jobItems.itemCodeSummary) {
                    for (const [key, value] of Object.entries(itemCodeTotals)) {
                        let csvRow = [];
                        if (header.length >= 3) {
                            for (let i = 2; i < header.length; i++) {
                                csvRow.push('');
                            }
                        }
                        csvRow.push('Totals');
                        csvRow.push(value.label);
                        for (let y = 0; y < weekDay.length; y++) {
                            csvRow.push(value[weekDay[y].day]);
                        }
                        csvRow.push(value.grand);
                        csvData.push(csvRow);
                    }
                }
            }
            break;

        case 'Expenses':
            csvData.push(header)
            view.model.forEach((item) => {
                let csvRow = [];
                csvRow.push(item.jobNumber);
                csvRow.push(item.userId || view.userId)
                csvRow.push(view.techName);
                csvRow.push(item.location);
                csvRow.push(item.itemCode);
                item.week.forEach((day) => {
                    csvRow.push(day.value);
                    grandTotals[day.day] = grandTotals[day.day] + day.value;
                })
                csvRow.push(item.weekTotal)
                csvData.push(csvRow);
            });
            // For additional rows, the format / order of each day must match the job. Add a weektotal for the timecard
            if (view.additionalRows) {
                view.additionalRows.forEach((item) => {
                    let csvRow = [];
                    csvRow.push('');
                    csvRow.push(view.userId || item.userId);
                    csvRow.push(view.techName);
                    csvRow.push('Totals');
                    csvRow.push(item.label);
                    for (let y = 0; y < weekDay.length; y++) {
                        csvRow.push(item[weekDay[y].day]);
                    }
                    csvRow.push(item.Sun + item.Mon + item.Tue + item.Wed + item.Thu + item.Fri + item.Sat)
                    csvData.push(csvRow);

                    grandTotals['Sun'] = grandTotals['Sun'] + item.Sun;
                    grandTotals['Mon'] = grandTotals['Mon'] + item.Mon;
                    grandTotals['Tue'] = grandTotals['Tue'] + item.Tue;
                    grandTotals['Wed'] = grandTotals['Wed'] + item.Wed;
                    grandTotals['Thu'] = grandTotals['Thu'] + item.Thu;
                    grandTotals['Fri'] = grandTotals['Fri'] + item.Fri;
                    grandTotals['Sat'] = grandTotals['Sat'] + item.Sat;
                });
            }

            break;
        default:
    };
    return csvData;
}


export const handleQBDFormat = (view, header, data) => {
    let csvData = [];
    const appSettings = view.appSettings;
    var grandTotals = {
        Sun: 0,
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
    }
    const formatName = (name) => {
        let updatedName = '';
        name = name.split(' ');
        if (name.length > 1) {
            updatedName = updatedName + name[name.length - 1] + ', ';
            name.pop();
            updatedName = updatedName + name.join(' ');
        }
        return updatedName;
    }
    const getWageType = (userId, users) => {
        return userId && users && users.length && users.filter((user) => user.id === userId)[0] ? users.filter((user) => user.id === userId)[0].wageType || '' : ''
    }
    const appendAdditionalRows = (view) => {
        let container = [];
        let dates = view.weekDates;
        view.additionalRows.forEach((row) => {
            dates.forEach((date) => {
                let day = moment(date).format('ddd'); 
                let subContainer = [];
                if (row[day] !== 0) {
                    subContainer.push('TIMEACT')
                    subContainer.push(date);
                    subContainer.push(row.label);
                    subContainer.push(formatName(view.techName));
                    subContainer.push('Labor');
                    subContainer.push(getWageType(view.userId, view.userList));
                    subContainer.push(row[day].toFixed(2));
                    subContainer.push('');
                    subContainer.push('');
                    subContainer.push('N');
                    subContainer.push((typeof view.appSettings.billable === undefined || (view.appSettings.billable && view.appSettings.billable.enabled === false)) ? 0 : 1);
                    container.push(subContainer);
                }
            })
        })

        return container.sort((a,b) => new Date(b[1]).valueOf() - new Date(a[1]).valueOf());
    }
    const getDefaultWeekDay = (startDay) => {
        var weekday = [{
            day: "Sun",
            value: 0
        }, {
            day: "Mon",
            value: 0
        }, {
            day: "Tue",
            value: 0
        }, {
            day: "Wed",
            value: 0
        }, {
            day: "Thu",
            value: 0
        }, {
            day: "Fri",
            value: 0
        }, {
            day: "Sat",
            value: 0
        }];
        // set the order of the week to match the set start day
        weekday.push.apply(weekday, weekday.splice(0, weekday.findIndex((week) => week.day === startDay)));
        return weekday;
    }
    let weekDay = getDefaultWeekDay(appSettings.startDay);
    switch (view.typeName) {
        case 'Clock Events':
            if (view.clockExports && view.clockExports.length) {
                view.model = view.clockExports;
            }
            view.model.forEach((item) => {
                let csvRow = data.map((val, index) => {
                    switch (val.header) {
                        case "activity":
                            val.value = item.activity
                            break;
                        case "location":
                            val.value = item.location
                            break;
                        case "companyName":
                            val.value = item.companyName
                            break;
                        case "technician_name":
                            val.value = view.techName
                            break;
                        case "technician_email":
                            val.value = view.techEmail
                            break;
                        case "companyId":
                            val.value = view.companyId
                            break;
                        case "jobNumber":
                            val.value = item.jobNumber
                            break;
                        case "jobName":
                            val.value = item.jobName
                            break;
                        case "jobOffice":
                            val.value = item?.jobOffice?.name || ""
                            break;
                        case "TIMEACT":
                            val.value = "TIMEACT"
                            break;
                        case "jobType":
                        case "job_type":
                            val.value = translate(item.jobType || item.job_type)
                            break;
                        case "technician_id":
                            val.value = view.userId
                            break;
                        case "tech_office":
                            val.value = view.techOffice
                            break;
                        case "item_code":
                            val.value = item.itemCode
                            break;
                        case "item_cost":
                            val.value = item.libItemCost
                            break;
                        case "item_price":
                            val.value = item.libItemPrice
                            break;
                        case "item_taxable":
                            val.value = item.libItemTaxable
                            break;
                        case "Date":
                            val.value = dateFormatter((item.usedOn || item.eventTime) * 1000) // turn item.usedOn into millseconds
                            break;
                        case "Item (quickbooks desktop)":
                            val.value = "Labor"
                            break;
                        case "BLANK":
                            val.value = ""
                            break;
                        case "XFERTOPAYROLL (quickbooks desktop)":
                            val.value = "N"
                            break;
                        case "BillingStatus (quickbooks desktop)":
                            // this should default to 0 for A1 Fire and the ungrouped clock events
                            val.value = (typeof view.appSettings.billable === undefined || (view.appSettings.billable && view.appSettings.billable.enabled === false)) ? 0 : 1
                            break;
                        case "total":
                            val.value = item.weekTotal.toFixed(2)
                            break;
                        case "PITEM (quickbooks desktop":
                            val.value = ""
                            break;
                        case "BLANK2":
                            val.value = ""
                            break;
                        case "EMP":
                            val.value = formatName(view.techName)
                            break;
                        case "QBD_JOB":
                            val.value = item.companyName + ":" + item.location
                            break;
                        case "QBD_JOB2":
                            val.value = item.companyName + ':' + translate(item.job_type || item.jobType) + ' #' + item.jobNumber
                            break;
                        case "QBD_JOB3":
                            val.value = item.companyName + ":" + item.location + ':' + translate(item.job_type || item.jobType) + ' #' + item.jobNumber
                            break;
                        case "QBD_JOB4":
                            val.value = item.companyName + ":" + item.location + ':' + item.jobName
                            break;
                        case "usedOn":
                            val.value = moment(item.usedOn * 1000).format('MM/DD/YYYY');
                            break;
                        case "quantity":
                            val.value = item.quantity
                            break;
                        case "externalId":
                            val.value = item.externalId || '';
                            break;
                        case "locationCompanyId":
                            val.value = item.locationCompanyId || '';
                            break;
                        case "techExtId":
                            val.value = view.techExtId || '';
                            if (typeof val.value === 'object') {
                                val.value = Object.values(val.value)[0]
                            }
                            if (val.value === '' && view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.externalId : '';
                            }
                            break;
                        case "locationId":
                            val.value = item.locationOfficeId || '';
                            break;
                        case "begin_date":
                            val.value = getWeekStartDayUTC(appSettings.startDay, moment(item.usedOn * 1000))
                            break;
                        case "union":
                            val.value = view.union || '';
                            if (typeof val.value === 'object') {
                                val.value = Object.values(val.value)[0]
                            }
                            if (val.value === '' && view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.isUnion : '';
                            }
                            break;
                        case "wageType":
                            val.value = view.wageType;
                            if (view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.wageType : view.wageType;
                            } else {
                                console.warn('User List Not Found')
                            }
                            break;
                        case "actionBy":
                            val.value = view.actionBy
                            break;
                        case "timecardId":
                            val.value = view._id
                            break;
                        case "clockInTimestamp":
                            val.value = view.clockInTimestamp
                            break;
                        case "clockOutTimestamp":
                            val.value = view.clockOutTimestamp
                            break;
                        case "clockInID":
                            val.value = view.clockInID
                            break;
                        case "clockOutID":
                            val.value = view.clockOutID // Required uncombined feature. Should expand it to all
                            break;
                        case "calendarStartDay":
                            val.value = view.calendarStartDay || view.appSettings.startDay
                            break;
                        case "approvedTimestamp":
                            val.value = view.approvedTimestamp
                            break;
                        default:
                            break;
                    }
                    return val.value
                })
                if (csvRow && csvRow[2] && csvRow[2].includes && !csvRow[2].includes('undefined')) {
                    csvData.push(csvRow)
                }
            });
            if (view.additionalRows && view.additionalRows.length > 0 && view.weekDates && view.weekDates[0]) {
                let formatedRows = appendAdditionalRows(view);
                csvData = [...csvData, ...formatedRows];
            }

            break;
        case 'Labor Items':
            // csvData.push(header)
            view.model.forEach((item) => {
                let csvRow = data.map((val) => {
                    switch (val.header) {
                        case "location":
                            val.value = item.location
                            break;
                        case "companyName":
                            val.value = item.companyName
                            break;
                        case "technician_name":
                            val.value = view.techName
                            break;
                        case "technician_email":
                            val.value = view.techEmail
                            break;
                        case "jobType":
                        case "job_type":
                            val.value = translate(item.jobType || item.job_type)
                            break;
                        case "companyId":
                            val.value = view.companyId
                            break
                        case "jobNumber":
                            val.value = item.jobNumber
                            break;
                        case "jobName":
                            val.value = item.jobName
                            break;
                        case "jobOffice":
                            val.value = item?.jobOffice?.name || ""
                            break;
                        case "technician_id":
                            val.value = item.userId
                            break;
                        case "Date":
                            val.value = dateFormatter(item.usedOn * 1000) // turn item.usedOn into millseconds
                            break;
                        case "TIMEACT":
                            val.value = "TIMEACT"
                            break;
                        case "Item (quickbooks desktop)":
                            val.value = "Labor"
                            break;
                        case "PITEM (quickbooks desktop)":
                            val.value = ""
                            break;
                        case "XFERTOPAYROLL (quickbooks desktop)":
                            val.value = "N"
                            break;
                        case "total":
                            val.value = item.weekTotal.toFixed(2)
                            break;
                        case "BillingStatus (quickbooks desktop)":
                            val.value = (typeof view.appSettings.billable === undefined || (view.appSettings.billable && view.appSettings.billable.enabled === false)) ? 0 : 1
                            break;
                        case "BLANK":
                            val.value = ""
                            break;
                        case "BLANK2":
                            val.value = ""
                            break;
                        case "EMP":
                            val.value = formatName(view.techName)
                            break;
                        case "QBD_JOB":
                            val.value = item.companyName + ":" + item.location
                            break;
                        case "QBD_JOB2":
                            val.value = item.companyName + ':' + translate(item.job_type || item.jobType) + ' #' + item.jobNumber
                            break;
                        case "QBD_JOB3":
                            val.value = item.companyName + ":" + item.location + ':' + translate(item.job_type || item.jobType) + ' #' + item.jobNumber
                            break;
                        case "QBD_JOB4":
                            val.value = item.companyName + ":" + item.location + ':' + item.jobName
                            break;
                        case "usedOn":
                            val.value = moment(item.usedOn * 1000).format('MM/DD/YYYY');
                            break;
                        case "quantity":
                            val.value = item.quantity
                            break;
                        case "begin_date":
                            val.value = getWeekStartDayUTC(appSettings.startDay, moment(item.usedOn * 1000))
                            break;
                        case "locationCompanyId":
                            val.value = item.locationCompanyId;
                            break;
                        case "techExtId":
                            val.value = view.techExtId || '';
                            if (typeof val.value === 'object') {
                                val.value = Object.values(val.value)[0]
                            }
                            if (val.value === '' && view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.externalId : '';
                            }
                            break;
                        case "locationId":
                            val.value = item.locationOfficeId || '';
                            break;
                        case "item_code":
                            val.value = item.itemCode
                            break;
                        case "item_cost":
                            val.value = item.libItemCost
                            break;
                        case "item_price":
                            val.value = item.libItemPrice
                            break;
                        case "item_taxable":
                            val.value = item.libItemTaxable
                            break;
                        case "union":
                            val.value = view.union || '';
                            if (typeof val.value === 'object') {
                                val.value = Object.values(val.value)[0]
                            }
                            if (val.value === '' && view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.isUnion : '';
                            }
                            break;
                        case "wageType":
                            val.value = view.wageType
                            if (view.userList) {
                                let latestUserData = view.userList.find((user) => view.userId === user.id);
                                val.value = latestUserData ? latestUserData.wageType : view.wageType;
                            } else {
                                console.warn('User List Not Found')
                            }
                            break;
                        case "tech_office":
                            val.value = view.techOffice
                            break;
                        case "actionBy":
                            val.value = view.actionBy // the user who submitted the timecard
                            break;
                        case "timecardId": // a machine id used for internal troubleshooting 
                            val.value = view._id
                            break;
                        case "clockInTimestamp":
                            val.value = view.clockInTimestamp
                            break;
                        case "clockOutTimestamp":
                            val.value = view.clockOutTimestamp
                            break;
                        case "clockInID":
                            val.value = view.clockInID
                            break;
                        case "clockOutID":
                            val.value = view.clockOutID
                            break;
                        case "week_start":
                            val.value = view.startEpoch
                            break;
                        case "week_end":
                            val.value = view.endEpoch
                            break;
                        case "calendarStartDay":
                            val.value = view.appSettings.startDay 
                            break;
                        case "approvedTimestamp":
                            val.value = view.approvedTimestamp
                            break;
                        case "libItemID":
                            val.value = item.libItemId;
                            break;
                        default:
                    }
                    return val.value
                })
                if (csvRow && csvRow[2] && csvRow[2].includes && !csvRow[2].includes('undefined')) {
                    csvData.push(csvRow)
                }
            });

            if (view.additionalRows && view.additionalRows.length > 0 && view.weekDates && view.weekDates[0]) {
                csvData = [...csvData, ...appendAdditionalRows(view)]
            }
            break;

        case 'Expenses':
            // csvData.push(header)
            view.model.forEach((item) => {
                let csvRow = [];
                csvRow.push(item.jobNumber);
                csvRow.push(item.userId)
                csvRow.push(view.techName);
                csvRow.push(item.location);
                csvRow.push(item.itemCode);
                item.week.forEach((day) => {
                    csvRow.push(day.value);
                    grandTotals[day.day] = grandTotals[day.day] + day.value;
                })
                csvRow.push(item.weekTotal)
                csvData.push(csvRow);
            });
            // For additional rows, the format / order of each day must match the job. Add a weektotal for the timecard
            if (view.additionalRows) {
                view.additionalRows.forEach((item) => {
                    let csvRow = [];
                    csvRow.push('');
                    csvRow.push(view.userId);
                    csvRow.push(view.techName);
                    csvRow.push('Totals');
                    csvRow.push(item.label);
                    for (let y = 0; y < weekDay.length; y++) {
                        csvRow.push(item[weekDay[y].day]);
                    }
                    csvRow.push(item.Sun + item.Mon + item.Tue + item.Wed + item.Thu + item.Fri + item.Sat)
                    csvData.push(csvRow);

                    grandTotals['Sun'] = grandTotals['Sun'] + item.Sun;
                    grandTotals['Mon'] = grandTotals['Mon'] + item.Mon;
                    grandTotals['Tue'] = grandTotals['Tue'] + item.Tue;
                    grandTotals['Wed'] = grandTotals['Wed'] + item.Wed;
                    grandTotals['Thu'] = grandTotals['Thu'] + item.Thu;
                    grandTotals['Fri'] = grandTotals['Fri'] + item.Fri;
                    grandTotals['Sat'] = grandTotals['Sat'] + item.Sat;
                });
            }

            break;
        default:
    };
    return csvData;
}