import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import axiosInstance from '../../utilities/axios';
import { connect } from 'react-redux';
import { requestHeaderConfig } from '../../utilities';

const ManageCheckBox = (props) => {
    let [isChecked, setChecked] = useState(props.row.canManage || false);

    const handleManageChange = (el, user) => {
        setChecked(!isChecked);
        user.canManage = !isChecked;
        updateUser(user);
    };

    const updateUser = (user) => {
        delete user.serviceLines;
        axiosInstance.post('/updateUser', {
                companyId: props.login.user.company.id,
                user: user,
                isSubmit: false
              }, requestHeaderConfig(props.login.authToken))
            .then(function () {
                
            })
            .catch(function (error) {
                console.log(error, ': An error has occured while retrieving the settings');
            });
    }

    return (
        <Checkbox
            onChange={(e) => {handleManageChange(e, props.row)}}
            name="manage"
            color="primary"
            checked={isChecked}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(ManageCheckBox);