import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AdditionalRow from './AdditionalRow'
import { v4 as uuid } from 'uuid';


const AdditionalRowContainer = (props) => {
    const {additionalRows, typeName} = props
    let [timeCardRows, setTimeCardRows] = useState(additionalRows);
    const isViewTimeCard = props.isViewTimeCard;

    useEffect(() => {
        setTimeCardRows(additionalRows);
    },[additionalRows, props.additionalRows, props.timeCardRows]);

    return (
        <>
            {timeCardRows && timeCardRows.length > 0 && timeCardRows.map((row) => (
                <AdditionalRow key={uuid()} isViewTimeCard={isViewTimeCard} readOnly={props.readOnly} row={row} typeName={typeName}/>
            )) }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(AdditionalRowContainer);
