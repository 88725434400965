import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux';
import Total from './total';
import { v4 as uuid } from 'uuid';
import { round , convertDecimalToHoursToHourly} from '../../utilities';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 160
    }
}));

const GrandTotals = (props) => {
    const classes = useStyles();
    const { isViewTimeCard, isEditTimeCard } = props;
    const isSummaryGrandTotal = props.isSummaryGrandTotal;
    let renderDecimalFormatClock = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;

    return (
        <TableRow key={uuid()}>
            <TableCell key={uuid()}>
                <Typography className={classes.root}>
                    <strong>Grand Totals:</strong>
                </Typography>
            </TableCell>
            {window.location.pathname === '/viewMyTimecard' && <TableCell key={uuid()}></TableCell>}
            {window.location.pathname === '/viewTimecard' && <TableCell key={uuid()}></TableCell>}
            <TableCell key={uuid()}></TableCell>
            {(props.appSettings && props.appSettings.clockEventTypes && props.appSettings.clockEventTypes.onbreak && props.appSettings.clockEventTypes.onbreak === true && isSummaryGrandTotal === true) && (
                <TableCell key={uuid()}></TableCell>
            )}
            {isSummaryGrandTotal && (<TableCell key={uuid()}></TableCell>)}
            {isEditTimeCard && (<TableCell key={uuid()}></TableCell>)}
            {isViewTimeCard && (
                <>  
               
                </>
                )}
            {props.week && props.week.length > 0 ? props.week.map((day) => (
                <>
                    <Total key={uuid()} day={day} />
                </>
            )) : null}
            <TableCell align='center' key={uuid()}>
                <strong>{ renderDecimalFormatClock && props.timeCardType === 1 
                ? convertDecimalToHoursToHourly(round(props.workTotal + props.grandTotal))
                : round(props.workTotal + props.grandTotal)
                }</strong>
            </TableCell>
        </TableRow>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(GrandTotals);