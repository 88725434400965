import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.primary,
    }
  }));

  /**
 * @summary React state will not update by passing the same object it already has. 
 * Create a new object with the same values as the original 
 * @param {object} model 
 */
const createNewModel = (model) => {
    let newModel = {}
    for (const [key, value] of Object.entries(model)) {
        newModel[key] = value;
    } 

    return newModel;
}

const SwitchOptions = (props) => {
    let [model, setModel] = useState(props.appSettings[props.fields.name] || props.login.settings[props.fields.name] || props.fields.default)
    const classes = useStyles();

    const updateUserList = () => {
        axiosInstance.get('/listUsers', {
            params: {
                companyId: props.login.user.company.id
            },
            ...requestHeaderConfig(props.login.authToken)
        })
            .then(function (response) {
                if (response && response.data && response.data.userList) {
                    props.dispatch({
                        type: 'set_users',
                        payload: response.data.userList
                    });
                }
                props.dispatch({
                    type: 'set_backdrop',
                    payload: false
                });
            })
            .catch(function (error) {
                props.dispatch({
                    type: 'set_backdrop',
                    payload: false
                });
            });
    }

    const handleToggle = (field) => () => {
        let newModel = model;
        newModel[field.key] = !model[field.key];
        setModel(createNewModel(newModel));
        axiosInstance.post('/settings', {
                companyId: props.login.user.company.id,
                key: field.name,
                value: newModel
            }, requestHeaderConfig(props.login.authToken)).then((response) => {
                let updatedSettingsObj = response.data;
                props.dispatch({
                    type: 'set_settings',
                    payload: updatedSettingsObj
                })
                if (props.fields.shouldUpdateUsersOnChange) {
                    props.dispatch({
                        type: 'set_backdrop',
                        payload: true
                    });
                    updateUserList();
                }
            }).catch((e) => {
                console.warn(e, 'Could not save settings for :' + field.label);
            });
    };

    const shouldShowSettings = (settingName) => {
        if (props.fields.title === 'Clock Events' && settingName.indexOf('Combine') > -1) {
            return false;
        } else {
            return true;
        }
    };

    const shouldNotHide = (field) => {
        let shouldNotHide = true;
        if (field.hide) {
            shouldNotHide = false;
        }
        return shouldNotHide;
    };

    if (!model) {
        return (
            <></>
        )
    }
    return (
        <>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <List subheader={<ListSubheader>{props.fields.title}</ListSubheader>} className={classes.root}>
                        {props.fields.options.map((field) => {
                            return (shouldShowSettings(field.label) && shouldNotHide(field) && <ListItem key={field.id + field.key + new Date().getTime()}>
                                <ListItemText id={"switch-list-label-" + field.id} primary={field.label} />
                                <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    color="primary"
                                    onChange={handleToggle(field)}
                                    checked={model[field.key]}
                                    inputProps={{ 'aria-labelledby': 'switch-list-label-' + field.id }}
                                />
                                </ListItemSecondaryAction>
                            </ListItem>)
                        })}
                    </List>
                </Paper>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
  };
export default connect(mapStateToProps)(SwitchOptions);