import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import { requestHeaderConfig } from '../../utilities';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

  const SelectOffice = (props) => {
    const handleChange = (field) => {
        props.dispatch({
            type: 'set_selected_office',
            payload: field.target.value
        });
    };

    useEffect(() => {
        axiosInstance.get('/offices', {
            params: {
                companyId: props.login.user.company.id
              },
              ...requestHeaderConfig(props.login.authToken)
            })
            .then(function (response) {
                if (response && response.data && response.data) {
                    // When importing a fresh list from service trade, it overwrites the permissions.
                    // It also forces the admin to logout and log back in to make the redux store for props.login.userList to be updated.
                    props.dispatch({
                        type: 'set_office_list',
                        payload: response.data
                    });
                }
            })
            .catch(function (error) {
                console.log(error, ': An error has occured while retrieving the settings');
            });
    }, [])

    if (!props.officeList || !props.officeList.length) {
        return (
            <></>
        )
    }

    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
        <FormHelperText>Office</FormHelperText>
            <Select
                value={props.selectedOffice}
                label="Email"
                onChange={handleChange}
            >
                {props.officeList.map((office) => {
                    return (<MenuItem key={uuid()} value={office.name}>{office.name}</MenuItem>)
                })}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(SelectOffice);