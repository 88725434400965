import React from 'react'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
    modal: {
        margin:'auto',
        height: '60%',
        maxWidth: '40%'

    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
   
}));


const StartDayModal = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClick = () => {
        props.setShowCalendar(true)
        setOpen(false)
    }
    return (
        <div>
     
        <Button onClick={handleOpen} color='primary' variant="contained" style={{ marginBottom: 10 }}  >Change Start Day</Button>

        <Modal
           aria-labelledby="transition-modal-title"
           aria-describedby="transition-modal-description"
           className={classes.modal}
           open={open}
           onClose={handleClose}
           closeAfterTransition
           BackdropComponent={Backdrop}
           BackdropProps={{
               timeout: 500,
           }}
        >
           <Fade in={open}>
                    <div className={classes.paper}>
                    <MuiAlert style={{ marginBottom: 20 }} severity="error">
                        Changing your start day while having existing timecards can affect your exporting format
                    </MuiAlert>

                        <Button onClick={handleClick} style={{marginTop: 10}} color='primary' variant="contained"  type="button" >
                            Yes I would still like to change my start day
                        </Button>
                    </div>
                </Fade>
        </Modal>
      </div>
    )
}

export default StartDayModal
