import ExportIcon from '@mui/icons-material/ArrowDownward';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const ExportSelectedButton = (props) => {
    const [count, setCount] = useState(0);
    const openExportModal = () => {
        props.dispatch({
            type: 'open_export_modal',
            payload: true
        });
    }
    useEffect(() => {
        props.showExportModal ? setCount(props.constTimecards.length) : setCount(props.bulkActions.length)
    }, [props.bulkActions])

    return (
        <Button onClick={() => {
            return openExportModal();
        }} variant="contained" disabled={count === 0} startIcon={<ExportIcon />}>
            Export Selected {count > 0 && (<span>({count})</span>)}
        </Button>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(ExportSelectedButton);