import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { requestHeaderConfig } from '../../utilities';

const ActionButton = {
    Submit:'submit',
    Release: 'release'
}
const hasFeature = (name, appSettings) => !!(appSettings.features && appSettings.features.includes(name));

const useStyles = makeStyles(() => ({
    submit: {
        margin: 10
    },
}));

const SubmitTimecardButton = (props) => {
    const classes = useStyles();
    const [buttonProperties, setButtonProperties] = useState({
        btnLabel: "Submit",
        disabled: props.mustBeCertified,
        type: ActionButton.Submit
    })

    const handleRelease = async () => {
        // disable while waiting for respnose
        setButtonProperties({
            btnLabel: "Release",
            disabled: true,
            type: ActionButton.Release
        })
        await axiosInstance.post('/save', {
            released: moment().format('YYYY-MM-DD hh:mm:ss a'),
            userid: props.technician.id,
            range: props.range,
            type: props.timeCardType
        }, requestHeaderConfig(props.login.authToken)).then(() => {
            props.dispatch({type: 'set_alert',payload: { active: true, isInfo: false, message: 'Timecard Released'}});
            props.dispatch({
                type: 'timecard_is_released',
            });
            setButtonProperties({
                btnLabel: "Submit",
                disabled: true,
                type: ActionButton.Submit
            });
            props.setCertified(true);
        }).catch(() => {
            props.dispatch({
                type: 'timecard_not_released',
            });
            setButtonProperties({
                btnLabel: "Release",
                disabled: false,
                type: ActionButton.Release
            })
        });
    }

    const isButtonDisabled = () => {
        if (props?.appSettings?.filterByManager?.enabled && hasFeature('release_timecard', props.appSettings)) { 
            return buttonProperties.disabled;
        } else {
            return props.mustBeCertified;
        }
    }

    useEffect(async () => {
        if (props?.appSettings?.filterByManager?.enabled && hasFeature('release_timecard', props.appSettings)) {
            if (props.timecardReleaseStatus) {
                setButtonProperties({
                    btnLabel: "Submit",
                    disabled: props.mustBeCertified,
                    type: ActionButton.Submit
                })
            } else {
                if (props.login.canSubmit || props.login.isAdmin) {
                    // what if Manager A has for a direct report Manager B. Should B be blocked from submitting his own Timecard while still approving others
                    setButtonProperties({
                        btnLabel: "Release",
                        disabled: false,
                        type: ActionButton.Release
                    })
                } else {
                    // Timecard is unreleased and current user is not a manager or admin
                    setButtonProperties({
                        btnLabel: "Unreleased",
                        disabled: true,
                        type: ActionButton.Submit
                    })
                }
            }
        }
    }, [props.timecardReleaseStatus, props.mustBeCertified, props.currentDateRange])

    return (
        <Box>
            <Button className={classes.submit} variant="contained" color={isButtonDisabled() ? "secondary" : "primary"} disabled={isButtonDisabled()} onClick={() => {
                buttonProperties.type === ActionButton.Submit ? props.handleSubmit() : handleRelease()
            }}>{buttonProperties.btnLabel}</Button>
            <FormControlLabel
                control={<Checkbox name="confirm" color="primary" onClick={props.handleConfirm} checked={!props.mustBeCertified}
                    style={{ pointerEvents: "auto" }} />}
                label="I have reviewed the above, and certify it to be true."
                style={{ pointerEvents: "none" }}
            />
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(SubmitTimecardButton);

