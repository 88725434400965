import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    dropdown: {
        width: '80%'
    }
  }));

const SelectFileType = (props) => {
    const classes = useStyles();
    const { value, setValue, data } = props;
    const handleChange = (field) => {
        setValue(field.target.value)
    };

    return (
        <Select className={classes.dropdown} value={value} onChange={handleChange}>
        {data.map((ext) => (
            <MenuItem value={ext}>{ext}</MenuItem>
        ))}
    </Select>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(SelectFileType);