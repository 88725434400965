import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import axiosInstance from '../../utilities/axios';
import { connect } from 'react-redux';
import { requestHeaderConfig } from '../../utilities';

const SubmitCheckBox = (props) => {

    let [isChecked, setChecked] = useState(props.row.canSubmit || false);

    const handleSubmitChange = (el, user) => {
        setChecked(!isChecked);
        user.canSubmit = !isChecked;
        updateUser(user);
    };

    const updateUser = (user) => {
        // users with complete list of service lines will exceed payload size of nodejs
        delete user.serviceLines;
        axiosInstance.post('/updateUser', {
                companyId: props.login.user.company.id,
                user: user,
                isSubmit: true
              }, requestHeaderConfig(props.login.authToken))
            .then(function () {

            })
            .catch(function (error) {
                console.log(error, ': An error has occured while updating the user');
            });
    }

    return (
        <Checkbox
            onChange={(e) => {handleSubmitChange(e, props.row)}}
            name="submit"
            color="primary"
            checked={isChecked}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(SubmitCheckBox);