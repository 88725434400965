/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Chip from '@mui/material/Chip';
import Typography from '@material-ui/core/Typography';
import { connect, useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import GrandTotals from './grandTotals';
import axiosInstance from '../../utilities/axios';
import { formatWeekDayOrder } from '../../actions/templateActions'
import { handleItemCodeHeaders, handleItemCodeFormat, groupClockEventData, handleClockHeaders, handleCSVFormat, handleQBDFormat} from '../../actions/viewTimecardActions'
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TextField from '@material-ui/core/TextField';
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { round, analytics, convertDecimalToHoursToHourly, translate, requestHeaderConfig } from '../../utilities';
import {templateTypes} from '../../Types/TemplateTypes'
import { Approved, Submitted } from '../../utilities/constants/constants'
import Comments from '../timecard/viewScreen/comments/comments';
import RequestChange from '../timecard/viewScreen/requestChange/RequestChange'
import SendEmailCSV from '../dashboard/emailCSV';
import { Avatar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import ViewSummary from './viewScreen/ViewSummary'


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1),
    },
    additionalRows: {
        maxWidth: 30
    },
    comments: {
        width: '100%'
    },
    accordion: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    submit: {
        margin: 10
    },
    dateHeader: {
        textAlign: 'center'
    },
    timecardDay: {
        textAlign: 'center'
    },
    jobHeader: {
        minWidth: 150
    },
    summary: {
        borderBottom: 'none',
        width: 92,
        paddingRight: 9
    },
    summaryBtn: {
        padding: '0 0 0 22px'
    },
    summaryBtmBorder: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'

    }
}));

const sortWeekDay = (weekday, startDay) => {
    // set the order of the week to match the set start day
    weekday.push.apply(weekday, weekday.splice(0, weekday.findIndex((week) => week.day === startDay)));
    return weekday;
}

const calculate = (rows) => {
    let total = rows ? rows.map((row) => {
        return row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat']
    }) : rows;

    if (Array.isArray(total) && total.length === 0) {
        return 0;
    } else if (total && total.reduce) {
        return total.reduce((total, value) => total + value)
    } else {
        return 0
    }
};

const ViewTimeCard = (props) => {
      const fileNameFormat = {
        techName: props.view.techName.replace(/ /g, '-'),
        techId: props.technician.id.toString().slice(4, props.technician.id.length),
        date: props.view.weekDates[0].slice(0, 5).replace(/\//g, ''),
        typeName: props.view.typeName.replace(/ /g, '-')
    }

    const { view } = props
    const classes = useStyles();
    let navi = useHistory();
    let uniqueJobItems = [];
    let [model, setModel] = useState([])
    let [week, setWeek] = useState([])
    let [additionalRows, setAdditionalRows] = useState([])
    let [buttonsLoading, setButtonsLoading] = useState(false);
    let [comments, setComments] = useState([])
    let [status, setStatus] = useState(props.view.status)
    let [summaryExpanded, setSummaryExpanded] = useState(true);
    let isClockEvent = props.view.typeName === "Clock Events"
    let decimalFormatEnabled = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;
    const [expanded, setExpanded] = React.useState(props.view.comments && props.view.comments.length > 0 ? true : false);
    const dispatch = useDispatch();
    const { laborDefault, clockDefault } = props.appSettings
    const { startMoment } = props.view


    const templateList = useSelector((state) => state.templateList)
    const weekDay = sortWeekDay(templateList.weekDay, props.appSettings.startDay)
    
    const [fileName, setFileName] = useState(`TimeCard${fileNameFormat.date}-${fileNameFormat.typeName}-export.csv`)

    // ** START data for labor items template
    const [laborItemTemp, setLaborItemTemp] = useState([])
    const [laborItemHeader, setLaborItemHeader] = useState([])
    const [laborItemExport, setLaborItemExport] = useState([])

    // ** Start of Clock Events template
    const [clockEventTemp, setClockEventTemp] = useState([])
    const [clockItemHeader, setClockItemHeader] = useState([])
    const [clockItemExport, setClockItemExport] = useState([])

    const handleChange = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {

        setModel(props.view.model);
        if (props.view.model.length) {
            setWeek(props.view.model[0].week);
        }
        setAdditionalRows(props.view.additionalRows)
        setComments(props.view.comments)
        if (window.location.pathname === '/viewMyTimecard' || window.location.pathname === '/myTimecards' || window.location.pathname === "/viewTimecard") {
            setFileName(`Timecard${fileNameFormat.date}-${fileNameFormat.techName}-${fileNameFormat.techId}-${fileNameFormat.typeName}.csv`)
        }
        dispatch(formatWeekDayOrder(props.appSettings.weekDayOrder))

        if (laborDefault.data) {
            setLaborItemTemp(laborDefault.data.sort(function (a, b) { return a.index - b.index }))
            setLaborItemExport([...laborItemTemp, ...weekDay])
            setLaborItemHeader(laborItemTemp.map((item) => item.alias))
        }
        if (clockDefault.data) {
            setClockEventTemp(clockDefault.data.sort(function (a, b) { return a.index - b.index }))
            setClockItemExport([...clockEventTemp, ...weekDay])
            setClockItemHeader(clockEventTemp.map((item) => item.alias))
        }

    }, [laborItemTemp, laborDefault.data, dispatch, clockEventTemp])

 
    const isTechViewTimecardScreen = () => {
        return window.location.pathname === '/viewMyTimecard';
    }
    let rows = model;
    let total = 0;
    // combines total from all records entered by technician in servicetrade
    for (let x = 0; x < rows.length; x++) {
        total = total + rows[x].weekTotal;
    }
    props.dispatch({
        type: 'set_work_total',
        payload: total
    });
    props.dispatch({
        type: 'set_application_page',
        payload: 'View Timecard: ' + props.view.techName
    });

    props.dispatch({
        type: 'set_grand_total',
        payload: calculate(props.view.additionalRows)
    })

    const handleCancel = () => {
        if (isTechViewTimecardScreen()) {
            navi.push('/myTimecards');
        } else {
            navi.push('/timecards');
        }
    };

    // dynamically changes export data based on template clients are using
    switch (props.view.typeName) {
        case 'Labor Items':
            if (laborDefault.groupByItemCode === true || laborDefault.groupByItemCode === templateTypes.GROUPED) {
                var exportData = handleItemCodeFormat(props.view, laborDefault)
                var headers = handleItemCodeHeaders(props.view, laborDefault)
            } else if (laborDefault.groupByItemCode === false || laborDefault.groupByItemCode === templateTypes.UNGROUPED || laborDefault.stacId !== undefined || laborDefault.groupByItemCode === null) {
                exportData = handleCSVFormat(props, clockItemHeader, view, startMoment, decimalFormatEnabled, clockItemExport, laborItemHeader, laborItemExport)
            } else if (laborDefault.groupByItemCode === templateTypes.QBD) {
                exportData = handleQBDFormat(props, clockItemHeader, view, startMoment, clockItemExport, laborItemHeader, laborItemExport)
            }
            break;
        case 'Clock Events':
            if (clockDefault.groupByItemCode === true || clockDefault.groupByItemCode === templateTypes.GROUPED) {
                exportData = groupClockEventData(props.view, clockDefault, decimalFormatEnabled)
                 headers = handleClockHeaders(props.view, clockDefault)
            } else if (clockDefault.groupByItemCode === false || clockDefault.groupByItemCode === null || clockDefault.groupByItemCode === templateTypes.UNGROUPED || clockDefault.stacId !== undefined) {
                exportData = handleCSVFormat(props, clockItemHeader, view, startMoment, decimalFormatEnabled, clockItemExport, laborItemHeader, laborItemExport)
            } else if (clockDefault.groupByItemCode === templateTypes.QBD) {
                exportData = handleQBDFormat(props, clockItemHeader, view, startMoment, clockItemExport, laborItemHeader, laborItemExport)
            } 
            break;
        case 'Expenses':
            exportData = handleCSVFormat(props, clockItemHeader, view, startMoment, decimalFormatEnabled, clockItemExport, laborItemHeader, laborItemExport)
            break;
        default:
            break;
    }

    const handleApprove = () => {
        setButtonsLoading(true);
        const payload = {
            companyId: props.login.user.company.id,
            userId: props.login.user.id,
            id: [props.view.id]
        };

        axiosInstance.post('/approve', payload, requestHeaderConfig(props.login.authToken)).then(() => {
            setStatus('Approved')
            // analytics({ props: props, type: SUBMISSION, action: APPROVE_BUTTON, metadata: props.view })
            setButtonsLoading(false);
        }).catch((e) => {
            console.warn(e, 'Could not approve timecard');
        });

      
    
    };

    const formatDate = (date) => {
        return moment(date).format('MM/DD/YY').toString();
    };

    const unique = (id) => {
        let isUnique = uniqueJobItems.indexOf(id) < 0;
        if (isUnique) {
            uniqueJobItems.push(id);
        }
        return isUnique;
    };

    if (!model || !week) {
        return (
            <>
                <Container maxWidth="md" className={classes.container}>
                    <Grid item xs>
                        <Paper className={classes.paper}>
                            <h4 align="left">Loading</h4>
                            <CircularProgress />
                        </Paper>
                    </Grid>
                </Container>
            </>
        )
    }
    return (
        <Container style={{ marginTop: 30 }}>
            <MuiAlert severity={status === Approved ? "success" : status === Submitted ? "info" : "warning"}>Current Status: <strong>{status}</strong></MuiAlert>
            <TableContainer style={{ marginTop: 10 }} component={Paper}>
                <Table size='small' className={classes.table} aria-label="Timecard">
                    <TableHead>
                        <TableRow key={uuid()}>
                            <TableCell className={classes.jobHeader}>Job</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell></TableCell>
                            {week && week.length > 0 ? week.map((day, index) => (
                                <TableCell className={classes.dateHeader} key={uuid()}>{day.day} <br /><sub>{formatDate(props.view.weekDates[index])}</sub></TableCell>
                            )) : null}
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {model.map((row) => (
                            <TableRow key={uuid()}>
                                <TableCell>
                                    <Typography className={classes.root}>
                                        {unique(row.jobNumber) && row.jobNumber !== '' && (
                                            <Link href={row.link} target="_blank">
                                            [ {row.jobNumber + "\n"} ] - <Chip label={translate(row.jobType || row.job_type, row)} size="small" />
                                            </Link>
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {row.location}
                                </TableCell>
                                <TableCell>
                                    {row.activity && (
                                        <>
                                            {row.activity}
                                        </>
                                    )}
                                    {!row.activity && (
                                        <>
                                            {row.itemCode}
                                        </>
                                    )}
                                </TableCell>
                                {row.week.map((day) => (
                                    <TableCell className={classes.timecardDay} key={uuid()}>
                                        {isClockEvent && decimalFormatEnabled
                                        ? convertDecimalToHoursToHourly(round(day.value))
                                        :round(day.value)}
                                    </TableCell>
                                ))}
                                <TableCell>
                                {isClockEvent && decimalFormatEnabled
                                        ? convertDecimalToHoursToHourly(round(row.weekTotal))
                                        :round(row.weekTotal)}

                                </TableCell>
                            </TableRow>
                        ))}
                        {additionalRows && additionalRows.length > 0 ? additionalRows.map((row) => (
                            <TableRow key={uuid()}>
                                <TableCell>
                                    <Typography className={classes.root}>
                                        {row.label}
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                {week.map((day) => (
                                    <TableCell className={classes.timecardDay} key={uuid()}>
                                        {round(row[day.day])}
                                    </TableCell>
                                ))}
                                <TableCell>
                                    {round(row['Sun'] + row['Mon'] + row['Tue'] + row['Wed'] + row['Thu'] + row['Fri'] + row['Sat'])}
                                </TableCell>
                            </TableRow>
                        )) : null}
                        <GrandTotals week={week} key={uuid()} />
                    </TableBody>
                </Table>
            </TableContainer>
            <Accordion className={classes.accordion} expanded={summaryExpanded} onChange={() => {
                summaryExpanded ? setSummaryExpanded(false) : setSummaryExpanded(true);
            }}>
                <AccordionSummary style={{ backgroundColor: "#FFFEF1" }} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography style={{ paddingRight: 4 }} className={classes.heading}>
                        <strong>View Summary:</strong>
                    </Typography>
                    {
                        summaryExpanded ?  <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                </AccordionSummary>
                <AccordionDetails>
                    <ViewSummary timeCardWeek={week} isViewTimeCard={true} additionalRows={additionalRows} model={props.view.model} typeName={props.view.typeName} />
                </AccordionDetails>
            </Accordion>
            {props && props.appSettings && props.appSettings.comments && props.appSettings.comments.enabled && (
                <Accordion className={classes.accordion} expanded={expanded} onChange={handleChange}>
                    <Paper>
                        <AccordionSummary style={{ border: ".1px ridge gray" }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography className={classes.heading}>Comments</Typography>
                        </AccordionSummary>
                    </Paper>

                    {typeof (comments) === 'string'
                        ? <AccordionDetails>
                            <TextField className={classes.comments} disabled={true} value={comments} id="outlined-multiline-static" label="Comments" multiline rows={4} variant="outlined" />
                        </AccordionDetails>
                        : comments.map((item) => {
                            if (!item.adminComment) {
                                return <Paper key={uuid()} style={{ padding: "20px 20px 1px", color: '#004680', backgroundColor: '#f8f6f0' }}>
                                    <AccordionDetails >
                                        <Grid container wrap="nowrap" spacing={2}>
                                            <Grid item>
                                                <Avatar alt="" src={item.avatar || ''}>{props.view.techName[0]}</Avatar>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                                <h4 style={{ margin: 0, textAlign: "left" }}>{!item.name ? props.view.techName : item.name}</h4>
                                                <p style={{ textAlign: "left" }}>
                                                    {item.msg}
                                                </p>
                                                <p style={{ textAlign: "left", color: "gray" }}>
                                                    {item.timestamp}
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Paper>
                            }
                            return <Paper style={{ padding: "20px 20px 1px", color: '#004680', backgroundColor: '#f8f6f0' }}>
                                <AccordionDetails  >
                                    <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <Avatar src={item.avatar || ''} style={{ backgroundColor: '#FBB034' }}>A</Avatar>
                                        </Grid>

                                        <Grid item xs zeroMinWidth>
                                            <h4 style={{ margin: 0, textAlign: "left" }}>Admin Message {item.name && "From " + item.name}</h4>
                                            <p style={{ textAlign: "left" }}>
                                                {item.msg}
                                            </p>
                                            <p style={{ textAlign: "left", color: "gray" }}>
                                                {item.timestamp}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Paper>
                        })}
                </Accordion>
            )}

            <Box sx={{ width: '100%' }}>
                <Grid container >
                    {view.status !== Approved && <div>
                        {!isTechViewTimecardScreen() && props.login.canSubmit && status !== 'Approved' && !buttonsLoading
                            && (<Button style={{ backgroundColor: '#004680', color: 'white', marginRight: 1 }} className={classes.submit} color="primary" onClick={handleApprove}>Approve</Button>)}
                    </div>}

                    <Grid item >
                        {!buttonsLoading && <Button style={{ backgroundColor: '#004680', color: 'white' }} className={classes.submit} onClick={handleCancel}>Cancel</Button>}
                    </Grid>
                    <Grid style={{ paddingTop: 9 }} item>
                        <Comments setComments={setComments} allComments={comments} />
                    </Grid>
                    <Grid style={{ paddingTop: 9, paddingLeft: 9}} >
                        {!buttonsLoading && <RequestChange setButtonsLoading={setButtonsLoading} buttonsLoading={buttonsLoading} setComments={setComments} allComments={comments} setStatus={setStatus}/>}
                    </Grid>
                    <Grid style={{ paddingTop: 9, paddingLeft: 9 }} item>
                        <SendEmailCSV exportData={exportData} headers={headers} />
                    </Grid>

                    <Grid >
                        <CSVLink style={{ textDecoration: 'none' }} filename={fileName} data={exportData} headers={headers} >
                            <Button color='secondary' variant='contained' style={{ marginTop: 9, marginLeft: 9 }} >Export {fileNameFormat.typeName}</Button>
                        </CSVLink>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(ViewTimeCard);
