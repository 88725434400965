import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AdditionalRow from './additionalRow';

const AdditionalRowContainer = (props) => {
    let [timeCardRows, setTimeCardRows] = useState(props.timeCardRows);
    const { isViewTimeCard, isEditTimeCard } = props;
    useEffect(() => {
        setTimeCardRows(props.timeCardRows);
    },[props.timeCardRows]);

    return (
        <>
            {timeCardRows && timeCardRows.length > 0 ? timeCardRows.map((row) => (<>
                <AdditionalRow isEditTimeCard={isEditTimeCard} isViewTimeCard={isViewTimeCard} readOnly={props.readOnly} row={row}></AdditionalRow></>
            )) : null}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(AdditionalRowContainer);