import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer, Table, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import AdditionalRowContainer from '../viewScreen/AdditionalRowContainer';
import { formatDate } from '../../utilities/dateUtilities'
import GrandTotals from '../grandTotals'
import { round, convertDecimalToHoursToHourly } from '../../../utilities';
import combineByItemCode from './utilities/combineItemCode'


const useStyles = makeStyles((theme) => ({
    hide: {
        display: 'none'
    },
    timecardDay: {
        textAlign: 'center',
        borderBottom: 'none'
    },
    summary: {
        borderBottom: 'none',
        width: 92,
        paddingRight: 9
    },
    summaryBtn: {
        padding: '0 0 0 22px'
    },
    summaryBtmBorder: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    root: {
        width: '100%',
        maxWidth: 360,
    }
}));

const typeNames = {
    clockEvent: 'Clock Events'
}

const ViewSummary = (props) => {
    const classes = useStyles();
    const { isViewTimeCard, additionalRows, timeCardWeek, model, typeName } = props
    let renderDecimalFormatClock = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;
    const [localModel, setLocalModel] = useState([])

    useEffect(() => {
        const formatModel = () => {
            let newModel = combineByItemCode(model).summary
            return newModel
        }
        formatModel();
        setLocalModel(formatModel());
    }, [])

    return (
        <>
            <TableContainer>
                <Table size='small' >
                    <TableHead>
                        <TableRow key={uuid()}>
                            <TableCell className={classes.jobHeader}></TableCell>
                            <TableCell></TableCell>
                            {isViewTimeCard && (
                                <>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    {props.appSettings.clockEventTypes.onbreak && props.appSettings.clockEventTypes.onbreak === true && (<TableCell></TableCell>)}
                                </>
                            )}
                            {timeCardWeek && (
                                <>
                                    {timeCardWeek && timeCardWeek.length > 0 ? timeCardWeek.map((day, index) => (
                                        <TableCell key={uuid()} align='center' className={classes.dateHeader}>{day.day} <br /><sub>{formatDate(props.view.weekDates[index])}</sub></TableCell>
                                    )) : null}
                                </>)}
                            {!timeCardWeek && (
                                <>
                                    {props.appSettings.weekDayOrder && props.appSettings.weekDayOrder.length > 0 ? props.appSettings.weekDayOrder.map((day, index) => (
                                        <TableCell align='center' className={classes.dateHeader} key={uuid()}>{day.day} <br /><sub>{formatDate(props.dateRanges.weekDates[index]).slice(0, 5)}</sub></TableCell>
                                    )) : null}
                                </>)}
                            <TableCell align='center'>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    {localModel && localModel.map((row) => (
                        <TableRow>
                            <TableCell style={{borderBottom: 'none'}} colSpan={props.readOnly && 0}>
                                <Typography className={classes.root}>
                                    {row.Label}
                                </Typography>
                            </TableCell>
                            {Array.from({ length: 3 }).map(() => <TableCell style={{borderBottom: 'none'}} />)}
                            {props.appSettings.clockEventTypes.onbreak && props.appSettings.clockEventTypes.onbreak === true && (<TableCell style={{borderBottom: 'none'}}></TableCell>)}
                            {model[0].week.map((key) => (
                                <TableCell  className={classes.timecardDay} key={uuid()}>
                                    {renderDecimalFormatClock && typeName === typeNames.clockEvent
                                        ? convertDecimalToHoursToHourly(round(row[key.day]))
                                        : round(row[key.day])}
                                </TableCell>
                            ))}
                            <TableCell className={classes.timecardDay}>
                            {renderDecimalFormatClock && typeName === typeNames.clockEvent
                                        ? convertDecimalToHoursToHourly(round(row.Total))
                                        : round(row.Total)}

                            </TableCell>
                        </TableRow>
                    ))}
                    <AdditionalRowContainer isViewTimeCard={isViewTimeCard} readOnly={true} additionalRows={additionalRows} typeName={typeName} />
                    <GrandTotals isSummaryGrandTotal={true} isViewTimeCard={true} week={props.appSettings.weekDayOrder} />
                </Table>
            </TableContainer>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(ViewSummary);
