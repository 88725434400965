import React from 'react';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import { requestHeaderConfig } from '../../utilities';
import axiosInstance from '../../utilities/axios';

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const Logout = (props) => {
    const handleLogout = async () => {
        axiosInstance.post('/logout', {}, requestHeaderConfig(props.login.authToken)).then(() => {
          const domain = process.env.REACT_APP_AUTH0_DOMAIN;
          const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
          const returnTo = process.env.REACT_APP_AUTH0_RETURN_TO;
          window.location.href = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`
        })
    }
    return (
      <ListItemLink onClick={handleLogout} primary="Log Out">
        <ListItem button>
          <ListItemIcon>
            <LogOutIcon color="primary" />
          </ListItemIcon>
            <ListItemText primary="Log Out" />
        </ListItem>
      </ListItemLink>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(Logout);