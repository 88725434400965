import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import TemplateColumnListItem from './templateColumnNameList';


const useStyles = makeStyles((theme) => ({
    stepTwo: {
      width: '200px',
      height: '200px'
    }
  }));

const TemplateColumnNaming = (props) => {
    const classes = useStyles();
    let [templateColumns, setTemplateColumns] = React.useState(props.templateModel.data)

    useEffect(() => {
        setTemplateColumns(props.templateModel.data)
    }, [props.templateModel])

    if (!props.templateModel.data.length) {
        return (<><LinearProgress /></>)
    }

    return (
        <Grid item xs={12} md={6}>
            <List dense={false}>
                {templateColumns.map((item) => (
                    <TemplateColumnListItem item={item}/>
                ))}
            </List>
        </Grid>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(TemplateColumnNaming);