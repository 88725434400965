import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { round, convertDecimalToHoursToHourly } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    timecardDay: {
        textAlign: 'center'
    }
}));

const Total = (props) => {
    const classes = useStyles();
    let [total, setTotal] = useState(0);
    let isViewMode = window.location.pathname.indexOf('viewTimecard') > -1;
    let isEditMode = window.location.pathname.indexOf('editTimecard') > -1;
    let renderDecimalFormatClock = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;

    useEffect(() => {
        let counter = 0;
        // view time card mode
        if (props.view && Object.keys(props.view).length > 0 && isViewMode) {
            props.view.model.forEach((row) => {
                if (row.week) {
                    let weekDay = row.week.find((e) => e.day === props.day.day)
                    counter = counter + weekDay.value;
                }
            });
            if (props.view.additionalRows) {
                props.view.additionalRows.forEach((row) => {
                    if (row[props.day.day]) {
                        counter = counter + row[props.day.day];
                    }
                });
            }
        } else if (props.edit && Object.keys(props.edit).length > 0 && isEditMode) { // edit mode
            props.edit.model.forEach((row) => {
                if (row[props.day.day]) {
                    counter = counter + row[props.day.day];
                }
            });
            if (props.timeCardRows) {
                props.timeCardRows.forEach((row) => {
                    if (row[props.day.day]) {
                        counter = counter + row[props.day.day];
                    }
                });
            }
        } else {
            props.timeCardRows.forEach((row) => {
                counter = counter + row[props.day.day];
            });
            props.tableRows.forEach((row) => {
                if (props.showOnBreakInSummary === true && row.activity === 'onbreak') {
                    counter = counter + row.week.find(day => day.day === props.day.day).value;
                } else if(props.showOnBreakInSummary === false && row.activity === 'onbreak') {
                    counter = counter + 0;
                } else {
                    // get the total for the current day of the job item
                    counter = counter + row.week.find(day => day.day === props.day.day).value;
                }
            });
        }
        setTotal(counter);
    }, [props.timeCardRows]);
    return (
        <TableCell className={classes.timecardDay} key={uuid()}>
            <strong>{
                renderDecimalFormatClock && props.timeCardType === 1
                    ? convertDecimalToHoursToHourly(round(total))
                    : round(total)}</strong>
        </TableCell>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(Total);