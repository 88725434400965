import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TemplateStep from './templateStepper';
import StacTemplateStep from './stacTemplateStepper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
    addButton: {
        background: "#004680",
        color: "white",
        margin: "10px 10px",
        width: 225
    }
}));

const TemplateAddModal = (props) => {
    const classes = useStyles();
    let [open, setOpen] = useState(false);
    let [openImport, setOpenImport] = useState(false);

    useEffect(() => {
        // each time the modal is opened, reset the model to its' default state + company id
        props.dispatch({
            type: 'set_template_model',
            payload: {
                company_id: props.login.user.account.id,
                data: false,
                groupByItemCode: false,
                name: false, 
                type: false
            }
        })
    }, [])

    const handleOpen = (e) => {
        setOpen(true);
    };
    const handleOpenImport = (e) => {
        setOpenImport(true);
    };

    const handleClose = (e) => {
        setOpen(false);
        props.dispatch({
            type: 'set_template_columns',
            payload: []
        })
    };

    const handleCloseImport = (e) => {
        setOpenImport(false);
        props.dispatch({
            type: 'set_template_columns',
            payload: []
        })
    };

    const handleEnter = (e) => {
        e.target.style.color = "white"
        e.target.style.background = '#004680'

    }
    const handleLeave = (e) => {
 
    }

    const hasFeature = (name) => {
        let features = props.appSettings.features;
        let hasFeature = false;
        if (features) {
            let feature = features.find((f) => { return f === name });
            if (!feature) {
                hasFeature = false;
            } else {
                hasFeature = true;
            }
        }
        return hasFeature;
    }
    return (
        <div className={classes.newTemplate}>
            <Button onMouseEnter={handleEnter} type="button" className={classes.addButton} onClick={handleOpen}>
                Add Template
            </Button>
            <Button onMouseEnter={handleEnter} type="button" className={classes.addButton} onClick={handleOpenImport}>
                Import Stac Template
            </Button>
            <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} scroll="paper" aria-labelledby="new-export-template">
                <DialogContent>
                    <TemplateStep setTemplateList={props.setTemplateList} setOpen={setOpen}/>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth={true} maxWidth={'md'} open={openImport} onClose={handleCloseImport} scroll="paper" aria-labelledby="new-stac-template">
                <DialogContent>
                    <StacTemplateStep setTemplateList={props.setTemplateList} setOpen={setOpenImport}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(TemplateAddModal);
