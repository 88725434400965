import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import SubmitTimecardButton from './timecards/submit';
import Table from './timecards/table';
import Exports from './timecards/exports';
import Actions from './timecards/actions';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import CalendarMonthIcon from '@material-ui/icons/DateRange';
import axiosInstance from '../../utilities/axios';
import History from './timecards/history';

import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import { handleLogout, requestHeaderConfig } from '../../utilities';
const { RangePicker } = DatePicker;

const useStyles = makeStyles((theme) => ({
    rangeSelector: {
        maxWidth: 320,
        display: "flex",
        justifyContent: "space-evenly"
    },
    rangePicker: {
        maxWidth: 320,
        display: "flex",
    },
    dateRange: {
        marginTop: 13,
        minWidth: 115
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));


const TimeCardReport = (props) => {
    const classes = useStyles();
    let startDay = props.login.settings.startDay;
    let [dates, setDates] = useState(false);
    let [selectionModel, setSelectionModel] = useState([]);
    let [useDefaultDateSelectionMode, setDefaultDateSelectionMode] = useState(true);
    let [userList, setUserList] = useState([]);
    
    let isBiWeekly = props.appSettings && props.appSettings.weeklyReports && props.appSettings.weeklyReports.enabled ? !props.appSettings.weeklyReports.enabled : true;

    const handleImport = async (login) => {
        return await axiosInstance.get('/import', {
          params: {
              companyId: login.user.company.id
            },
            ...requestHeaderConfig(login.authToken)
          })
          .then(function (response) {
              return response.data;
          })
          .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401 && error.response.data && error.response.data.redirectUrl) {
                    handleLogout(error.response.data.redirectUrl, props)
                }
            }
          });
    };

    useEffect(() => {
        if (useDefaultDateSelectionMode) {
            let cachedDateRangeIsAvailable = false
            if (sessionStorage.getItem('startRange') && sessionStorage.getItem('endRange')) {
                cachedDateRangeIsAvailable = true;
            }
            if (Object.keys(props.appSettings).length === 0) {
                props.dispatch({
                    type: 'set_settings',
                    payload: props.login.settings
                })
            }
            let startEndDates = getStartEndDates(startDay, false, false, null, null, isBiWeekly, cachedDateRangeIsAvailable)
            props.dispatch({
                type: 'set_application_page',
                payload: 'Timecards'
            });
            props.dispatch({
                type: 'set_timecard_report_date_range',
                payload: startEndDates
            });
            setDates(startEndDates);
        }
    }, [useDefaultDateSelectionMode])

    useEffect(async () => {
        if (!userList.length) {
            let users = await handleImport(props.login) || false;
            setUserList(users)
        }
    }, [])

    const getDaysBetweenDates = function (startDate, endDate) {
        var now = startDate.clone(), dates = [];

        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format('MM/DD/YYYY'));
            now.add(1, 'days');
        }
        return dates;
    };

    const getStartEndDates = (calendarStartDay, prevWeek, nextWeek, startMoment, endMoment, isBiWeekly, cachedDateRangeIsAvailable) => {
        let days = [],
            noDaysInWeek = 7,
            label = "",
            diff;
        // collect the last 7 days
        for (let x = 0; x < 7; x++) {
            days.push(moment().subtract(x, 'days'));
        }

        // on initial set
        if (!prevWeek && !nextWeek) {
            if (cachedDateRangeIsAvailable) {
                // if cached date range is available, use it.
                // ex: 08/27/2023 00:00:00
                startMoment = moment(sessionStorage.getItem('startRange'), 'MM/DD/YYYY HH:mm:SS');
                // ex: 09/09/2023 23:59:99
                endMoment = moment(sessionStorage.getItem('endRange'), 'MM/DD/YYYY HH:mm:SS');
            } else {
                for (let y = 0; y < 7; y++) {
                    // when the most recent calendar start day is found, this is our week start
                    if (days[y].toString().split(' ')[0] === calendarStartDay) {
                        startMoment = days[y].startOf('day');
                        // calculate the difference been our week start and today to get the week end day.
                        diff = moment().diff(startMoment, 'days')
                        let numDaysUntilEndWeek;
                        // console.log('Week start day was '+ diff +' days ago. You need to add ' + ((noDaysInWeek - diff) - 1) + ' days onto today to get the end day');
                        numDaysUntilEndWeek = ((noDaysInWeek - diff) - 1);
                        endMoment = moment().add(numDaysUntilEndWeek, 'days').endOf('day');
                    }
                }
            }
            
            // if bi-weekly, subtract another 7 days from the start moment, but only if the cached date range is not available
            if (isBiWeekly && !cachedDateRangeIsAvailable) {
                startMoment.subtract(7, 'days');
            }
        } else if (prevWeek) {
            // if switching date range, we already know our start and end days
            if (isBiWeekly) {
                startMoment.subtract(14, 'days');
                endMoment.subtract(14, 'days');
            } else {
                startMoment.subtract(7, 'days');
                endMoment.subtract(7, 'days');
            }
        } else if (nextWeek) {
            if (isBiWeekly) {
                startMoment.add(14, 'days');
                endMoment.add(14, 'days');
            } else {
                startMoment.add(7, 'days');
                endMoment.add(7, 'days');
            }
        }
        label = startMoment.format('MMM DD') + ' - ' + endMoment.format('MMM DD');

        return {
            startDay: startMoment.unix(),
            endDay: endMoment.unix(),
            label: label,
            startMoment: startMoment, // use to increment/decrement date
            endMoment: endMoment,
            weekDates: getDaysBetweenDates(startMoment, endMoment)
        };
    };

    const cacheDateRange = (startEndDates) => {
        sessionStorage.setItem('startRange', startEndDates.startMoment.format('MM/DD/YYYY HH:mm:SS'));
        sessionStorage.setItem('endRange', startEndDates.endMoment.format('MM/DD/YYYY HH:mm:SS'));
    }

    const handleNext = () => {
        let startEndDates = getStartEndDates(startDay, false, true, props.reportDateRanges.startMoment, props.reportDateRanges.endMoment, isBiWeekly);
        cacheDateRange(startEndDates)
        props.dispatch({
            type: 'set_timecard_report_date_range',
            payload: startEndDates
        });
        props.dispatch({
            type: 'set_bulkActions',
            payload: []
        });
    };
    const handlePrev = () => {
        let startEndDates = getStartEndDates(startDay, true, false, props.reportDateRanges.startMoment, props.reportDateRanges.endMoment, isBiWeekly);
        cacheDateRange(startEndDates)
        props.dispatch({
            type: 'set_timecard_report_date_range',
            payload: startEndDates
        });
        props.dispatch({
            type: 'set_bulkActions',
            payload: []
        });
    };
    const handleRange = (range) => {
        if (range) {
            let startMoment = range[0].startOf('day');
            let endMoment = range[1].endOf('day');
            let startEndDates = {
                startDay: startMoment.unix(),
                endDay: endMoment.unix(),
                label: startMoment.format('MMM DD') + ' - ' + endMoment.format('MMM DD'),
                startMoment: startMoment, // use to increment/decrement date
                endMoment: endMoment,
                weekDates: getDaysBetweenDates(startMoment, endMoment)
            }

            props.dispatch({
                type: 'set_timecard_report_date_range',
                payload: startEndDates
            });
            props.dispatch({
                type: 'set_bulkActions',
                payload: []
            });
            // setDates(startEndDates);
        }
    };

    if (!dates) {
        return (<></>)
    }
    if (props.login && window.Appcues && props.login.user && props.login.user.firstName !== 'ServiceTrade') {
        let user = props.login.user;
        let firstName = user.firstName;
        let lastName = user.lastName;
        window.Appcues.page();
        window.Appcues.identify(`${user.firstName} ${user.lastName}`, {
            firstName,
            lastName,
            companyName: user.company.name,
            name: `${firstName} ${lastName}`,
            platform: 'Timecard',
        }); 
    }
    
    return (
        <>
            <Container maxWidth="lg">
                <Grid container direction="row" justify="space-between" spacing={6} alignItems="center">
                    <Grid item xs={5} >
                        <SubmitTimecardButton />
                    </Grid>
                    <Grid item xs={7} className={useDefaultDateSelectionMode ? classes.rangeSelector : classes.rangePicker}>
                        {useDefaultDateSelectionMode && (
                            <>
                                <IconButton aria-label="calender" className={classes.rangePickerIcon} onClick={() => {setDefaultDateSelectionMode(false)}}>
                                    <CalendarMonthIcon/>
                                </IconButton>
                                <IconButton aria-label="prev" className={classes.left} onClick={handlePrev}>
                                    <ChevronLeftIcon />
                                </IconButton>
                                <Box className={classes.dateRange}>
                                    <strong>{props.reportDateLabel}</strong>
                                </Box>
                                <IconButton aria-label="next" className={classes.right} onClick={handleNext}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </>
                        )}
                        {!useDefaultDateSelectionMode && (
                            <>
                                <IconButton aria-label="calender" className={classes.rangeSelectIcon} onClick={() => {setDefaultDateSelectionMode(true)}}>
                                    <LinearScaleIcon/>
                                </IconButton>
                                <RangePicker
                                    format={'MMM DD'}
                                    allowClear={false}
                                    defaultValue={[dates.startMoment, dates.endMoment]}
                                    onChange={(rangeMoments) => {handleRange(rangeMoments)}}
                                        dateRender={current => {
                                            const style = {};
                                            if (current.date() === 1) {
                                                style.border = '1px solid #1890ff';
                                                style.borderRadius = '50%';
                                            }
                                            return (
                                                <div className="ant-picker-cell-inner" style={style}>
                                                    {current.date()}
                                                </div>
                                            );
                                        }}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table selectionModel={selectionModel}/>
                    <Exports setSelectionModel={setSelectionModel} userList={userList}/>
                    <History/>
                </TableContainer>
                <Actions setSelectionModel={setSelectionModel}/>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
        reportDateLabel: state.reportDateRanges.label
    };
};
export default connect(mapStateToProps)(TimeCardReport);