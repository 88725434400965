import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import StartDayModal from './startDayModal'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));


const StartDay = (props) => {
  let [model, setModel] = useState(props.appSettings.startDay) // set default state after login
  let [showCalendar, setShowCalendar] = useState(false)
  const classes = useStyles();
  const handleToggle = (field) => {
    setModel(field.target.value);
    axiosInstance.post('/settings', {
      companyId: props.login.user.company.id,
      key: 'startDay',
      value: field.target.value
    }, requestHeaderConfig(props.login.authToken)).then((response) => {
      console.warn('here res')
      let updatedSettingsObj = response.data;
      props.dispatch({
        type: 'set_settings',
        payload: updatedSettingsObj
      })
      console.log(updatedSettingsObj, "udpdated settings")
    }).catch((e) => {
      console.warn(e, 'Could not save settings for : startDay');
    });
  };

  const handleClose = () => {
    setShowCalendar(false)
  }


  if (!model) {
    return (<></>);
  }
  return (
    <>


      <Grid item sm>
        <Paper>
          <MuiAlert style={{ marginBottom: 20 }} severity="info">Your current start day is <strong>
            {props.appSettings.startDay === "Sun" && props.appSettings.startDay + "day"}
            {props.appSettings.startDay === "Mon" && props.appSettings.startDay + "day"}
            {props.appSettings.startDay === "Tue" && props.appSettings.startDay + "sday"}
            {props.appSettings.startDay === "Wed" && props.appSettings.startDay + "nesday"}
            {props.appSettings.startDay === "Thu" && props.appSettings.startDay + "sday"}
            {props.appSettings.startDay === "Fri" && props.appSettings.startDay + "day"}
            {props.appSettings.startDay === "Sat" && props.appSettings.startDay + "urday"}
          </strong></MuiAlert>
          {showCalendar
            && <MuiAlert style={{ marginBottom: 20 }} severity="error">
              Changing your start day while having existing timecards can affect your exporting format
            </MuiAlert>}
        </Paper>
        {showCalendar ? <Button onClick={handleClose} color='secondary' variant="contained"  >Close</Button> : <StartDayModal setShowCalendar={setShowCalendar} />}
        {showCalendar && <Paper className={classes.paper}>
          <List subheader={<ListSubheader>Calendar Start Day</ListSubheader>} className={classes.root}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="calendar" name="startDay" value={model} onChange={handleToggle}>
                <FormControlLabel value="Sun" control={<Radio color="primary" />} label="Sunday" />
                <FormControlLabel value="Mon" control={<Radio color="primary" />} label="Monday" />
                <FormControlLabel value="Tue" control={<Radio color="primary" />} label="Tuesday" />
                <FormControlLabel value="Wed" control={<Radio color="primary" />} label="Wednesday" />
                <FormControlLabel value="Thu" control={<Radio color="primary" />} label="Thursday" />
                <FormControlLabel value="Fri" control={<Radio color="primary" />} label="Friday" />
                <FormControlLabel value="Sat" control={<Radio color="primary" />} label="Saturday" />
              </RadioGroup>
            </FormControl>
          </List>
        </Paper>}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state
  };
};
export default connect(mapStateToProps)(StartDay);
