import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    }
  }));

  const DeleteJobItemButton = (props) => {
    const classes = useStyles();
    useEffect(() => {
        if (props.jobModal && props.model.action) {
            console.log(props.model, 'The model to delete ' + props.model.index);
        }
    }, [props.jobModal]);

    
    return (
        <>
            <IconButton aria-label="delete" className={classes.margin} onClick={e => { props.setShow(false);}}>
                <DeleteIcon fontSize="small" />
            </IconButton>
            {props.jobModal && (
                <CircularProgress size={25}/>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(DeleteJobItemButton);