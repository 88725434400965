import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SelectOffice from './selectOffice';
import Emails from './emails';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import axiosInstance from '../../utilities/axios';

import { requestHeaderConfig } from '../../utilities';
import SelectEmail from './selectEmail';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(1),
    },
    isDefault: {
        marginLeft: 10,
        fontSize: 15
    },
    headIcon: {
        fontSize: 15,
        marginTop: 4
    },
    description: {
        alignItems: 'left'
    }
  }));

  const NotificationEmails = (props) => {
    const classes = useStyles();
    
    const handleAdd = () => {
        let initialEmailArr = props.appSettings.emails || [];
        var emailArr = [{
            id: uuid(),
            email: props.selectedEmail,
            office: props.selectedOffice,
            data: props.officeList.filter(e => e.name === props.selectedOffice)[0]
        }, ...initialEmailArr];
        axiosInstance.post('/settings', {
                companyId: props.login.user.company.id,
                key: 'emails',
                value: emailArr
            }, requestHeaderConfig(props.login.authToken)).then(response => {
                let updatedSettingsObj = response.data;
                props.dispatch({
                    type: 'set_settings',
                    payload: updatedSettingsObj
                })
            }).catch((e) => {
                console.warn(e, 'Could not save settings for : Notification Emails');
            });
    };

    return (
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell style={{width: '20%'}}>Office</TableCell>
                            <TableCell align="left">Email</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <Emails/>
                        </TableBody>
                        <TableFooter align="left">
                            <Grid className={classes.footer} style={{flexWrap: "nowrap"}} container direction="row" justify="space-between" alignItems="center">
                                <SelectOffice />
                                <>
                                    <SelectEmail />
                                    <Button color="primary" style={{paddingTop: "25px"}} onClick={handleAdd}>Add</Button>
                                </>
                            </Grid>
                        </TableFooter>
                    </Table>
                </TableContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(NotificationEmails);