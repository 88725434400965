export const TEMPLATE_REQUEST = 'TEMPLATE_REQUEST'
export const TEMPLATE_REQUEST_SUCCESS = 'TEMPLATE_REQUEST_SUCCESS'
export const TEMPLATE_REQUEST_FAIL = 'TEMPLATE_REQUEST_FAIL'

export const TEMPLATE_REQUEST_UPDATE = 'TEMPLATE_REQUEST'
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS'

export const TEMPLATE_DELETE_REQUEST = 'TEMPLATE_DELETE_REQUEST'
export const TEMPLATE_DELETE_SUCCESS = 'TEMPLATE_DELETE_SUCCESS'
export const TEMPLATE_DELETE_FAIL = 'TEMPLATE_DELETE_FAIL'

export const LABOR_HEADER_UPDATE = 'LABOR_HEADER_UPDATE'

export const WEEKDAY_ORDER_SETUP = 'WEEKDAY_ORDER_SETUP'

export const UPDATE_UNIVERSAL_HEADER = "UPDATE_UNIVERSAL_HEADER"
