import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Table from '@material-ui/core/Table';
import TemplateModal from './templateModal';
import TemplateAddModal from './templateAddModal';
import Button from '@material-ui/core/Button';
import axiosInstance from '../../utilities/axios';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TemplateEditModal from './templateEditModal'
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import { connect, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import { getTemplate, deleteTemplate } from '../../actions/templateActions';
import { requestHeaderConfig } from '../../utilities';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(1),
    },
    button: {
        background: "#004680"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        marginRight: 1000,
        marginTop: 20
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    defaultTemp: {
        paddingRight: 20,
        display: 'flex'
    }

}));

const TemplateSettings = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [weekDayOrder, setWeekDayOrder] = useState([])
    const [templateList, setTemplateList] = useState([]);

    const deleteTemplate = async (templateId) => {
        try {
            const { data } = await axiosInstance.delete('/template/' + templateId, requestHeaderConfig(props.login.authToken));
            dispatch({type: 'TEMPLATE_DELETE_SUCCESS', payload: data})
        } catch (error) {
            dispatch({type: 'TEMPLATE_DELETE_FAIL', payload: error.message})
        }
    }
    const deleteHandler = (templateId) => {
        deleteTemplate(templateId);
        setTemplateList(templateList.filter((item) => templateId !== item._id))
    }

    const handleOpenEditModal = (template) => {
        props.dispatch({
            type: 'set_template_model',
            payload: template
        })
        props.dispatch({
            type: 'set_open_edit_template_model',
            payload: true
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        dispatch(getTemplate(props))

        let payload = {
            params: {},
            ...requestHeaderConfig(props.login.authToken)
        }
        await axiosInstance.get('/template/', payload).then((response) => {
            let templates = response.data;
            setTemplateList(templates);

            if (props && props.appSettings && props && props.appSettings.weekDayOrder && props.appSettings.weekDayOrder.length) {
                let mappedWeekDayOrder = [];
                for (let i = 0; i < props.appSettings.weekDayOrder.length; i++) {
                    let item = props.appSettings.weekDayOrder[i];
                    let header = "";
                    switch (item.day) {
                        case "Sun":
                            header = "Sunday";
                            break;
                        case "Mon":
                            header = "Monday";
                            break;
                        case "Tue":
                            header = "Tuesday";
                            break;
                        case "Wed":
                            header = "Wednesday";
                            break;
                        case "Thu":
                            header = "Thursday";
                            break;
                        case "Fri":
                            header = "Friday";
                            break;
                        case "Sat":
                            header = "Saturday";
                            break;
                        default:
                            break;
                    }
                    mappedWeekDayOrder.push({ day: item.day, header: header, index: '-' });
                }
                setWeekDayOrder(mappedWeekDayOrder);
            }
        })

        // linting is being caused by not passing in prop. Not needed here due to it causing too many re-renders
        // eslint-disable-next-line  
    }, [props.imported, dispatch, props.appSettings.weekDayOrder])

    return (
        <TableContainer>
            <TemplateAddModal setTemplateList={setTemplateList} />
            <TemplateEditModal setTemplateList={setTemplateList} />
            <Table style={{marginLeft: 10}} size='small' className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Template Name</TableCell>
                        <TableCell align="left">Template Type</TableCell>
                        <TableCell align="left">Grouped</TableCell>
                        <TableCell align="left">View More</TableCell>
                        <TableCell style={{paddingLeft: 25}} align="left">Edit</TableCell>
                        <TableCell align="left">Delete</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {templateList.filter((item) => { return item.data }).map((item) => {
                        return <TableRow key={uuid()}>

                            <TableCell component="th" scope="row">
                                <div style={{ paddingLeft: 15 }} >
                                    {item.name}
                                </div>

                            </TableCell>

                            <TableCell component="th" scope="row">
                                <div style={{ paddingLeft: 15 }} >
                                    {item.type}
                                </div>
                            </TableCell>

                            <TableCell component="th" scope="row">
                                {item.groupByItemCode && item.groupByItemCode !== 'UNGROUPED' ? <Check style={{ marginLeft: 8 }} /> : <div style={{ paddingLeft: 15 }} >X</div>}
                            </TableCell>

                            <TableCell component="th" scope="row">
                                <TemplateModal templateMeta={item} templateName={item.name} templateData={item.data} weekDayOrder={weekDayOrder} temp={item.type} groupByItemCode={item.groupByItemCode} grouped={item.groupByItemCode} />
                            </TableCell>

                            {item.company_id !== 1 && item.company_id !== 2
                                ? <TableCell component="th" scope="row">
                                    <Button type="button" className={classes.addButton} onClick={() => {
                                        return handleOpenEditModal(item);
                                    }}><EditIcon style={{ marginRight: 15 }} /></Button>
                                </TableCell>
                                : <TableCell component="th" scope="row"></TableCell>}

                            {item.company_id !== 1 && item.company_id !== 2
                                ? <TableCell style={{paddingRight: 50}} component="th" scope="row">
                                    <Button onClick={() => deleteHandler(item._id)}>
                                        <DeleteIcon style={{ marginRight: 20 }} />
                                    </Button>
                                </TableCell>
                                : <TableCell component="th" scope="row">
                                </TableCell>}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(TemplateSettings);
