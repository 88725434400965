import React from 'react'
import { useSelector } from 'react-redux'
import { Line } from 'react-chartjs-2'

const LineGraph = (props) => {

    const analytics = useSelector((state) => state.analytics)
    const { clickActions,  } = analytics
    
    return (
        <div>
            <Line
                data={{
                    labels: Object.keys(clickActions),
                    datasets: [{
                        label: 'All Events',
                        data: Object.values(clickActions).map(item => item.Total),
                        backgroundColor: 'green',
                        borderColor: 'green',
                        borderWidth: 1,
                    }, {
                        label: "All Submissions",
                        data: Object.values(clickActions).map(item => item.Submission),
                        backgroundColor: '#FBB034',
                        borderColor: '#FBB034',
                        borderWidth: 1
                    }, {
                        label: "Navigation only Events",
                        data: Object.values(clickActions).map(item => item.Navigation),
                        backgroundColor: '#004680',
                        borderColor: '#004680',
                        borderWidth: 1
                    }],
                }}
                height={300}
                width={100}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{ ticks: { beginAtZero: true } }]
                    }
                }}
            />
        </div>
    )
}

export default LineGraph
