import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Settings from './dashboard/settings';
import Logout from './dashboard/logout';
import LogoutPage from './dashboard/logoutPage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import TimeCardContainer from './timecard/timeCardContainer';
import TimeCardReport from './reports/timeCardReport';
import ViewTimeCard from './timecard/viewTimeCard';
import EditTimeCard from './timecard/editTimeCard';
import ViewRedirect from './dashboard/view';
import EditRedirect from './dashboard/edit';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewAnalytics from './timecard/viewAnalytics'
// import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import AnalyticsIcon from '@material-ui/icons/Assessment';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ListIcon from '@material-ui/icons/List';
import AlarmIcon from '@material-ui/icons/AlarmAdd';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Alert from './alert';
import Unsubscribe from './unsubscribe';
import Audit from './reports/auditLogReport';
import Protected from './protected';
import { requestHeaderConfig } from '../utilities';
import axiosInstance from '../utilities/axios';

function Copyright() {
  let version = process.env.REACT_APP_VERSION;
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://servicetrade.com">
        ServiceTrade
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'} <br/>
      <Typography variant="overline">
        V. {version.slice(0, 5)}
      </Typography>
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  avatar: {
    textAlign: 'left'
  },
  avatarName: {
    marginTop: 10
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarClosed: {
    height: 64
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Dashboard = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.menuOpen);
  let [pageTitle, setPageTitle] = useState('Home');
  const handleDrawerOpen = () => {
    props.dispatch({
      type: 'set_menu_open',
      payload: true
    });
  };

  const onMouseOver = (e) => {
    e.target.style.color = "#004680"
  }

  const onMouseLeave = (e) => {
    e.target.style.color = "black"
  }

  const handleDrawerClose = () => {
    props.dispatch({
      type: 'set_menu_open',
      payload: false
    });
  };

  const hasAccess = () => {
    return props.login.isAdmin || props.login.canManage;
  }
  const canSubmitApproveRequestChangesOnTimecards = () => {
    return props.login.isAdmin || props.login.canSubmit;
  }
  const isNotAdmin = () => {
    return !props.login.isAdmin;
  }
  const isTech = () => {
    return props.login.user.isTech || props.login.user.isHelper;
  }
  const canSubmit = () => {
    return props.login.canSubmit;
  }
  const analyticsAccess = () => {
    return props.login.isSuperAdmin
  }

  useEffect(() => {
    setPageTitle(props.applicationPage);
  }, [props.applicationPage])
  useEffect(() => {
    setOpen(props.menuOpen);
  }, [props.menuOpen])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // set the initial tech so it is defined if / when page reloads

    const setInitialTechnician = (data) => {
      if (data && data.userList && props.login.isAdmin) {
        // find and return the first user that is a tech
        return data.userList.find((u) => u.isTech || u.isHelper);
      } else {
        // return the current logged in user, since current user is a tech
        return props.login.user;
      }
    }
      if (props.listOfAccountUsers.length === 0 || !props.listOfAccountUsers) {
          await axiosInstance.get('/listUsers', {
            params: {
              companyId: props.login.user.company.id
            },
            ...requestHeaderConfig(props.login.authToken)
          })
          .then(function (response) {
              props.dispatch({
                  type: 'set_technician',
                  payload: setInitialTechnician(response.data)
              });
              props.dispatch({
                  type: 'set_users',
                  payload: response.data.userList
              });
          })
          .catch(function (error) {
            console.log(error, ': An error has occured while retrieving the settings');
          });
      }
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          {!open && <MenuIcon onClick={handleDrawerOpen} />}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {pageTitle}
          </Typography>
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>
      {open && <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={open ? classes.toolbarIcon : classes.toolbarClosed}>
          {open && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <>
                <Avatar alt={props.login.user.name} src={props.login.user.avatar.medium} className={classes.avatar} />
                <Typography className={classes.avatarName} variant="overline" display="block" gutterBottom>
                  {props.login.user.firstName}
                </Typography>
              </>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
          )}
        </div>
        <Divider />
        <List>
          {(hasAccess() || isTech() || canSubmitApproveRequestChangesOnTimecards()) && (
            <ListItemLink href="/submitTimecard">
              <ListItem button>
                <ListItemIcon>
                  <AlarmIcon color="secondary" />
                </ListItemIcon>
                <ListItemText onMouseEnter={onMouseOver} onMouseLeave={onMouseLeave} primary="Submit Timecard" />
              </ListItem>
            </ListItemLink>)}
          {(isNotAdmin() && isTech()) && (
            <ListItemLink href="/myTimecards">
              <ListItem button>
                <ListItemIcon>
                  <ScheduleIcon color="secondary" />
                </ListItemIcon>
                <ListItemText onMouseEnter={onMouseOver} onMouseLeave={onMouseLeave} primary="My Timecards" />
              </ListItem>
            </ListItemLink>)}
          {(canSubmitApproveRequestChangesOnTimecards()) && (
            <ListItemLink href="/timecards">
              <ListItem button>
                <ListItemIcon>
                  <ListIcon color="secondary" />
                </ListItemIcon>
                <ListItemText onMouseEnter={onMouseOver} onMouseLeave={onMouseLeave} primary="Review Timecards" />
              </ListItem>
            </ListItemLink>)}
        </List>
        <Divider />
        {hasAccess() && (
          <ListItemLink href="/settings">
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon color="secondary" />
              </ListItemIcon>
              <ListItemText onMouseEnter={onMouseOver} onMouseLeave={onMouseLeave} primary="Settings" />
            </ListItem>
          </ListItemLink>)}
        {hasAccess() && (
          <ListItemLink href="/audit">
            <ListItem button>
              <ListItemIcon>
                <DescriptionIcon color="secondary" />
              </ListItemIcon>
              <ListItemText onMouseEnter={onMouseOver} onMouseLeave={onMouseLeave} primary="Logs" />
            </ListItem>
          </ListItemLink>)}
        <Logout />
        <ListItem button>
          <Typography variant="overline">
                {props.login.user.account.name}
          </Typography>
        </ListItem>
      </Drawer>}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Alert />
        <BrowserRouter>
          <Switch>
            <Route path="/dashboard">
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/analytics">
              <Box pt={4}>
                <ViewAnalytics />
              </Box>
            </Route>
            <Route path="/submitTimecard">
              <TimeCardContainer />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/settings">
              <Protected props={props}>
                <Settings />
                <Box pt={4}>
                  <Copyright />
                </Box>
              </Protected>
            </Route>
            <Route path="/viewTimecard">
              <ViewTimeCard />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/viewMyTimecard">
              <ViewTimeCard />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/editTimecard">
              <EditTimeCard />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/myTimecards">
              <TimeCardReport />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/timecards">
              <Protected>
                <TimeCardReport />
                <Box pt={4}>
                  <Copyright />
                </Box>
              </Protected>
            </Route>
            <Route path="/viewRedirect">
              <ViewRedirect />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/editRedirect">
              <EditRedirect />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/unsubscribe">
              <Unsubscribe />
              <Box pt={4}>
                <Copyright />
              </Box>
            </Route>
            <Route path="/audit">
              <Protected>
                <Audit />
                <Box pt={4}>
                  <Copyright />
                </Box>
              </Protected>
            </Route>
            <Route path="/logout">
              <Box pt={4}>
                <LogoutPage />
              </Box>
            </Route>
            {(hasAccess() || isTech()) && (
              <Route path="/">
                <TimeCardReport />
                <Box pt={4}>
                  <Copyright />
                </Box>
              </Route>
            )}
            {(!isNotAdmin() && !isTech()) && (
              <Route path="/">
                <Box pt={4}>
                  <Copyright />
                </Box>
              </Route>
            )}
          </Switch>
        </BrowserRouter>
      </main>
    </div>
  );
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(Dashboard);