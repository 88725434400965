import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import axiosInstance from "../utilities/axios";
import Box from '@mui/material/Box'
import EulaModal from './timecard/eulaModal';
import CircularProgress from '@mui/material/CircularProgress';
import { cleanseCachedStartAndEndDates } from "../utilities";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://servicetrade.com/">
        ServiceTrade
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#004680"
  },

}));


const Authorize = (props) => {
    const classes = useStyles();

    const [isLoading, setLoading] = useState(false);
    const [EulaAgreementRequired, setEulaArgumentRequired] = useState(false)
    cleanseCachedStartAndEndDates();
    const handleLogout = async () => {
        const domain = process.env.REACT_APP_AUTH0_DOMAIN;
        const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
        const returnTo = process.env.REACT_APP_AUTH0_RETURN_TO;
        window.location.href = `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`


        props.dispatch({
            type: 'exit'
        });
    }

    useEffect(async () => {
        let query = window.location.search.split('=')[1]
        await axiosInstance.get('/login?data=' + query).then((user) => {
            try {
                var userModel = user.data;
                if (userModel && userModel.userList && userModel.userList.length > 600) {
                    userModel.userList = [];
                }
                props.dispatch({
                    type: 'initialize',
                    payload: userModel
                });
                props.dispatch({
                    type: 'set_technician',
                    payload: userModel.isAdmin && userModel.userList && userModel.userList.length > 0 ? userModel.userList[0] : userModel.user
                });
            
                if (userModel.userList) {
                    props.dispatch({
                        type: 'set_users',
                        payload: userModel.userList
                    });
                }
            
                props.dispatch({
                    type: 'set_settings',
                    payload: userModel.settings
                })

                setTimeout(() => {
                    if (typeof window.zenDeskPrefillLoggedIn === "function") {
                        window.zenDeskPrefillLoggedIn({"name": userModel.user.name, "email": userModel.user.email});
                    }
                    window.location.href = '/';
                }, 5000)
                return userModel;

            } catch(e) {
                console.log(e, 'We have encountered an unexpected error.')
            }
            
        }).catch((error) => {
            let statusCode = false;

            if (error && error.response && error.response.status) {
                statusCode = error.response.status;
            
                switch (statusCode) {
                    case 403:
                        setEulaArgumentRequired(true)
                        setLoading(false);
                        break;
                    case 400:
                        window.location.href = '/error';
                        break;
                    default:
                        if (error && error.response) {
                            setTimeout(() => {
                                handleLogout();
                            }, 15000)
                        }
                        break;
                }
            } else {
                console.log(error, 'Response.Status: encountered a logout response');
                setTimeout(() => {
                    handleLogout();
                }, 15000)
            }
            
            return {}
        })
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    maxWidth: '400px',
                    margin: 'auto',
                    marginTop: '50px'
                }}
            >
                <div className={classes.paper}>
                    <CircularProgress/>
                    <Typography component="h1" variant="h5">Welcome</Typography>
                    <img loading="true" type="loading" />
                    <form className={classes.form} noValidate>
                        {!EulaAgreementRequired ? <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isLoading}
                        >
                            Authorizing
                        </Button> : <EulaModal
                            EulaAgreementRequired={EulaAgreementRequired}
                            setEulaArgumentRequired={setEulaArgumentRequired}
                        />}
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </Box>
        </Container>
    );
}

const mapStateToProps = state => ({
  account: state
});

export default connect(mapStateToProps)(Authorize);
