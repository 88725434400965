import React, { useState, useEffect } from 'react'
import axiosInstance from '../../utilities/axios';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import Backdrop from '@material-ui/core/Backdrop';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TableContainer from '@material-ui/core/TableContainer';
import { requestHeaderConfig } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    modal: {
        margin:'auto',
        height: '80%',
        maxWidth: '50%',
        overflow: 'scroll'

     
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 25,
    },
    pos: {
        marginBottom: 12,
    },
    table: {
        minWidth: 650,
        paddingTop: 20
    },
    type: {
        marginBottom: 20
    },
    button: {
        background:"#004680",
        color: "white",
        paddingTop: 10,
        marginTop: 5
    }
}));


const TemplateModal = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [temp, setTemp] = useState([])
    const [updateTemp, setUpdateTemp] = useState()

    useEffect(() => {
        setUpdateTemp({
            name: props.templateName,
            type: props.temp,
            data: props.templateData,
            groupByItemCode: props.groupByItemCode,
            isStacConnector: props.templateMeta.isStacConnector,
            stacId: props.templateMeta.stacId,
            token: props.templateMeta.token
        })

        if (props.groupByItemCode) {
            setTemp([...props.templateData])
        } else if (!props.groupByItemCode) {
            let weekDayOrder = props.weekDayOrder && props.weekDayOrder.length ? props.weekDayOrder : props.appSettings.weekDayOrder;
            setTemp([...props.templateData, ...weekDayOrder])
        }

       
    }, [props.weekDayOrder, props.templateData, props.templateName, props.temp, props.groupByItemCode, props.templateMeta])

    const handleSave = () => {
        let tempType = props.temp
        if (props.temp === "Labor") {
            tempType = "laborDefault"
        } else if (props.temp === "Clock") {
            tempType = "clockDefault"
        }
        axiosInstance.post('/settings', {
            companyId: props.login.user.company.id,
            key: tempType,
            value: updateTemp
        }, requestHeaderConfig(props.login.authToken)).then(response => {
            let updatedSettingsObj = response.data;
            props.dispatch({
                type: 'set_settings',
                payload: updatedSettingsObj
            });
            props.dispatch({
                type: 'set_alert',
                payload: {
                    active: true,
                    message: 'Default Template Updated'
                }
            });
        }).catch((e) => {
            console.warn(e, 'Could not save settings for : Expense Code');
        });
    }


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            
            <Button type="button" onClick={handleOpen}>
               <VisibilityIcon style={{marginRight: 10}} />
            </Button>
          
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <strong>{props.templateName}</strong>
                        </Typography>

                        {updateTemp && !updateTemp.isStacConnector && (
                        <>
                            <Typography className={classes.type} variant="body2" component="p">
                                type: <strong>{props.temp}</strong> 
                            </Typography>

                            <Typography className={classes.type} variant="body2" component="p">
                                Grouped: {props.groupByItemCode ? <strong>Grouped</strong> : <strong>Not Grouped</strong>}
                            </Typography>
                        </>)}
                        
                        
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>{updateTemp && !updateTemp.isStacConnector && ("Column")}{updateTemp && updateTemp.isStacConnector && ("Stac ID")}</strong></TableCell>
                                        <TableCell><strong>{updateTemp && !updateTemp.isStacConnector && ("Header Name")}{updateTemp && updateTemp.isStacConnector && ("Token")}</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {updateTemp && updateTemp.isStacConnector && (
                                        <TableRow key={"1"}>
                                            <TableCell component="th" scope="row">
                                                {updateTemp.stacId}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {updateTemp.token}
                                            </TableCell>
                                        </TableRow>)}
                                    {temp && temp.map((item) => (
                                        <TableRow key={item._id}>
                                            <TableCell component="th" scope="row">
                                                {item.header}
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {item.alias}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Button className={classes.button} onClick={handleSave} type="button" >
                            Save as Default
                        </Button>
                    </div>
                </Fade>
            </Modal>
           
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(TemplateModal);
