import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
// import axios from 'axios';
import axiosInstance from '../../../utilities/axios';
import { DataGrid } from '@material-ui/data-grid';
import { limitByOffice } from '../../../utilities/index';
import moment from 'moment';
import QuickSearchToolbar from './utils/QuickSearchToolbar'
import { round, checkOfficeLimitSettings } from '../../../utilities';
import {isTechViewingHisOwnTimecards} from  '../../utilities/windowUtilities'
import ButtonGroup from '@mui/material/ButtonGroup';
import { v4 as uuid } from 'uuid';
import Backdrop from '@material-ui/core/Backdrop';
import { requestHeaderConfig } from '../../../utilities';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
  }));

function TimeCardTable(props) {
    const [searchText, setSearchText] = useState("");
    const classes = useStyles();
    let navi = useHistory();
    let [rows, setRows] = useState([]);
    let [data, setData] = useState([])
    let [showUnsubmittedLabor, setShowUnsubmittedLabor] = useState(false);
    let [showUnsubmittedClock, setShowUnsubmittedClock] = useState(false);
    let [showUnsubmittedExpenses, setShowUnsubmittedExpenses] = useState(false);
    let [showCheckboxSelection, setShowCheckboxSelection] = useState(true);
    let [loading, setIsLoading] = useState(true);
    let [showBackdrop, setShowBackdrop] = useState(false);
    let {selectionModel} = props;

    const hasAccess = () => {
        return props.login.isAdmin || props.login.canManage;
    }
    const hasFeature = (name, appSettings) => {
        let features = appSettings.features;
        let hasFeature = false;
        if (features) {
            let feature = features.find((f) => { return f === name });
            if (!feature) {
                hasFeature = false;
            } else {
                hasFeature = true;
            }
        }
        return hasFeature;
    }

    let settingsParams = {
        params: {
            companyId: props.login.user.company.id,
            office: props.login.user.location,
            reportDateRange: props.reportDateRanges
        },
        ...requestHeaderConfig(props.login.authToken)
    };

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = data.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field]);
            });
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        setRows(rows);
    }, [rows]);
    useEffect(() => {
        setShowBackdrop(props.isProcessing)
    }, [props.isProcessing]);

    const getTimecardById = async (id) => {
        let timecard = await axiosInstance.get('/timecard', {
            params: {
                id: id,
            },
            ...requestHeaderConfig(props.login.authToken)
        });
        props.dispatch({
            type: 'set_backdrop',
            payload: false
        });
        return timecard.data[0]
    }

    const openHistoryModal = async (timecard) => {
        props.dispatch({
            type: 'set_backdrop',
            payload: true
        });
        let response = await axiosInstance.get('/history', { params: { 
            id: timecard.row.id, 
        }, ...requestHeaderConfig(props.login.authToken)});
        props.dispatch({
            type: 'set_backdrop',
            payload: false
        });

        props.dispatch({
            type: 'set_history_modal',
            payload: {
                open: true,
                id: timecard.row.id,
                name: timecard.row.techName,
                history: response.data
            }
        });
    }

    const isTechTimecardScreen = () => {
        return window.location.pathname === '/myTimecards'
    };
    const isDashboard = () => {
        return window.location.pathname === '/';
    };

    const isRecordForCurrentTech = (model) => {
        return model.techEmail === props.login.user.email;
    };

    const setGrandTotal = (models) => {
        models.forEach(model => {
            let runningTotal = 0;
            if (model.additionalRows) {
                model.additionalRows.forEach(row => {
                    runningTotal += row.Mon;
                    runningTotal += row.Tue;
                    runningTotal += row.Wed;
                    runningTotal += row.Thu;
                    runningTotal += row.Fri;
                    runningTotal += row.Sat;
                    runningTotal += row.Sun;
                })
            }
            if (model.model) {
                model.model.forEach(job => {
                    runningTotal += job.weekTotal;
                })
            }
            model.grandTotals = runningTotal;
        })
        return models;
    }

    const processRows = ({data, headers}) => {
        let model = data.map((row) => {
            if (!row.id) {
                return false;
            }

            // split string by last name
            let name = row.techName.split(' ');
            row.lastName = name[name.length - 1];
            row.firstName = name[0];
            if (props.appSettings.sortByLastName && props.appSettings.sortByLastName.enabled) {
                row.officialName = row.lastName + ', ' + row.firstName;
            }
            return row;
        });

        // find out what is wrong with the logic
        if (headers?.unsubmitted) {
            setShowCheckboxSelection(false);
        } else {
            setShowCheckboxSelection(true);
        }

        if (checkOfficeLimitSettings(props)) {
            model = limitByOffice(model, props.login.user.location);
        }

        if (!headers?.unsubmitted) {
            model = setGrandTotal(model);
            if (props.appSettings.sortByLastName && props.appSettings.sortByLastName.enabled) {
                // sort model by last name (could also sort by the officialName)
                model = model.sort((a, b) => {
                    if (a.lastName < b.lastName) {
                        return -1;
                    }
                    if (a.lastName > b.lastName) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        setData(model);
        setRows(model);
    }

    const fetchTimeCards = async (reportDateRanges) => {
        try {
            if (reportDateRanges) {
                settingsParams.params.reportDateRange = reportDateRanges
            }
            if (showUnsubmittedExpenses) {
                settingsParams.params.showUnsubmittedExpenses = showUnsubmittedExpenses;
            }
            if (showUnsubmittedClock) {
                settingsParams.params.showUnsubmittedClock = showUnsubmittedClock;
            }
            if (showUnsubmittedLabor) {
                settingsParams.params.showUnsubmittedLabor = showUnsubmittedLabor;
            }

            await axiosInstance.get('/timecards', settingsParams).then(async (response) => {
                processRows({
                    data: response.data, 
                    headers: response.headers
                });
                props.dispatch({
                    type: 'set_timecard_report',
                    payload: response.data
                });
                setIsLoading(false)
            });
            
        } catch (error) {
            console.error(error)
        }
    };

    const getDefaultWeekDay = (startDay) => {
        var weekday = [{
            day: "Sun",
            value: 0
        }, {
            day: "Mon",
            value: 0
        }, {
            day: "Tue",
            value: 0
        }, {
            day: "Wed",
            value: 0
        }, {
            day: "Thu",
            value: 0
        }, {
            day: "Fri",
            value: 0
        }, {
            day: "Sat",
            value: 0
        }];
        // set the order of the week to match the set start day
        weekday.push.apply(weekday, weekday.splice(0, weekday.findIndex((week) => week.day === startDay)));
        return weekday;
    }

    const navigateEdit = (params) => {
        if (params.row.status !== 'Changes Requested') return false;
        navi.push('/editRedirect?key=' + params.row.id);
    }

    const navigateTo = async (params) => {
        props.dispatch({
            type: 'set_backdrop',
            payload: true
        });
        let timecard = await getTimecardById(params.row.id)
        // for every model (row) in the view
        timecard.model = timecard.model.map((model) => {
            let weekDay = getDefaultWeekDay(props.appSettings.startDay);
            // re-order the week days
            model.week = model.week.map((wModel, index, wArray) => {
                // update the value of the day that is already in the correct order with the matching day value in wArray
                weekDay[index].value = wArray.find(week => week.day === weekDay[index].day).value
                // replace the old, potentially incorrect week day with the new 
                return weekDay[index];
            });
            return model;
        });
        props.dispatch({
            type: 'set_view_timecard',
            payload: timecard
        });
        if (isTechTimecardScreen()) {
            navi.push('/viewMyTimecard');
        } else {
            navi.push('/viewTimecard');
        }
    }
    useEffect(async () => {
        if (loading) {
            await fetchTimeCards();
        }
    }, [])

    // each time the date ranges change, filter the rows
    useEffect(async () => {
        setIsLoading(true);
        setRows([]);
        await fetchTimeCards(props.reportDateRanges)
        setSearchText('');
    }, [props.reportDateRanges, showUnsubmittedLabor, showUnsubmittedClock, showUnsubmittedExpenses])

    useEffect(() => {
        if (props.report) {
            processRows({
                data: props.report
            });
        }
    }, [props.reportStatus])

    const columns = [
        {
            field: 'action',
            headerName: 'Actions', width: hasFeature('history', props.appSettings) ? 225 : 150,
            renderCell: (params) => (
                <>
                    <ButtonGroup variant="text" aria-label="text button group">
                        <Button color="primary" disabled={params.row.status === 'Unsubmitted'} onClick={() => { return navigateTo(params) }}>View</Button>
                        <Button color="primary" disabled={params.row.status !== 'Changes Requested'} onClick={() => { return navigateEdit(params)}}>Edits</Button>
                        {hasFeature('history', props.appSettings) && hasAccess() && (<Button color="primary" onClick={() => { return openHistoryModal(params)}}>History</Button>)}
                    </ButtonGroup>
                </>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150
        },
        {
            field: props.appSettings.sortByLastName && props.appSettings.sortByLastName.enabled ? 'officialName' : 'techName',
            headerName: 'Technician',
            width: 200
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
            renderCell: (params) => (
                <>
                    {params.row.type === 0 && (
                        "Labor Items"
                    )}

                    {params.row.type === 1 && (
                        "Clock Events"
                    )}

                    {params.row.type === 2 && (
                        "Expenses"
                    )}
                </>
            )
        },
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            width: 300,
            renderCell: (params) => {
                return (
                    <>
                        {moment(params.row.endEpoch * 1000).format('MMM-DD-YYYY hh:mm A')}
                    </>
                )
            }
        },
        {
            field: 'grandTotals',
            headerName: 'Grand Total',
            width: 200,
            renderCell: (params) => (
                <>
                    {round(params.row.grandTotals)}
                </>
            )
        }
    ];

    useEffect(() => {
        setShowUnsubmittedLabor(props.showUnsubmittedLabor)
    }, [props.showUnsubmittedLabor]);

    useEffect(() => {
        setShowUnsubmittedExpenses(props.showUnsubmittedExpenses)
    }, [props.showUnsubmittedExpenses]);

    useEffect(() => {
        setShowUnsubmittedClock(props.showUnsubmittedClock)
    }, [props.showUnsubmittedClock]);

    const handleSelection = (dataGrid) => {
        if (dataGrid.selectionModel) {
            let selectionModel = dataGrid.selectionModel;
            selectionModel = selectionModel.map((modelId) => {
                return props.report.filter((row) => {
                    return row.id === modelId;
                })[0];
            });
            props.dispatch({
                type: 'set_bulkActions',
                payload: selectionModel
            });
        } else {
            props.dispatch({
                type: 'set_bulkActions',
                payload: dataGrid.map((modelId) => {
                    return props.report.filter((row) => {
                        return row.id === modelId;
                    })[0];
                })
            });
        }
    };

    const handleCloseBackdrop = () => {
        props.dispatch({
            type: 'set_backdrop',
            payload: false
        });
    };

    return (
        <>
            <Backdrop className={classes.backdrop} open={showBackdrop} onClick={handleCloseBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    disableSelectionOnClick={showUnsubmittedLabor || showUnsubmittedClock || showUnsubmittedExpenses}
                    components={{ Toolbar: !isTechViewingHisOwnTimecards() && QuickSearchToolbar }}
                    checkboxSelection={showCheckboxSelection}
                    rows={rows}
                    loading={loading}
                    columns={columns}
                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelection} 
                    componentsProps={{
                        toolbar: {
                          value: searchText,
                          onChange: (event) => requestSearch(event.target.value),
                          clearSearch: () => requestSearch("")
                        }
                      }}
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
        startEpoch: state.reportDateRanges.startDay,
        endEpoch: state.reportDateRanges.endDay,
        dateLabel: state.reportDateRanges.label
    };
};
export default connect(mapStateToProps)(TimeCardTable);