import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import JobItem from './jobItem';
import axiosInstance from '../../utilities/axios';
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import { requestHeaderConfig } from '../../utilities';
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    dateContainer: {
      marginTop: 0,
    }
  }));
const EditModalList = (props) => {
    const classes = useStyles();
    let [jobItems, setJobItems] = useState(false);
    useEffect(async () => {
        await axiosInstance.post('/jobs/draft', {
            companyId: props.login.user.company.id,
            userId: props.login.user.id,
            jobs: props.modal.content
          }, requestHeaderConfig(props.login.authToken)).then((response) => {
            setJobItems(response.data)
            console.warn(response.data, 'Successfully saved jobs');
          }).catch((e) => {
              console.warn(e, 'Could not save settings for : startDay');
          });
    }, [])

    if (!jobItems) { 
        return (<></>)
    }

    return (
        <>
            {jobItems.length && jobItems.map((item) => (
                <JobItem model={item} key={uuid()}/>
            ))}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(EditModalList);