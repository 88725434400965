import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { TableContainer, Table, TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import AdditionalRowContainer from './additionalRowContainer';
import { formatDate } from '../utilities/dateUtilities'
import GrandTotals from './grandTotals'
import { round, convertDecimalToHoursToHourly } from '../../utilities';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    hide: {
        display: 'none'
    },
    timecardDay: {
        textAlign: 'center',
        borderBottom: 'none'
    },
    summary: {
        borderBottom: 'none',
        width: 92,
        paddingRight: 9
    },
    summaryBtn: {
        padding: '0 0 0 22px'
    },
    summaryBtmBorder: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
    root: {
        width: '100%',
        maxWidth: 360,
    }
}));

const Summary = (props) => {
    const classes = useStyles();
    const [showOnBreak, setShowOnBreak] = useState(props.showOnBreakInSummary);
    const isViewTimeCard = props.isViewTimeCard;
    const timeCardWeek = props.timeCardWeek;
    let renderDecimalFormatClock = props.appSettings && props.appSettings.clockEventDecimalFormat ? props.appSettings.clockEventDecimalFormat.enabled : false;
    useEffect(() => {
        setShowOnBreak(props.showOnBreakInSummary)
    }, [props.showOnBreakInSummary]);

    const shouldDisplayRow = (row) => {
        if (row.Label !== 'onbreak') return true;
        return row.Label === 'onbreak' && showOnBreak;
    }

    const showOnBreakTime = () => {
        props.dispatch({
            type: 'show_on_break_in_summary',
            payload: !showOnBreak
        });
    }
    return (
        <>
            <TableContainer>
                <Table size='small' >
                <TableHead>
                        <TableRow key={uuid()}>
                            {(props.appSettings && props.appSettings.clockEventTypes && props.appSettings.clockEventTypes.onbreak && props.appSettings.clockEventTypes.onbreak === true) && (
                            <TableCell className={classes.jobHeader}>
                                <FormControlLabel
                                    control={<Checkbox checked={props.showOnBreakInSummary} name="confirm" color="primary" onClick={showOnBreakTime}
                                    style={{ pointerEvents: "auto" }} />}
                                    label="Show On Break Time"/>
                            </TableCell>)}
                            <TableCell className={classes.jobHeader}></TableCell>
                            <TableCell></TableCell>
                            {isViewTimeCard && (
                                <> 
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </>
                            )}
                            {!isViewTimeCard && (
                                <> 
                                    <TableCell></TableCell>
                                </>
                            )}
                            {timeCardWeek && (
                            <>
                                {timeCardWeek && timeCardWeek.length > 0 ? timeCardWeek.map((day, index) => (
                                    <TableCell align='center' className={classes.dateHeader} key={uuid()}>{day.day} <br /><sub>{formatDate(props.view.weekDates[index])}</sub></TableCell>
                                )) : null}
                            </>)}
                            {!timeCardWeek && (
                            <>
                                {props.appSettings.weekDayOrder && props.appSettings.weekDayOrder.length > 0 ? props.appSettings.weekDayOrder.map((day, index) => (
                                    <TableCell align='center' className={classes.dateHeader} key={uuid()}>{day.day} <br /><sub>{formatDate(props.dateRanges.weekDates[index]).slice(0, 5)}</sub></TableCell>
                                )) : null}
                            </>)}
                            <TableCell align='center'>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    {props.tableRows[0] && props.tableRows[0].week && props.summary && props.summary.length > 0 && props.summary.map((row) => (
                        <>
                        {shouldDisplayRow(row) && (
                            <TableRow >
                                <TableCell colSpan={2} align="left" className={classes.summary} >
                                    <Typography className={classes.root}>{row['Label']}</Typography>
                                </TableCell>
                                {!isViewTimeCard && (
                                    <> 
                                        <TableCell className={classes.timecardDay}></TableCell>
                                    </>
                                )}
                                {(props.appSettings && props.appSettings.clockEventTypes && props.appSettings.clockEventTypes.onbreak && props.appSettings.clockEventTypes.onbreak === true) && (
                                    <TableCell className={classes.timecardDay}></TableCell>
                                )}
                                {props.tableRows[0].week.map((key) => (
                                    <TableCell className={classes.timecardDay} key={uuid()}>
                                        {renderDecimalFormatClock && props.timeCardType === 1 
                                        ? convertDecimalToHoursToHourly(round(row[key.day]))
                                        :round(row[key.day])}
                                    </TableCell>
                                ))}
                                <TableCell className={classes.timecardDay}>
                                    {renderDecimalFormatClock && props.timeCardType === 1 
                                        ? convertDecimalToHoursToHourly(round(row['Total']))
                                    :round(row['Total'])}
                                </TableCell>
                            </TableRow>
                        )}
                    </>
                    ))}
                     <AdditionalRowContainer isViewTimeCard={isViewTimeCard} readOnly={true} />
                     <GrandTotals isSummaryGrandTotal={true} isViewTimeCard={true} week={props.appSettings.weekDayOrder} />
                </Table>
            </TableContainer>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(Summary);