import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { requestHeaderConfig } from '../../utilities';

import axiosInstance from '../../utilities/axios';

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }));

  /**
   * @name Redirect
   * @param {} props
   * @description This component will redirect an admin to the specified timecard based solely on the uuid passed in the url query string 
   */
  const ViewRedirect = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    let navi = useHistory();
    let requestParams = {
        params: {
            companyId: props.login.user.company.id,
            uuid: new URLSearchParams(window.location.search).get('key')
        },
        ...requestHeaderConfig(props.login.authToken)
    }

    const getDefaultWeekDay = (startDay) => {
        let weekday = [{
            day: "Sun",
            value: 0
        }, {
            day: "Mon",
            value: 0
        }, {
            day: "Tue",
            value: 0
        }, {
            day: "Wed",
            value: 0
        }, {
            day: "Thu",
            value: 0
        }, {
            day: "Fri",
            value: 0
        }, {
            day: "Sat",
            value: 0
        }];
        // set the order of the week to match the set start day
        weekday.push.apply( weekday, weekday.splice( 0, weekday.findIndex((week) => week.day === startDay) ));
        return weekday;
    }

    axiosInstance.get('/fetchTimecard', requestParams).then((response) => {
        var timecard = response.data;
        
        // for every model (row) in the view
        timecard.model = timecard.model.map((model) => {
            let weekDay = getDefaultWeekDay(props.appSettings.startDay);
            // re-order the week days
            model.week = model.week.map((wModel, index, wArray) => {
                // update the value of the day that is already in the correct order with the matching day value in wArray
                weekDay[index].value = wArray.find(week => week.day === weekDay[index].day).value
                // replace the old, potentially incorrect week day with the new 
                return weekDay[index];
            });
            return model;
        });

        props.dispatch({
            type: 'set_timecard_rows',
            payload: timecard.additionalRows || []
        });
        props.dispatch({
            type: 'set_view_timecard',
            payload: timecard
        });
        navi.push('/viewTimecard');
        return timecard
    }).catch((e) => {
        console.warn(e, 'Could not retrieve timecard');
        navi.push('/');
        setOpen(false);
    })

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(ViewRedirect);