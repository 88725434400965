import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { checkOfficeLimitSettings } from '../../utilities';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const SelectTechnician = (props) => {
    const classes = useStyles();
    let [technician, setTechnician] = useState(props.technician && props.technician.id !== 0 ? props.technician.id : props.users[0].id)
    let model = props.listOfAccountUsers;
    const limitedByOffice = checkOfficeLimitSettings(props);

    const setInitialTechnician = (data) => {
        if (data && Array.isArray(data) && props.login.isAdmin) {
            // find and return the first user that is a tech
            return data.find((u) => u.isTech || u.isHelper);
        } else {
            // return the current logged in user, since current user is a tech
            return props.login.user;
        }
    }

    useEffect(() => {
        if (!props.technician) {
            props.dispatch({
                type: 'set_technician',
                payload: setInitialTechnician(props.listOfAccountUsers)
            });
        }
    }, [])

    const handleChange = (field) => {
        let technician = model.find(e => e.id === field.target.value);
        setTechnician(field.target.value);
        
        props.dispatch({
            type: 'set_technician',
            payload: technician
        });
    };

    const shouldShowTechnician = (user) => {
        let shouldShow = true;
        if (limitedByOffice && limitedByOffice.id) {
            // The current technician in the drop down does not match the current users office
            if (user.location && user.location.id && user.location.id !== limitedByOffice.id) {
                shouldShow = false;
            } else if ( user.location && !user.location.id ) {
                console.warn('Cannot determine technician`s office. Location is null, Showing technician: ' + user.name);
            }
        }
        return shouldShow;
    }

    if (!model) {
        return (
            <></>
        )
    }
    if (props.appSettings.sortByLastName && props.appSettings.sortByLastName.enabled) {
        // sort model by last name (could also sort by the officialName)
        model = model.sort((a, b) => {
            const firstNameA = a.firstName.toLowerCase();
            const firstNameB = b.firstName.toLowerCase();
        
            if (firstNameA < firstNameB) {
              return -1; // a should come before b
            } else if (firstNameA > firstNameB) {
              return 1; // b should come before a
            } else {
              return 0; // a and b are equal in terms of firstName
            }
          });
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>Technicians</InputLabel>
            <Select value={technician} onChange={handleChange}>
                {model.map((user) => (
                    (user.isTech || user.isHelper) && shouldShowTechnician(user) && (
                        <MenuItem key={uuid()} value={user.id}>{user.officialName || user.name}</MenuItem>
                    )
                ))}
            </Select>
        </FormControl>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(SelectTechnician);