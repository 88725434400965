import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import UsageGraphById from './usageGraphById';

import axiosInstance from '../../utilities/axios';

const ActiveSubscribers = (props) => {
    let [subscribers, setSubscribers] = useState([]);
    let [open, setOpen] = useState(false);

    useEffect(() => {
        axiosInstance.get('/analytics/access').then(response => {
                let subscribers = response.data;
                setSubscribers(subscribers)
            }).catch((e) => {
                console.warn(e, 'Could not fetch subscribers');
            });
    }, []);

    const columns = [
        {   
            field: 'id', 
            headerName: 'Account ID',
            width: 300
        },
        {   
            field: 'name', 
            headerName: 'Account Name',
            width: 300
        },
        {   
            field: 'accountManager', 
            headerName: 'Account Manager',
            width: 300
        },
        {
            field: 'lastTimestamp',
            headerName: 'Last Timecard Submission',
            width: 300
        }
    ];

    const handleSelection = (selection) => {
        props.dispatch({
            type: 'set_usage_graph_id',
            payload: +selection.selectionModel[0]
        });
        props.dispatch({
            type: 'set_usage_graph_mode',
            payload: true
        });
    }

    const handleClose = () => {
        props.dispatch({
            type: 'set_usage_graph_mode',
            payload: false
        });
        props.dispatch({
            type: 'set_usage_graph_id',
            payload: 0
        });
    }

    useEffect(() => {
        setOpen(props.usageGraphMode);
    }, [props.usageGraphMode])

    if (!subscribers.length) {
        return <><LinearProgress /> May take 8 - 15 seconds</>;
    }
    return (
        <>
            <TableContainer component={Paper}>
            <h4 style={{marginLeft: 15}} align="left">Active Subscribers</h4>
                <div style={{ height: 500, width: '100%'}}>
                    <DataGrid pageSize={25} disableMultipleSelection={true} rows={subscribers} columns={columns} onSelectionModelChange={handleSelection}/>
                </div>
            </TableContainer>
            <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} scroll="paper" aria-labelledby="new-export-template">
                <DialogContent>
                    <UsageGraphById setOpen={setOpen}/>
                </DialogContent>
            </Dialog>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(ActiveSubscribers);