import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { connect } from 'react-redux';
import axiosInstance from '../../utilities/axios';
import { requestHeaderConfig } from '../../utilities';

const WageType = (props) => {
    const [wageType, setWageType] = React.useState(props.row.wageType || '');
    const handleChange = (event, user) => {
        setWageType(event.target.value);
        user.wageType = event.target.value;
        updateUser(user);
    };

    const updateUser = (user) => {
        // users with complete list of service lines will exceed payload size of nodejs
        delete user.serviceLines;
        axiosInstance.post('/updateUser', {
            companyId: props.login.user.company.id,
            user: user,
            isWageType: true
            }, requestHeaderConfig(props.login.authToken)).then((result) => {
              let userData = {...props.login};
              userData.userList = result.data.userList;
              props.dispatch({
                type: 'initialize',
                payload: userData
              });
            })
            .catch(function (error) {
                console.log(error, ': An error has occured while updating the user');
            });
    }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>Default</InputLabel>
        <Select
          value={wageType}
          label="Default"
          onChange={(e) => {handleChange(e, props.row)}}
        >
          <MenuItem value={"Hourly"}>Hourly</MenuItem>
          <MenuItem value={"Salary"}>Salary</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
};
export default connect(mapStateToProps)(WageType);