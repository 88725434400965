import React from 'react';
import DragIcon from '@material-ui/icons/DragHandle';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';

import { connect } from 'react-redux';

const TemplateColumnListItem = (props) => {
    let [alias, setAlias] = React.useState(props.item.alias);

    const handleReadModeOnChange = () => {
        let updated = props.templateModel.data.map((column) => {
            if (column.header === props.item.header) {
                column.alias = alias;
            }
            return column;
        });
        props.dispatch({
            type: 'set_template_model',
            payload: {
                data: updated
            }
        });
    }

    const handleAliasChange = (e, item) => {
        setAlias(e.target.value);
    };

  return (
        <ListItem>
            <ListItemIcon>
                {props.showDragHandle && (<DragIcon />)}
            </ListItemIcon>
            <TextField
                id="outlined-required"
                label={props.item.header}
                margin="dense"
                fullWidth
                defaultValue={props.item.alias}
                variant="outlined"
                onBlur={() => {
                    handleReadModeOnChange()
                }}
                onChange={(e, a) => { return handleAliasChange(e)}}
            />
        </ListItem>
  );
}

const mapStateToProps = (state) => {
    return {
        ...state
    };
  };
export default connect(mapStateToProps)(TemplateColumnListItem);