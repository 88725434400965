import axiosInstance from '../utilities/axios';
import moment from 'moment';
import {
    REQUEST_DATA, REQUEST_DATA_FAIL, SUBMISSION,
    REQUEST_COMPANY_SUBMISSIONS,NAVIGATION, 
} from "../utilities/constants/analytics";


export const getClickActionAnalytics = (days, params) => async (dispatch) => {

    try {
        let dataObject = {};
        let companySubmissionEvents = {}
        let response = await axiosInstance.get('/analytics', { params: { daysBack: days } });
     
        // Created an data object with the date(s) as the key and the value is an object with three keys. 1. Navigation 2, Submission, 3. Total 
        // {date: {Submission: x, Navigation: Y, Total: x + y}}
        for (let i = 0; i < response.data.length; i++) {
            let timeStamp = moment(response.data[i].timestamp).format().slice(0, 10)
            if (!dataObject[timeStamp]) {
                if (response.data[i].type === SUBMISSION) {
                    dataObject[timeStamp] = {
                        Submission: 1,
                        Navigation: 0, 
                        Total: 1
                    };
                } else if (response.data[i].type === NAVIGATION) {
                    dataObject[timeStamp] = {
                        Submission: 0,
                        Navigation: 1, 
                        Total: 1
                    };
                }
                
            } else {

                if (response.data[i].type === SUBMISSION) {
                    dataObject[timeStamp].Submission += 1
                    dataObject[timeStamp].Total += 1  
                } else if (response.data[i] === NAVIGATION) {
                    dataObject[timeStamp].Navigation += 1
                    dataObject[timeStamp].Total += 1  
                } else {
                    dataObject[timeStamp].Total += 1  
                }
            }
        }

        // algo to add submission events to the submission event object
        let responseData = response.data.filter((item) => {
            if (item.metadata !== undefined) { return item.metadata }
        }).filter(item => item.type === SUBMISSION)

        for (let data of responseData) {

            if (data.user !== undefined) {
        
                if (companySubmissionEvents[data.companyName] === undefined) {
                    if (data.user.isTech || data.user.isHelper) {
                        companySubmissionEvents[data.companyName] = {techSubmission: 1, adminSubmission: 0.020}
                    } else if (!data.user.isTech || !data.user.isHelper) {
                        companySubmissionEvents[data.companyName] = {techSubmission: 0.2, adminSubmission: 1}
                    }
                    
                } else if (companySubmissionEvents[data.companyName]) {
                    if (data.user.isTech || data.user.isHelper) {
                        companySubmissionEvents[data.companyName].techSubmission += 1
                    } else if (!data.user.techSubmission) {
                        companySubmissionEvents[data.companyName.adminSubmission] += 1
                    }
                }
            }
        }

        dispatch({
            type: REQUEST_COMPANY_SUBMISSIONS, 
            payload: companySubmissionEvents
        })
        dispatch({
            type: REQUEST_DATA,
            payload: dataObject
        })
    } catch (error) {
        dispatch({
            type: REQUEST_DATA_FAIL,
            payload: error.message
        })
    }
}
