export const round = (num) => {
	return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const analytics = ({props, type, action, metadata}) => {
	let user = {};
	// let log = '';
	// let owner = '';
	// user = {...user, ...props.login.user};
	// delete user.serviceLines;
	// delete user.activities;
	// if (user && user.metadata) {
	// 	delete user.metadata.activites;
	// }

	// if (action === REQUEST_BUTTON) {
	// 	type = 'REQUEST';
	// } else if (action === APPROVE_BUTTON) {
	// 	type = 'APPROVE';
	// }

	// switch (type) {
	// 	case 'SUBMISSION':
	// 		owner = props.login.user.name === metadata.techName ? 'submitted his/her own timecard' : `submitted ${metadata.techName}'s timecard`;
	// 		log = `${props.login.user.name} from the ${props.login.user.location.name} office ` + owner;
	// 		break;
	// 	case 'REQUEST':
	// 		// owner = props.login.user.name === metadata.techName ? 'submitted his/her own timecard' : `submitted ${metadata.techName}'s timecard`;
	// 		owner = `requested changes to ${metadata[0].techName}'s timecard`;
	// 		if (metadata.length > 1) {
	// 			owner = `requested changes to ${metadata.length} timecards`;
	// 		}
	// 		log = `${props.login.user.name} from the ${props.login.user.location.name} office ` + owner;
	// 		break;
	// 	case 'APPROVE':
	// 		owner = `approved changes to ${metadata[0].techName}'s timecard`;
	// 		if (metadata.length > 1) {
	// 			owner = `approved changes to ${metadata.length} timecards`;
	// 		}
	// 		log = `${props.login.user.name} from the ${props.login.user.location.name} office ` + owner;
	// 		break;
	// }
	
	// need to be able to say "X" approved "Y" timecards for "Z" technician(s)
	// axiosInstance.post('/analytics/', {
	// 	token: props.login.authToken,
	// 	timezone: props.login.user.timezone,
	// 	userId: props.login.user.id,
	// 	accountId: props.login.user.account.id,
	// 	log: log
	// });
};

export const limitByOffice = (array, office) => {
	array = array.filter((timecard) => { 
		return timecard.techOffice === office.name;
	});
	return array;
};

export const handleLogout = (logoutUrl) => {
	window.location.href = logoutUrl;
} 

export const checkOfficeLimitSettings = (props) => {
	const user = props.login.user;
	const completeUserData = props.listOfAccountUsers.find((fullUserData)=> { return user.id === fullUserData.id})
	const location = completeUserData?.location;
	let officeId = 0;
	if (location) {
		officeId = location.id;
	}
	const limitedArray = props.appSettings.limitByTech;
	const currentOfficeIsLimitedByTech = typeof limitedArray !== 'undefined' && limitedArray.length > 0 ? limitedArray.find((item) => { return item.id === officeId }) || false : false;
	return currentOfficeIsLimitedByTech;
}
export const convertDecimalToHoursToHourly = (number) => {
	let sign = (number >= 0) ? 1 : -1;
	let time; 

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    let hour = Math.floor(number);
    let decpart = number - hour;

    let min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    let minute = Math.floor(decpart * 60) + '';

    // Add padding if need
    if (minute.length < 2) {
    minute = '0' + minute; 
    }

    // Add Sign in final result
    sign = sign === 1 ? '' : '-';

    // Concate hours and minutes
    time = sign + hour + ':' + minute;

    return time;
}

export const translate = (text) => {
	switch (text) {
		case 'service_call':
			return 'Service Call';
		case 'emergency_service_call':
			return 'Emergency Service Call';
		case 'cleaning':
			return 'Cleaning';
		case 'priority_service_call':
			return 'Priority Service Call';
		case 'construction':
			return 'Construction';
		case 'inspection':
			return 'Inspection';
		case 'planned_maintenance':
			return 'Planned Maintenance';
		case 'training':
			return 'Training';
		case 'retrofit':
			return 'Retrofit';
		case 'repair':
			return 'Repair';
		case 'consultation':
			return 'Consultation';
		case 'Upgrade':
			return 'Upgrade';
		case 'urgent_service_call':
			return 'Urgent Service Call';
		case 'priority_inspection':
			return 'Priority Inspection';
		case 'reinspection':
			return 'Reinspection';
		case 'survey':
			return 'Survey';
		case 'preventative_maintenance':
			return 'Preventative Maintenance';
		case 'quality_assurance':
			return 'Quality Assurance';
		case 'inspection_repair':
			return 'Inspection Repair';
		case 'delivery':
			return 'Delivery';
		case 'pickup':
			return 'Pickup';
		case 'exchange':
			return 'Exchange';
		case 'sales':
			return 'Sales';
		case 'installation':
			return 'Installation';
		case 'warranty':
			return 'Warranty';
		case 'testing':
			return 'Testing'
		case 'administrative':
			return 'Administrative';
		case 'replacement':
			return 'Replacement';
		case 'design':
			return 'Design';
		case 'buildout':
			return 'Buildout';
		case 'hookup':
			return 'Hookup';
		case 'start_up':
			return 'Start Up';
		case 'unknown':
			return 'Unknown';
		default:
			return text
	}
}

export const cleanseCachedStartAndEndDates = () => {
	sessionStorage.removeItem('startRange');
	sessionStorage.removeItem('endRange');
}

export const requestHeaderConfig = (authToken, custom) => {
	const isValidObject = (obj) => {
		return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
	}
	let config = {
		headers: {
			'Authorization': `Bearer ${authToken}`,
		}
	}
	if (isValidObject(custom)) {
		config.headers = {...config.headers, ...custom}
	}
	return config;
}